/*global google*/
import React, { useState } from "react";
import { Marker } from "react-google-maps";
import DroneIcon from "assets/svg/drone.svg";
import { useDevicesContext, useFlightDataContext, useMapContext } from "../UAVMapContainer";
import { scaleIconSize } from "./utils";
import Card from "../../../components/Card/Card";
import { pmColor, tempColor } from "./color_utils";
import { MeasurementUnit } from "./Measurement";
import { lightBlue } from "@material-ui/core/colors";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

const InfoBoxOptions = {
  pixelOffset: { width: -60, height: -210 },
  boxStyle: {
    padding: "0px 0px 0px 0px"
  },
  closeBoxURL: "",
  isHidden: false,
  enableEventPropagation: true
};

const CustomCardStyle = {
  background: "rgba(0, 27, 45, 0.7)",
  marginTop: 0,
  padding: 5,
  width: 140,
  color: "white",
  textAlign: "center"
};

const normalize = coordinate => Math.round(coordinate * 100000);

export default function YetiAirMarker ({ serialNo }) {

  const [showPointInfobox, setShowPointInfobox] = useState({  });
  const selectedDevice = serialNo;
  const { state: mapState } = useMapContext();
  const { zoom } = mapState;

  const { state: flightState } = useFlightDataContext();
  const { flightsData } = flightState;
  const datapoints = flightsData[selectedDevice] || [];
  const lastDatapoint = datapoints[datapoints.length - 1];
  const { height, width } = scaleIconSize(zoom);
  //currentValue, index, array
  const dronePoints = datapoints.slice(0, datapoints.length - 2).map((dp, i, array) => {
    const datapoint = dp;
    const previousDatapoint = array[i - 1];
    // skip same location
    if (previousDatapoint && (datapoint.t === previousDatapoint.t ||
      (normalize(datapoint.lat) === normalize(previousDatapoint.lat) && normalize(datapoint.lng) === normalize(previousDatapoint.lng)))) {
      return null;
    }

      return <Marker
        key={dp.t}
        position={{ lat: dp.lat, lng: dp.lng }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE, anchor: { x: 0, y: 2 },
          scale: 6.5,
          fillColor: selectedDevice === "AIR_1" ? "purple" : "blue",
          fillOpacity: 0.6,
          strokeWeight: 0.0
        }}
        onMouseOver={() => setShowPointInfobox({ ...showPointInfobox, [dp.t]: true })}
        onMouseOut={() => setShowPointInfobox({ ...showPointInfobox, [dp.t]: false })}
      >
      {showPointInfobox[dp.t] &&
      <InfoBox options={InfoBoxOptions}>
        <Card style={CustomCardStyle}>

          {datapoint.PM10 && <div>
            PM10: <span style={{ color: pmColor(datapoint.PM10) }}>
                  {Math.round(datapoint.PM10) } [{MeasurementUnit.PM10}]
                </span>
          </div>}
          {datapoint.PM25 && <div>
            PM2.5: <span style={{ color: pmColor(datapoint.PM10) }}>
                  {Math.round(datapoint.PM25) } [{MeasurementUnit.PM25}]
                </span>
          </div>}
          {datapoint.temp && <div>
            Temp: <span style={{ color: tempColor(datapoint.temp) }}>
                  {Math.round(datapoint.temp)} [{MeasurementUnit.temp}]
                </span>
          </div>}
          {datapoint.RH && <div>
            RH: <span style={{ color: lightBlue[300] }}>
                  {Math.round(datapoint.RH)} [{MeasurementUnit.RH}]
                </span>
          </div>}
          {datapoint.pressure && <div>
            Pressure: <span style={{ color: lightBlue[300] }}>
                  {Math.round(datapoint.pressure)} [{MeasurementUnit.Pressure}]
                </span>
          </div>}
        </Card>
      </InfoBox>}
  </Marker>}
  );

  return lastDatapoint ? [<Marker key={"first-" + selectedDevice}

    position={{ lat: lastDatapoint.lat, lng: lastDatapoint.lng }}
    icon={{ url: DroneIcon, scaledSize: { height, width }}}
  >
  </Marker>, ...dronePoints] : null;
}
