import React from "react";
// react components used to create a google map
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Phone from "@material-ui/icons/Phone";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Email from "@material-ui/icons/Email";
import ReCAPTCHA from "react-google-recaptcha";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import city from "assets/img/examples/city.jpg";

const useStyles = makeStyles(contactsStyle);

const ReCAPTCHA_SITE_ID = "6LdkgcwZAAAAAMLuNXKycWMDMV9By58mU5u8Hm_V";

export default function SectionContacts({ ...rest }) {
  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [notARobot, setNotARobot] = React.useState(false);
  const recaptchaRef = React.createRef();

  const onCaptchaChange = (value) => {
    setNotARobot(true);
  };

  const isSubmitButtonEnabed = () => {
    return notARobot && email && email.includes("@") && first && message
  };

  const clearForm = () => {
    console.log("clearForm", { first, last });
    setFirst("");
    setLast("");
    setEmail("");
    setMessage("");
  };

  const handleSubmitMessage = () => {
    const name = first + " " + last;
    const url = "https://eb57hviexh.execute-api.eu-central-1.amazonaws.com/dev/send";
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      alert("Twoja wiadomość została wysłana. Dziękujemy!");
      clearForm();
    });

    xhr.addEventListener('error', () => {
      // update the state of the component with the result here
      // alert("I'm So Sorry!! I cannot Sent your message. Please send your message on email: mwenta@seadata.pl");
      alert("Jest mi bardzo przykro!! Nie mogłem wysłać Twojej wiadomości. Bardzo proszę o wysłanie jej na adres: kontakt@seadata.pl");
    });

    xhr.open('POST', url);
    xhr.send(JSON.stringify({ "source": window.location.href, "name": name, "email": email, "message": message }));
  };

  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              {/*<h2 className={classes.title}>Get in Touch</h2>*/}
              <h2 className={classes.title}>Bądźmy w kontakcie</h2>
              <h5 className={classes.description}>
                Potrzebujesz więcej informacji? Napisz do nas.
              </h5>
              {/*<h5 className={classes.description}>*/}
                {/*You need more information? Check what other persons are saying*/}
                {/*about our product. They are very happy with their purchase.*/}
              {/*</h5>*/}
              <br />
              <br />
              <br />
              <br />
              <InfoArea
                className={classes.infoArea}
                title="Wolisz Zadzwonić?"
                description={
                  <span>
                    Marta Wenta
                    <br /> Product Manager/Data Scientist
                    <br /> +48 504 756 409
                    <br /> Pon - Pt, 8:00-16:00
                  </span>
                }
                icon={Phone}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="rose"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Napisz do Nas</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput

                          labelText="Imię"
                          id="first"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            autoComplete: "name",
                            value: first,
                            onChange: (event) => {
                              setFirst(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Nazwisko"
                          id="last"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            autoComplete: "lastname",
                            value: last,
                            onChange: (event) => {
                              setLast(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput

                      labelText="Email"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        autoComplete: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: email,
                        onChange: (event) => {
                          setEmail(event.target.value);
                        }
                      }}
                    />
                    <CustomInput
                      labelText="Twoja wiadomość"
                      id="message"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: message,
                        onChange: (event) => {
                          setMessage(event.target.value);
                        }
                      }}
                    />

                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <ReCAPTCHA
                      size="compact"
                      ref={recaptchaRef}
                      sitekey={ReCAPTCHA_SITE_ID}
                      onChange={onCaptchaChange}
                    />
                    <Button
                      disabled={!isSubmitButtonEnabed()}
                      color="rose"
                      className={classes.pullRight}
                      onClick={handleSubmitMessage}
                    >
                      Wyślij
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
    </div>
  );
}
