import React from 'react';
import {Marker} from 'react-google-maps';
// import MobileBoxIcon from 'assets/svg/water-drone.svg';
import YachtIcon from 'assets/img/droneMission/yacht-yellow.png'

export default function WaterDroneMarker() {
    const height = 40;
    const width = 40;

   return <Marker
      key={"water-drone-1"}
      position={{ lat: 54.359921, lng: 18.601817 }}
      icon={{ url: YachtIcon, scaledSize: { height, width } }}
      // onClick={() => setShowStationInfobox({ ...showStationInfobox, [serialNo]: !showStationInfobox[serialNo] })}
    >
     <img alt='water drone' src={YachtIcon} />
    </Marker>;

}
