import { container } from "assets/jss/material-kit-pro-react.js";

const carouselStyle = {
  section: {
    padding: "70px 0"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },

  roboImageStyle: {
    paddingLeft:  20,
    paddingRight:  20,
  }
};

export default carouselStyle;
