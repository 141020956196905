import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import { BlogPosts } from "views/BlogPostPage/data";

const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 1 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              {/*<h2 className={classes.title}>Latest Blogposts</h2>*/}
              <h2 className={classes.title}>Najnowsze posty na blogu</h2>
              <br />
              {BlogPosts.map(post => {
                // Nie Parzyste ids bloga
                return post.id % 2 === 1 ? <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                      <CardHeader image plain>
                        <a href={"/blog/" + post.id}>
                          <img src={post.frontImg} alt="..."/>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${post.frontImg})`,
                            opacity: "1"
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                      <Info>
                        <h6 className={classes.cardCategory}>{post.category}</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <a href={"/blog/" + post.id}>
                          {post.title}
                        </a>
                      </h3>
                      <p className={classes.description1}>
                        {post.abstract}
                        <a href={"/blog/" + post.id} >
                          {" "}
                          Czytaj Dalej{" "}
                        </a>
                      </p>
                      <p className={classes.author}>
                        by{" "}
                        <a href="#marta-wenta" onClick={e => e.preventDefault()}>
                          <b>Marta Wenta</b>
                        </a>{" "}
                        , {post.publishDate}
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                :
                  // Parzyste ids bloga
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={7} md={7}>
                        <Danger>
                          <h6 className={classes.cardCategory}><TrendingUp/>{post.category}</h6>
                        </Danger>
                        <h3 className={classes.cardTitle}>
                          <a href={"/blog/" + post.id}>
                            {post.title}
                          </a>
                        </h3>
                        <p className={classes.description1}>
                          {post.abstract}
                          <a href={"/blog/" + post.id} >
                            {" "}
                            Czytaj Dalej{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          by{" "}
                          <a href="#marta-wenta" onClick={e => e.preventDefault()}>
                            <b>Marta Wenta</b>
                          </a>{" "}
                          , {post.publishDate}
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={5} md={5}>
                        <CardHeader image plain>
                          <a href={"/blog/" + post.id}>
                            <img src={post.frontImg} alt="..."/>
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${post.frontImg})`,
                              opacity: "1"
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                    </GridContainer>
                  </Card>
                  ;
              })}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 1 END */}
    </div>
  );
}
