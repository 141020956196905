import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

import profileImage from "assets/img/uav/team-faces/marta-wenta.jpg";
import profileImageWebP from "assets/img/uav/team-faces/marta-wenta.webp";

import sectionBlogInfoStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle.js";
import { useWebPSupportCheck } from "react-use-webp-support-check";

const useStyles = makeStyles(sectionBlogInfoStyle);

export default function SectionBlogInfo(props) {
  const classes = useStyles();
  const { post } = props;
  const supportsWebP = useWebPSupportCheck();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.blogTags}>
                Tagi: {" "}
                {post.tags.map(tag => <Badge color="primary">{tag}</Badge>)}
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FacebookShareButton quote={post.abstract} hashtag={"#UAVMonitoring.pl"}
                url={window.location.href} className={classes.buttons}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} title={post.title} className={classes.buttons}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton url={window.location.href} className={classes.buttons}
                                   title={post.title} summary={post.abstract} source={window.location.href}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
            </GridItem>
          </GridContainer>
          <hr />
          <Card plain profile className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={2} md={2}>
                <CardAvatar plain profile>
                  <img src={supportsWebP ? profileImageWebP : profileImage} alt="Marta Wenta photo" />
                </CardAvatar>
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <h4 className={classes.cardTitle}>Marta Wenta</h4>
                <p className={classes.description}>
                  Doktorantka. Zajmuję się modelowaniem atmosfery w rejonach Polarnych.
                  Pomaga zrozumieć zwykłym ludziom jak nasze małe decyzje teraz będą miały wpływ
                  na jakość oraz przyszłość życia na naszej planecie.
                  <br />
                  Uwielbia biegi górskie, koty i łąki kwietne.
                </p>
              </GridItem>
              {/*<GridItem xs={12} sm={2} md={2}>*/}
                {/*<Button round className={classes.pullRight}>*/}
                  {/*Follow*/}
                {/*</Button>*/}
              {/*</GridItem>*/}
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
