import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import bg7 from "assets/img/bg7.jpg";
import bg7WebP from "assets/img/bg7.webp";

import uavIcon from "assets/img/uav/uav-icon-small.png";
import uavIconWebP from "assets/img/uav/uav-icon-small.webp";

import seadataProfilePhoto from "assets/img/uav/team-faces/seadata-team.jpg";
import seadataProfilePhotoWebP from "assets/img/uav/team-faces/seadata-team.webp";

import cardProfile2Square from "assets/img/uav/team-faces/together.jpg";
import cardProfile2SquareWebP from "assets/img/uav/team-faces/together.webp";

import camdroneProfilePhoto from "assets/img/uav/team-faces/dawid-camdrone.jpg";
import camdroneProfilePhotoWebP from "assets/img/uav/team-faces/dawid-camdrone.webp";
import { useWebPSupportCheck } from "react-use-webp-support-check";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  const supportsWebP = useWebPSupportCheck();

  return (
    <div className="cd-section" {...rest}>
      {/* Team 4 START */}
      <div
        className={classes.team + " " + classes.section}
        style={{ backgroundImage: `url(${supportsWebP ? bg7WebP : bg7})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Kim jesteśmy?</h2>
              <h5 className={classes.descriptionWhite}>
                UAV Monitoring powstało w wyniku współpracy dwóch trojmiejskich frim: SeaData Sp. z o. o. z Gdyni oraz Camdrone z Gdańska.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="https://seadata.eu"  >
                    <img src={supportsWebP ? seadataProfilePhotoWebP : seadataProfilePhoto} alt="Seadata Team photo" />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    <a href="https://seadata.eu" >Seadata Sp. z o. o. </a>
                  </h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Monitoring Środowiska / IoT / Big Data</h6>
                  </Muted>
                  <p className={classes.description}>
                    Specjalizujemy się w data science, tworzeniu systemów analitycznych oraz rozwiązaniach IoT.
                    Tworzymy systemy monitoringu środowiska, oraz Inteligentne rozwiązania wspierające zrównoważony rozwój przedsiębiorstw.
                    Z naszych usług korzystają największe polskie porty w Gdańsku i w Gdyni, a także w Lipawie.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button justIcon round color="linkedin">
                    <a style={{ color: "white" }} href="https://www.linkedin.com/company/seadata-gdynia" >
                      <i className="fab fa-linkedin-in"/>
                    </a>
                  </Button>
                  <Button justIcon round color="facebook">
                    <a style={{ color: "white" }} href="https://www.facebook.com/seadatagdynia" >
                      <i className="fab fa-facebook" />
                    </a>
                  </Button>
                  <Button justIcon round color="twitter">
                    <a style={{ color: "white" }} href="https://twitter.com/marta_wenta" >
                      <i className="fab fa-twitter" />
                    </a>
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="https://uav-monitoring.pl" onClick={e => e.preventDefault()}>
                    <img src={supportsWebP ? cardProfile2SquareWebP : cardProfile2Square} alt="Camdrone i Seadata" />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>UAV Monitoring</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Monitoring Środowiska z Dronów</h6>
                  </Muted>
                  <p className={classes.description}>
                    Dzięki współpracy obu firm mogliśmy stworzyć nowy, kompleksowy produkt,
                    który obejmuje monitoring środowiska z powietrza wraz z zaawansowanym systemem
                    analizy oraz wizualizacji zebranych danych, także w czasie rzeczywistym.
                  </p>
                  < br/>
                  < br/>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button justIcon round color="transparent">
                    <img src={supportsWebP ? uavIconWebP : uavIcon}/>
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="https://camdrone.pl"  >
                    <img src={supportsWebP ? camdroneProfilePhotoWebP : camdroneProfilePhoto} alt="Camdrone photo" />
                  </a>
                </CardAvatar>
                <CardBody>
                    <h4 className={classes.cardTitle}>
                      <a href="https://camdrone.pl"  >Camdrone.pl</a>
                    </h4>

                  <Muted>
                    <h6 className={classes.cardCategory}>Usługi oparte o drony</h6>
                  </Muted>
                  <p className={classes.description}>
                    Operatorzy Camdrone posiadają wieloletnie doświadczenie w operacjach opartych o drony.
                    Posiadają uprawnienia Urzędu Lotnictwa Cywilnego VLOS / BVLOS / MTOM 25 kg / INS.
                    Do usług Camdrone należą: nadzór inwestycji, badania termowizyjne, inspekcje z powietrza,
                    monitoring środowiska z dronów, tworzenie ortofotomap 3D oraz montaż i produkcja video.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button justIcon round color="facebook">
                    <a style={{ color: "white" }} href="https://www.facebook.com/Camdronedawidwalczyna/" >
                      <i className="fab fa-facebook" />
                    </a>
                  </Button>
                  <Button justIcon round color="rose">
                    <a style={{ color: "white" }} href="https://www.instagram.com/camdrone_dawid_walczyna/" >
                      <i className="fab fa-instagram" />
                    </a>
                  </Button>
                  <Button justIcon round color="youtube">
                    <a style={{ color: "white" }} href="https://www.youtube.com/channel/UCv3pbU34DhZGv2veTMznJSg" >
                      <i className="fab fa-youtube" />
                    </a>
                  </Button>
                  <Button justIcon round color="linkedin">
                    <a style={{ color: "white" }} href="https://www.linkedin.com/in/dawid-walczyna-b67a3515b" >
                      <i className="fab fa-linkedin-in"/>
                    </a>
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 4 END */}
    </div>
  );
}
