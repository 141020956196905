import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";
// core components

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText(props) {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  const { post } = props;

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            {post.firstLine}
          </h3>
          {post.text.map(paragraph => <p>{paragraph}</p>)}

        </GridItem>
        <GridItem xs={12} sm={8} md={8} className={classes.section}>
          <img src={post.images[0]} alt={post.title + " " + post.subtitle} className={imgClasses}/>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          {/*<h3 className={classes.title}>Konkluzja:</h3>*/}
          <br />
          <br />
          <p>
            {post.finishingLine}
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
