import Favorite from "@material-ui/core/SvgIcon/SvgIcon";
import Footer from "../../components/Footer/Footer";
import React from "react";
import { makeStyles } from "@material-ui/core";
import footerStyle from "../../assets/jss/material-kit-pro-react/components/footerStyle";

const useStyles = makeStyles(footerStyle);

export default function ErrorPage({ ...rest }) {

  const classes = useStyles();

  return <Footer
    content={
      <div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} , made with{" "}
          <Favorite className={classes.icon} /> by{" "}
          <a
            href="https://seadata.eu"
          >
            SeaData
          </a>{" "}
          for a better web.
        </div>
      </div>
    }
  />
}
