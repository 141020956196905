import React, {useEffect, useMemo, useReducer} from "react";
import UAVMap from "./UAVMap";
import {PusherProvider} from "@harelpls/use-pusher";
import PusherListener from "./PusherListener";
import DemoReplaysProvider from "./DemoReplaysProvider";

export const FLIGHT_PUSHER_CHANNEL_NAME = "FLIGHT-CHANNEL";
export const MOBILE_PUSHER_CHANNEL_NAME = "MOBILE-CHANNEL";
export const STATIONARY_PUSHER_CHANNEL_NAME = "STATIONARY-CHANNEL";

export const PUSHER_MESSAGE_TAG = "datapoint";

// Gdynia
// const lat = 54.351103333333334;
// const lng = 18.597521666666665;
// const mapZoom = 18;

// GPNT
const defaultLat = 54.447006;
const defaultLng = 18.531685;
const defaultMapZoom = 14;

const pusherConfig = {
  // required config props
  clientKey: "58386b9f29bd5e176367",
  cluster: "eu"

  // optional if you'd like to trigger events. BYO endpoint.
  // see "Trigger Server" below for more info
  // triggerEndpoint: "/pusher/trigger",

  // required for private/presence channels
  // also sends auth headers to trigger endpoint
  // authEndpoint: "/pusher/auth",
  // auth: {
  //   headers: { Authorization: "Bearer token" },
  // },
};

const demoReplaysInitialState = () => {
  return {
    activeDemo: null, // ONE OF: demo_mission_1, demo_mission_2, demo_mission_3
    demoData: {}, // missionId->value missionData ???

    simulationData: null,
    simulationIdx: 0
  };
};

const flightInitialState = () => {
  return {
    flightsData: {} // key-> serialNo, value flightData as [{...}]
  };
};

const mobileInitialState = () => {
  return {
    mobileData: {} // Mobile boxes, mounted on a Car. format the same
  };
};

const stationaryInitialState = () => {
  return {
    stationaryData: {} // Stationary Box data format same as above
  };
};

const devicesInitialState = () => {
  return {
    onlineDevices: [],
    selectedDevice: null
  };
};

const mapInitialState = () => {
  return {
    zoom: defaultMapZoom,
    center: {lat: defaultLat, lng: defaultLng}
  };
};

const MapContext = React.createContext(mapInitialState());
const DevicesContext = React.createContext(devicesInitialState());
const DemoReplaysContext = React.createContext(demoReplaysInitialState());
const FlightDataContext = React.createContext(flightInitialState());
const MobileDataContext = React.createContext(mobileInitialState());
const StationaryDataContext = React.createContext(stationaryInitialState());

// expose the context via a custom hook.
export const useMapContext = () => React.useContext(MapContext);
export const useDevicesContext = () => React.useContext(DevicesContext);
export const useDemoReplaysContext = () => React.useContext(DemoReplaysContext);
export const useFlightDataContext = () => React.useContext(FlightDataContext);
export const useMobileDataContext = () => React.useContext(MobileDataContext);
export const useStationaryDataContext = () => React.useContext(StationaryDataContext);

export default function UAVMapContainer() {

  const [mapReducerState, mapDispatch] = useReducer(reducer, mapInitialState());
  const [devicesReducerState, devicesDispatch] = useReducer(reducer, devicesInitialState());
  const [demoReplaysReducerState, demoReplaysDispatch] = useReducer(reducer, demoReplaysInitialState());
  const [flightReducerState, flightDispatch] = useReducer(reducer, flightInitialState());
  const [mobileReducerState, mobileDispatch] = useReducer(reducer, mobileInitialState());
  const [stationaryReducerState, stationaryDispatch] = useReducer(reducer, stationaryInitialState());

  // LAZY LOAD PUSHER SCRIPT
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.pusher.com/7.0/pusher.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const mapContext = useMemo(() => {
    return {
      state: mapReducerState,
      dispatch: mapDispatch
    };
  }, [mapReducerState]);

  const devicesContext = useMemo(() => {
    return {
      state: devicesReducerState,
      dispatch: devicesDispatch
    };
  }, [devicesReducerState]);

  const demoReplaysContext = useMemo(() => {
    return {
      state: demoReplaysReducerState,
      dispatch: demoReplaysDispatch
    };
  }, [demoReplaysReducerState]);

  const flightContext = useMemo(() => {
    return {
      state: flightReducerState,
      dispatch: flightDispatch
    };
  }, [flightReducerState]);

  const mobileContext = useMemo(() => {
    return {
      state: mobileReducerState,
      dispatch: mobileDispatch
    };
  }, [mobileReducerState]);

  const stationaryContext = useMemo(() => {
    return {
      state: stationaryReducerState,
      dispatch: stationaryDispatch
    };
  }, [stationaryReducerState]);

  return (
    <DemoReplaysContext.Provider value={demoReplaysContext}>
      <DevicesContext.Provider value={devicesContext}>
        <MapContext.Provider value={mapContext}>
          <FlightDataContext.Provider value={flightContext}>
            <MobileDataContext.Provider value={mobileContext}>
              <StationaryDataContext.Provider value={stationaryContext}>
                <PusherProvider {...pusherConfig}>
                  <DemoReplaysProvider/>
                  <PusherListener/>
                  <UAVMap/>
                </PusherProvider>
              </StationaryDataContext.Provider>
            </MobileDataContext.Provider>
          </FlightDataContext.Provider>
        </MapContext.Provider>
      </DevicesContext.Provider>
    </DemoReplaysContext.Provider>);
}

/**
 * Common reducer for all Contexts. If gets complicated split it.
 */
function reducer(state, action) {
  switch (action.type) {
    // Symulation context
    case "set-simulationData":
      return {
        ...state,
        simulationData: action.simulationData
      };
    case "set-simulationIdx":
      return {
        ...state,
        simulationIdx: action.simulationIdx
      };
    // DevicesContext
    case "set-device-online":
      if (action.isSimulation) {
        return {
          ...state,
          onlineDevices: [action.serialNo, ...state.onlineDevices],
          selectedDevice: action.serialNo,
        };
      }
      if (state.activeMission) {
        return {
          ...state,
          onlineDevices: [action.serialNo, ...state.onlineDevices],
          selectedDevice: (action.serialNo.includes("DEMO_AIR_1") || !state.selectedDevice.includes("DEMO")) ? action.serialNo : state.selectedDevice
        };
      }
      return {
        ...state,
        onlineDevices: [action.serialNo, ...state.onlineDevices],
        selectedDevice: (action.serialNo.includes("AIR") || !state.selectedDevice) ? action.serialNo : state.selectedDevice
      };
    case "set-device-offline":
      const newOnlineDevices = state.onlineDevices.filter(serialNo => serialNo !== action.serialNo);
      return {
        ...state,
        onlineDevices: newOnlineDevices,
        selectedDevice: action.serialNo === state.selectedDevice ? newOnlineDevices[0] : state.selectedDevice
      };
    case "select-device":
      return {
        ...state,
        selectedDevice: action.serialNo
      };

    // MapContext
    case "set-map-center-and-zoom":
      return {
        ...state,
        center: action.center,
        zoom: action.zoom
      };
    case "set-map-center":
      return {
        ...state,
        center: action.center
      };
    case "set-map-zoom":
      return {
        ...state,
        zoom: action.zoom
      };
    case "new-flight-datapoint":
      // Update only data for specific serialNo and in addition preserve last seen measurements.
      const flightData = state.flightsData[action.serialNo] || [];
      const newDatapoint = flightData.length > 0 ? {...flightData[flightData.length - 1], ...action.flightDatapoint} : action.flightDatapoint;
      return {
        ...state,
        // activeDevice: action.activeDevice,
        // lastDatapoint: { ...state.lastDatapoint, ...newDatapoint },
        flightsData: {...state.flightsData, [action.serialNo]: [...flightData, newDatapoint]}
      };
    //  Future Use for updating entire flight fetched from AWS
    case "new-flight-data":
      return {
        ...state,
        flightsData: {...state.flightsData, [action.serialNo]: action.flightData}
      };
    case "new-mobile-datapoint":
      // Update only data for specific serialNo and in addition preserve last seen measurements.
      const mobileData = state.mobileData[action.serialNo] || [];
      const newMobileDatapoint = mobileData.length > 0 ? {...mobileData[mobileData.length - 1], ...action.mobileDatapoint} : action.mobileDatapoint;
      return {
        ...state,
        mobileData: {...state.mobileData, [action.serialNo]: [...mobileData, newMobileDatapoint]}
      };
    case "new-stationary-datapoint":
      // Update only data for specific serialNo and in addition preserve last seen measurements.
      const stationaryData = state.stationaryData[action.serialNo] || [];
      const newStationaryData = stationaryData.length > 0 ? {...stationaryData[stationaryData.length - 1], ...action.stationaryDatapoint} : action.stationaryDatapoint;
      return {
        ...state,
        stationaryData: {...state.stationaryData, [action.serialNo]: [...stationaryData, newStationaryData]}
      };
    // DemoReplays Context
    case "start-demo":
      // Update only data for specific serialNo and in addition preserve last seen measurements.
      return {
        ...state,
        activeMission: action.activeMission,
        demoData: action.demoData
      };
    case "stop-demo":
      // Update only data for specific serialNo and in addition preserve last seen measurements.
      return {
        ...state,
        activeMission: null,
        demoData: null,
      };

    // case "reset-flight-simulation":
    //   return flightInitialState();
    // case "reset-mobile-simulation":
    //   return mobileInitialState();
    // case "reset-stationary-simulation":
    //   return stationaryInitialState();

    default:
      console.log({action});
      throw new Error("REDUCER cannot handle action " + action);
  }
}
