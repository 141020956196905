export const station1Location = {lat: 54.351250, lng: 18.597287};

export const station2Location = {lat: 54.350856, lng: 18.598821};

export const test_flight_data = [
  {
    "t": 1593708862000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 55.0
  }, {
    "t": 1593708865000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 54.599998474121094
  }, {
    "t": 1593708866000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 54.5
  }, {
    "t": 1593708874000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 52.400001525878906
  }, {
    "t": 1593708882000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.70000076293945
  }, {
    "t": 1593708887000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.099998474121094
  }, {
    "t": 1593708896000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.20000076293945
  }, {
    "t": 1593708904000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 52.099998474121094
  }, {
    "t": 1593708912000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.70000076293945
  }, {
    "t": 1593708922000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.599998474121094
  }, {
    "t": 1593708928000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.200000762939453,
    "RH": 51.900001525878906
  }, {
    "t": 1593708929000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.100000381469727,
    "RH": 51.5
  }, {
    "t": 1593708937000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.200000762939453,
    "RH": 51.79999923706055
  }, {
    "t": 1593708945000,
    "lat": 54.500025,
    "lng": 18.51687666666667,
    "temp": 3.200000762939453,
    "RH": 51.5
  }, {
    "t": 1593708958000,
    "lat": 54.35095,
    "lng": 18.598156666666668,
    "temp": 3.200000762939453,
    "RH": 51.599998474121094
  }, {
    "t": 1593708974000,
    "lat": 54.35097666666667,
    "lng": 18.598133333333333,
    "temp": 3.200000762939453,
    "RH": 52.29999923706055
  }, {
    "t": 1593708977000,
    "lat": 54.350986666666664,
    "lng": 18.59814,
    "temp": 3.200000762939453,
    "RH": 52.5
  }, {
    "t": 1593708980000,
    "lat": 54.351,
    "lng": 18.598143333333333,
    "temp": 3.299999237060547,
    "RH": 52.900001525878906
  }, {
    "t": 1593708988000,
    "lat": 54.35102333333333,
    "lng": 18.598161666666666,
    "temp": 3.200000762939453,
    "RH": 53.400001525878906
  }, {
    "t": 1593708996000,
    "lat": 54.351061666666666,
    "lng": 18.598206666666666,
    "temp": 3.299999237060547,
    "RH": 53.099998474121094
  }, {
    "t": 1593708999000,
    "lat": 54.351075,
    "lng": 18.598208333333332,
    "temp": 3.299999237060547,
    "RH": 53.599998474121094
  }, {
    "t": 1593709015000,
    "lat": 54.351215,
    "lng": 18.598216666666666,
    "temp": 3.299999237060547,
    "RH": 54.70000076293945
  }, {
    "t": 1593709018000,
    "lat": 54.351195,
    "lng": 18.598201666666668,
    "temp": 3.299999237060547,
    "RH": 54.900001525878906
  }, {
    "t": 1593709031000,
    "lat": 54.35118166666667,
    "lng": 18.598195,
    "temp": 3.399999618530273,
    "RH": 51.400001525878906
  }, {
    "t": 1593709034000,
    "lat": 54.35117666666667,
    "lng": 18.598198333333332,
    "temp": 3.399999618530273,
    "RH": 52.599998474121094
  }, {
    "t": 1593709040000,
    "lat": 54.35111666666667,
    "lng": 18.598206666666666,
    "temp": 3.449999809265137,
    "RH": 52.60000038146973
  }, {
    "t": 1593709041000,
    "lat": 54.35109333333333,
    "lng": 18.598211666666668,
    "temp": 18.34999990463257,
    "RH": 1677.8500480651855
  }, {
    "t": 1593709042000,
    "lat": 54.35109333333333,
    "lng": 18.598211666666668,
    "temp": 3.5,
    "RH": 52.400001525878906
  }, {
    "t": 1593709045000,
    "lat": 54.351065,
    "lng": 18.598208333333332,
    "temp": 3.5,
    "RH": 52.20000076293945
  }, {
    "t": 1593709050000,
    "lat": 54.351013333333334,
    "lng": 18.598196666666666,
    "temp": 3.5,
    "RH": 52.099998474121094
  }, {
    "t": 1593709066000,
    "lat": 54.350968333333334,
    "lng": 18.598165,
    "temp": 3.5,
    "RH": 50.599998474121094
  }, {
    "t": 1593709067000,
    "lat": 54.350968333333334,
    "lng": 18.598165,
    "temp": 3.5,
    "RH": 50.70000076293945
  }, {
    "t": 1593709070000,
    "lat": 54.350966666666665,
    "lng": 18.598161666666666,
    "temp": 3.5,
    "RH": 50.79999923706055
  }, {
    "t": 1593709075000,
    "lat": 54.35096166666667,
    "lng": 18.598161666666666,
    "temp": 3.5,
    "RH": 50.900001525878906
  }, {
    "t": 1593709083000,
    "lat": 54.35096333333333,
    "lng": 18.598161666666666,
    "temp": 3.5,
    "RH": 50.29999923706055
  }, {
    "t": 1593709089000,
    "lat": 54.350965,
    "lng": 18.59817,
    "temp": 3.5,
    "RH": 50.599998474121094
  }, {
    "t": 1593709090000,
    "lat": 54.350965,
    "lng": 18.59817,
    "temp": 3.5,
    "RH": 50.900001525878906
  }, {
    "t": 1593709108000,
    "lat": 54.35098833333333,
    "lng": 18.598201666666668,
    "temp": 3.5,
    "RH": 51.099998474121094
  }, {
    "t": 1593709113000,
    "lat": 54.35100166666667,
    "lng": 18.598213333333334,
    "temp": 3.5,
    "RH": 51.20000076293945
  }, {
    "t": 1593709114000,
    "lat": 54.35100166666667,
    "lng": 18.598213333333334,
    "temp": 3.5,
    "RH": 51.29999923706055
  }, {
    "t": 1593709135000,
    "lat": 54.35103,
    "lng": 18.598253333333332,
    "temp": 12.699999809265137,
    "RH": 50.79999923706055
  }, {
    "t": 1593709143000,
    "lat": 54.35107333333333,
    "lng": 18.598246666666668,
    "temp": 3.5,
    "RH": 50.599998474121094
  }, {
    "t": 1593709146000,
    "lat": 54.35117666666667,
    "lng": 18.598195,
    "temp": 3.5,
    "RH": 50.79999923706055
  }, {
    "t": 1593709151000,
    "lat": 54.35122833333333,
    "lng": 18.598176666666667,
    "temp": 3.5,
    "RH": 51.0
  }, {
    "t": 1593709154000,
    "lat": 54.35128,
    "lng": 18.598151666666666,
    "temp": 3.5,
    "RH": 51.20000076293945
  }, {"t": 1593709155000, "lat": 54.35128, "lng": 18.598151666666666, "temp": 3.5, "RH": 51.5}, {
    "t": 1593709181000,
    "lat": 54.35127,
    "lng": 18.598019999999998,
    "temp": 3.5,
    "RH": 51.099998474121094
  }, {
    "t": 1593709191000,
    "lat": 54.35126666666667,
    "lng": 18.59798333333333,
    "temp": 3.399999618530273,
    "RH": 51.099998474121094
  }, {
    "t": 1593709202000,
    "lat": 54.35124833333333,
    "lng": 18.597966666666668,
    "temp": 3.299999237060547,
    "RH": 51.29999923706055
  }, {
    "t": 1593709205000,
    "lat": 54.351245,
    "lng": 18.597961666666667,
    "temp": 3.299999237060547,
    "RH": 51.70000076293945
  }, {
    "t": 1593709211000,
    "lat": 54.351235,
    "lng": 18.597955,
    "temp": 3.200000762939453,
    "RH": 53.0
  }, {
    "t": 1593709226000,
    "lat": 54.351213333333334,
    "lng": 18.597955,
    "temp": 3.299999237060547,
    "RH": 52.20000076293945
  }, {
    "t": 1593709229000,
    "lat": 54.351213333333334,
    "lng": 18.597955,
    "temp": 3.299999237060547,
    "RH": 51.900001525878906
  }, {
    "t": 1593709243000,
    "lat": 54.35119,
    "lng": 18.597945,
    "temp": 3.299999237060547,
    "RH": 52.099998474121094
  }, {
    "t": 1593709251000,
    "lat": 54.35113666666667,
    "lng": 18.597968333333334,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 3.399999618530273,
    "RH": 51.70000076293945
  }, {"t": 1593709252000, "lat": 54.351135, "lng": 18.597966666666668, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593709253000,
    "lat": 54.351135,
    "lng": 18.597966666666668,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593709254000, "lat": 54.351135, "lng": 18.597966666666668, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593709255000,
    "lat": 54.35113333333334,
    "lng": 18.597963333333333,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593709256000, "lat": 54.35113333333334, "lng": 18.597963333333333, "PM10": 6.0}, {
    "t": 1593709257000,
    "lat": 54.35113,
    "lng": 18.597961666666667,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593709258000,
    "lat": 54.35113,
    "lng": 18.597961666666667,
    "PM10": 7.0,
    "PM25": 4.666666666666667
  }, {"t": 1593709259000, "lat": 54.35113, "lng": 18.597961666666667, "PM10": 8.0, "PM25": 4.0}, {
    "t": 1593709260000,
    "lat": 54.35113,
    "lng": 18.597961666666667,
    "PM10": 8.0
  }, {"t": 1593709261000, "lat": 54.351128333333335, "lng": 18.597965, "PM10": 8.0, "PM25": 4.0}, {
    "t": 1593709262000,
    "lat": 54.351128333333335,
    "lng": 18.597965,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593709263000,
    "lat": 54.351123333333334,
    "lng": 18.597966666666668,
    "PM10": 10.0,
    "PM25": 7.0
  }, {
    "t": 1593709264000,
    "lat": 54.351123333333334,
    "lng": 18.597966666666668,
    "PM10": 10.0,
    "PM25": 8.0
  }, {"t": 1593709265000, "lat": 54.35111833333333, "lng": 18.59797, "PM10": 10.5, "PM25": 7.0}, {
    "t": 1593709266000,
    "lat": 54.35111833333333,
    "lng": 18.59797,
    "PM10": 10.0,
    "PM25": 7.0
  }, {"t": 1593709267000, "lat": 54.35111833333333, "lng": 18.59797, "PM10": 10.0, "PM25": 6.0}, {
    "t": 1593709268000,
    "lat": 54.35111666666667,
    "lng": 18.597968333333334,
    "PM10": 10.0,
    "PM25": 6.0
  }, {
    "t": 1593709269000,
    "lat": 54.35111666666667,
    "lng": 18.597968333333334,
    "PM10": 10.0,
    "PM25": 6.0
  }, {
    "t": 1593709270000,
    "lat": 54.35111666666667,
    "lng": 18.597966666666668,
    "PM10": 9.0,
    "PM25": 6.0,
    "temp": 3.299999237060547,
    "RH": 54.5
  }, {
    "t": 1593709271000,
    "lat": 54.35111666666667,
    "lng": 18.597966666666668,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593709272000,
    "lat": 54.35111833333333,
    "lng": 18.597966666666668,
    "PM10": 11.0,
    "PM25": 7.0
  }, {
    "t": 1593709273000,
    "lat": 54.35111833333333,
    "lng": 18.597966666666668,
    "PM10": 11.0,
    "PM25": 7.0
  }, {
    "t": 1593709274000,
    "lat": 54.35111833333333,
    "lng": 18.597966666666668,
    "PM10": 11.0,
    "PM25": 7.5
  }, {
    "t": 1593709275000,
    "lat": 54.35111833333333,
    "lng": 18.597966666666668,
    "PM10": 11.0,
    "PM25": 8.0
  }, {"t": 1593709276000, "lat": 54.35111833333333, "lng": 18.597966666666668, "PM10": 11.0}, {
    "t": 1593709277000,
    "lat": 54.35111833333333,
    "lng": 18.597965,
    "PM10": 11.5,
    "PM25": 8.0
  }, {"t": 1593709278000, "lat": 54.35111833333333, "lng": 18.597965, "PM10": 12.0, "PM25": 8.0}, {
    "t": 1593709279000,
    "lat": 54.35111666666667,
    "lng": 18.597965,
    "PM10": 12.0,
    "PM25": 8.0
  }, {"t": 1593709280000, "lat": 54.35111666666667, "lng": 18.597965, "PM10": 10.0, "PM25": 8.0}, {
    "t": 1593709281000,
    "lat": 54.35111666666667,
    "lng": 18.597965,
    "PM10": 10.0,
    "PM25": 8.0
  }, {"t": 1593709282000, "lat": 54.35111666666667, "lng": 18.597965, "PM10": 10.0, "PM25": 8.0}, {
    "t": 1593709283000,
    "lat": 54.35111666666667,
    "lng": 18.597965,
    "PM10": 10.0
  }, {
    "t": 1593709284000,
    "lat": 54.351115,
    "lng": 18.597961666666667,
    "PM10": 10.0,
    "PM25": 8.0,
    "temp": 3.200000762939453,
    "RH": 54.5
  }, {"t": 1593709285000, "lat": 54.35111, "lng": 18.597966666666668, "PM10": 7.0, "PM25": 7.0}, {
    "t": 1593709286000,
    "lat": 54.35111,
    "lng": 18.597966666666668,
    "PM10": 7.0,
    "PM25": 7.0
  }, {"t": 1593709287000, "lat": 54.35111, "lng": 18.597966666666668, "PM25": 6.0}, {
    "t": 1593709288000,
    "lat": 54.35109666666666,
    "lng": 18.597963333333333,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593709289000,
    "lat": 54.35109666666666,
    "lng": 18.597963333333333,
    "PM10": 7.0,
    "PM25": 7.0
  }, {
    "t": 1593709290000,
    "lat": 54.35109,
    "lng": 18.597963333333333,
    "PM10": 8.5,
    "PM25": 6.0,
    "RH": 52.900001525878906
  }, {"t": 1593709291000, "lat": 54.35109, "lng": 18.597963333333333, "PM10": 9.0, "PM25": 6.0}, {
    "t": 1593709292000,
    "lat": 54.35108,
    "lng": 18.59796,
    "PM10": 8.0,
    "PM25": 5.0,
    "temp": 3.299999237060547
  }, {"t": 1593709293000, "lat": 54.35108, "lng": 18.59796, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709294000,
    "lat": 54.35108,
    "lng": 18.59796
  }, {"t": 1593709295000, "lat": 54.35106, "lng": 18.597965, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709296000,
    "lat": 54.351045,
    "lng": 18.597968333333334,
    "PM10": 9.0,
    "PM25": 6.0,
    "temp": 3.299999237060547,
    "RH": 52.20000076293945
  }, {
    "t": 1593709297000,
    "lat": 54.351045,
    "lng": 18.597968333333334,
    "PM10": 8.0,
    "PM25": 5.0,
    "temp": 3.299999237060547,
    "RH": 52.099998474121094
  }, {"t": 1593709298000, "lat": 54.351045, "lng": 18.597968333333334, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709299000,
    "lat": 54.351045,
    "lng": 18.597968333333334
  }, {
    "t": 1593709300000,
    "lat": 54.351031666666664,
    "lng": 18.597966666666668,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593709301000,
    "lat": 54.351031666666664,
    "lng": 18.597966666666668,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593709302000,
    "lat": 54.351031666666664,
    "lng": 18.597966666666668,
    "PM10": 7.0,
    "PM25": 4.0
  }, {
    "t": 1593709303000,
    "lat": 54.35100333333333,
    "lng": 18.597961666666667,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593709304000, "lat": 54.35099833333334, "lng": 18.59796, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593709305000,
    "lat": 54.35099833333334,
    "lng": 18.59796,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593709306000, "lat": 54.35099833333334, "lng": 18.59796, "PM10": 14.5, "PM25": 11.0}, {
    "t": 1593709307000,
    "lat": 54.350971666666666,
    "lng": 18.59797,
    "PM10": 17.0,
    "PM25": 11.0
  }, {"t": 1593709308000, "lat": 54.350971666666666, "lng": 18.59797}, {
    "t": 1593709309000,
    "lat": 54.35095833333333,
    "lng": 18.597975,
    "PM10": 17.0,
    "PM25": 11.0
  }, {"t": 1593709310000, "lat": 54.35095833333333, "lng": 18.597975, "PM10": 17.0, "PM25": 11.0}, {
    "t": 1593709311000,
    "lat": 54.350946666666665,
    "lng": 18.597986666666667,
    "PM10": 20.0,
    "PM25": 11.0
  }, {"t": 1593709312000, "lat": 54.350946666666665, "lng": 18.597986666666667}, {
    "t": 1593709313000,
    "lat": 54.350946666666665,
    "lng": 18.597986666666667,
    "PM10": 23.0,
    "PM25": 10.0
  }, {
    "t": 1593709314000,
    "lat": 54.350946666666665,
    "lng": 18.597986666666667,
    "PM10": 24.0,
    "PM25": 10.0
  }, {
    "t": 1593709315000,
    "lat": 54.350946666666665,
    "lng": 18.597986666666667,
    "PM10": 19.0,
    "PM25": 4.0
  }, {
    "t": 1593709316000,
    "lat": 54.350941666666664,
    "lng": 18.597993333333335,
    "PM10": 20.0,
    "PM25": 4.0,
    "temp": 3.200000762939453,
    "RH": 52.70000076293945
  }, {
    "t": 1593709317000,
    "lat": 54.350941666666664,
    "lng": 18.597993333333335,
    "PM10": 20.0,
    "PM25": 4.0
  }, {
    "t": 1593709318000,
    "lat": 54.350941666666664,
    "lng": 18.597993333333335,
    "PM10": 20.0,
    "PM25": 4.0
  }, {
    "t": 1593709319000,
    "lat": 54.350941666666664,
    "lng": 18.597993333333335,
    "PM10": 20.0,
    "PM25": 6.0
  }, {
    "t": 1593709320000,
    "lat": 54.35093666666667,
    "lng": 18.598003333333335,
    "PM10": 20.0,
    "PM25": 6.0,
    "temp": 3.200000762939453,
    "RH": 1.600000023841858
  }, {
    "t": 1593709321000,
    "lat": 54.35093666666667,
    "lng": 18.598003333333335,
    "PM10": 20.0,
    "PM25": 5.0
  }, {"t": 1593709322000, "lat": 54.35093666666667, "lng": 18.598003333333335, "PM10": 18.0}, {
    "t": 1593709323000,
    "lat": 54.35093166666667,
    "lng": 18.597996666666667,
    "PM10": 18.0,
    "PM25": 5.0
  }, {
    "t": 1593709324000,
    "lat": 54.35093166666667,
    "lng": 18.597996666666667,
    "PM10": 18.0,
    "PM25": 5.0
  }, {
    "t": 1593709325000,
    "lat": 54.35093166666667,
    "lng": 18.597996666666667,
    "PM10": 16.0,
    "PM25": 5.0
  }, {"t": 1593709326000, "lat": 54.35093333333333, "lng": 18.59799, "PM10": 16.0, "PM25": 5.0}, {
    "t": 1593709327000,
    "lat": 54.35093166666667,
    "lng": 18.597975,
    "PM10": 14.0,
    "PM25": 5.0
  }, {"t": 1593709328000, "lat": 54.35093166666667, "lng": 18.597975, "PM10": 14.0, "PM25": 5.0}, {
    "t": 1593709329000,
    "lat": 54.35093166666667,
    "lng": 18.597975,
    "PM10": 14.0
  }, {"t": 1593709330000, "lat": 54.35093, "lng": 18.597971666666666, "PM10": 13.0, "PM25": 5.0}, {
    "t": 1593709331000,
    "lat": 54.35093,
    "lng": 18.597971666666666,
    "PM10": 13.0,
    "PM25": 5.0
  }, {"t": 1593709332000, "lat": 54.35093, "lng": 18.597971666666666, "PM10": 13.0, "PM25": 6.0}, {
    "t": 1593709333000,
    "lat": 54.35093,
    "lng": 18.597968333333334,
    "PM10": 12.0,
    "PM25": 5.5
  }, {
    "t": 1593709334000,
    "lat": 54.350928333333336,
    "lng": 18.597966666666668,
    "PM10": 11.0,
    "PM25": 4.0
  }, {
    "t": 1593709335000,
    "lat": 54.350928333333336,
    "lng": 18.597966666666668,
    "PM10": 9.0,
    "PM25": 4.0
  }, {"t": 1593709336000, "lat": 54.350928333333336, "lng": 18.597966666666668, "PM10": 9.0}, {
    "t": 1593709337000,
    "lat": 54.350928333333336,
    "lng": 18.597966666666668,
    "PM10": 9.0,
    "PM25": 4.0
  }, {
    "t": 1593709338000,
    "lat": 54.350928333333336,
    "lng": 18.597966666666668,
    "PM10": 7.0,
    "PM25": 4.0
  }, {
    "t": 1593709339000,
    "lat": 54.350926666666666,
    "lng": 18.597961666666667,
    "PM10": 6.333333333333333,
    "PM25": 3.6666666666666665
  }, {"t": 1593709340000, "lat": 54.350926666666666, "lng": 18.597961666666667, "PM25": 3.0}, {
    "t": 1593709341000,
    "lat": 54.350926666666666,
    "lng": 18.597961666666667,
    "PM10": 5.0,
    "PM25": 3.0,
    "temp": 3.200000762939453,
    "RH": 53.29999923706055
  }, {
    "t": 1593709342000,
    "lat": 54.350926666666666,
    "lng": 18.597961666666667,
    "PM10": 5.0,
    "PM25": 4.0
  }, {"t": 1593709343000, "lat": 54.350926666666666, "lng": 18.597961666666667, "PM10": 4.0}, {
    "t": 1593709344000,
    "lat": 54.350928333333336,
    "lng": 18.597961666666667,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 3.200000762939453,
    "RH": 53.20000076293945
  }, {
    "t": 1593709345000,
    "lat": 54.350928333333336,
    "lng": 18.597961666666667,
    "PM10": 3.6666666666666665,
    "PM25": 3.6666666666666665,
    "temp": 3.200000762939453,
    "RH": 53.29999923706055
  }, {"t": 1593709346000, "lat": 54.35093166666667, "lng": 18.597965, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593709347000,
    "lat": 54.35093166666667,
    "lng": 18.597965,
    "PM25": 4.0
  }, {
    "t": 1593709348000,
    "lat": 54.35093166666667,
    "lng": 18.597968333333334,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 3.200000762939453,
    "RH": 53.599998474121094
  }, {
    "t": 1593709349000,
    "lat": 54.35093166666667,
    "lng": 18.597968333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593709350000, "lat": 54.35093166666667, "lng": 18.597968333333334, "PM10": 4.0}, {
    "t": 1593709351000,
    "lat": 54.35093333333333,
    "lng": 18.59797,
    "PM10": 4.0,
    "PM25": 4.0,
    "RH": 54.29999923706055
  }, {"t": 1593709352000, "lat": 54.35093333333333, "lng": 18.59797, "PM10": 3.5, "PM25": 3.5}, {
    "t": 1593709353000,
    "lat": 54.350935,
    "lng": 18.597971666666666,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 3.200000762939453,
    "RH": 54.79999923706055
  }, {
    "t": 1593709354000,
    "lat": 54.350935,
    "lng": 18.597971666666666,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 3.200000762939453
  }, {"t": 1593709355000, "lat": 54.350935, "lng": 18.597971666666666, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593709356000,
    "lat": 54.35093666666667,
    "lng": 18.597975,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593709357000, "lat": 54.35093666666667, "lng": 18.597975, "PM25": 4.0}, {
    "t": 1593709358000,
    "lat": 54.35094,
    "lng": 18.597978333333334,
    "PM10": 4.5,
    "PM25": 3.0,
    "temp": 3.200000762939453,
    "RH": 55.29999923706055
  }, {"t": 1593709359000, "lat": 54.35094, "lng": 18.597978333333334, "PM10": 5.0}, {
    "t": 1593709360000,
    "lat": 54.350941666666664,
    "lng": 18.597976666666668,
    "PM10": 5.0,
    "PM25": 3.0
  }, {
    "t": 1593709361000,
    "lat": 54.350941666666664,
    "lng": 18.597976666666668,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593709362000,
    "lat": 54.350941666666664,
    "lng": 18.597978333333334,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593709363000,
    "lat": 54.350941666666664,
    "lng": 18.597978333333334,
    "PM10": 7.0,
    "PM25": 4.0
  }, {"t": 1593709364000, "lat": 54.350945, "lng": 18.597981666666666, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709365000,
    "lat": 54.350945,
    "lng": 18.597981666666666,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593709366000, "lat": 54.350945, "lng": 18.597981666666666, "PM10": 8.0}, {
    "t": 1593709367000,
    "lat": 54.350945,
    "lng": 18.59798,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593709368000, "lat": 54.350945, "lng": 18.59798, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709369000,
    "lat": 54.350946666666665,
    "lng": 18.597978333333334,
    "PM10": 9.0,
    "PM25": 5.0,
    "temp": 3.100000381469727,
    "RH": 53.79999923706055
  }, {
    "t": 1593709370000,
    "lat": 54.350946666666665,
    "lng": 18.597978333333334,
    "PM10": 8.0,
    "PM25": 4.0
  }, {
    "t": 1593709371000,
    "lat": 54.350948333333335,
    "lng": 18.59798,
    "PM10": 8.0,
    "PM25": 4.0,
    "temp": 3.100000381469727,
    "RH": 52.79999923706055
  }, {"t": 1593709372000, "lat": 54.350948333333335, "lng": 18.59798, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593709373000,
    "lat": 54.350948333333335,
    "lng": 18.59798,
    "PM10": 9.0
  }, {"t": 1593709374000, "lat": 54.35095, "lng": 18.597981666666666, "PM10": 9.0, "PM25": 5.0}, {
    "t": 1593709375000,
    "lat": 54.35095,
    "lng": 18.597981666666666,
    "PM10": 9.0,
    "PM25": 5.0
  }, {"t": 1593709376000, "lat": 54.35095, "lng": 18.597981666666666, "PM10": 9.0, "PM25": 5.0}, {
    "t": 1593709377000,
    "lat": 54.35095,
    "lng": 18.597986666666667,
    "PM10": 9.0,
    "PM25": 6.0
  }, {"t": 1593709378000, "lat": 54.35095, "lng": 18.597986666666667, "PM10": 9.0, "PM25": 6.0}, {
    "t": 1593709379000,
    "lat": 54.35096,
    "lng": 18.59797,
    "PM10": 9.0,
    "PM25": 6.0
  }, {"t": 1593709380000, "lat": 54.35096, "lng": 18.59797, "PM25": 6.0}, {
    "t": 1593709381000,
    "lat": 54.35097,
    "lng": 18.597953333333333,
    "PM10": 9.0,
    "PM25": 5.0
  }, {"t": 1593709382000, "lat": 54.35097, "lng": 18.597953333333333, "PM10": 9.0, "PM25": 5.0}, {
    "t": 1593709383000,
    "lat": 54.35097,
    "lng": 18.597953333333333,
    "PM10": 9.0,
    "PM25": 5.0
  }, {"t": 1593709384000, "lat": 54.35097, "lng": 18.597953333333333, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593709385000,
    "lat": 54.35097,
    "lng": 18.597953333333333,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593709386000,
    "lat": 54.35097833333333,
    "lng": 18.59795666666667,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593709387000,
    "lat": 54.35097833333333,
    "lng": 18.59795666666667,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593709388000,
    "lat": 54.35097833333333,
    "lng": 18.59795666666667,
    "PM10": 6.0,
    "PM25": 4.0,
    "temp": 3.0,
    "RH": 53.20000076293945
  }, {
    "t": 1593709389000,
    "lat": 54.35097833333333,
    "lng": 18.59795666666667,
    "PM10": 6.0,
    "PM25": 4.0
  }, {"t": 1593709390000, "lat": 54.350993333333335, "lng": 18.597935, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593709391000,
    "lat": 54.350993333333335,
    "lng": 18.597935,
    "PM10": 5.0,
    "PM25": 6.0
  }, {"t": 1593709392000, "lat": 54.350993333333335, "lng": 18.597935, "PM10": 7.0}, {
    "t": 1593709393000,
    "lat": 54.350993333333335,
    "lng": 18.597935,
    "PM10": 15.0,
    "PM25": 13.0
  }, {
    "t": 1593709394000,
    "lat": 54.350993333333335,
    "lng": 18.597935,
    "PM10": 22.0,
    "PM25": 17.0
  }, {
    "t": 1593709395000,
    "lat": 54.350993333333335,
    "lng": 18.597935,
    "PM10": 26.0,
    "PM25": 18.5
  }, {"t": 1593709396000, "lat": 54.35099, "lng": 18.597946666666665, "PM10": 29.0, "PM25": 19.0}, {
    "t": 1593709397000,
    "lat": 54.35099,
    "lng": 18.597946666666665,
    "PM25": 20.0
  }, {
    "t": 1593709398000,
    "lat": 54.351015,
    "lng": 18.59793,
    "PM10": 33.0,
    "PM25": 20.0,
    "temp": 3.0,
    "RH": 54.400001525878906
  }, {"t": 1593709399000, "lat": 54.351015, "lng": 18.59793, "PM10": 36.0, "PM25": 21.0}, {
    "t": 1593709400000,
    "lat": 54.35102166666667,
    "lng": 18.597923333333334,
    "PM10": 38.0,
    "PM25": 21.0
  }, {
    "t": 1593709401000,
    "lat": 54.35102166666667,
    "lng": 18.597923333333334,
    "PM10": 38.0,
    "PM25": 19.0
  }, {
    "t": 1593709402000,
    "lat": 54.35102166666667,
    "lng": 18.597923333333334,
    "PM10": 27.5,
    "PM25": 9.5
  }, {"t": 1593709403000, "lat": 54.35104, "lng": 18.597916666666666, "PM10": 3.0, "PM25": 7.0}, {
    "t": 1593709404000,
    "lat": 54.35104,
    "lng": 18.597916666666666,
    "PM25": 7.0
  }, {
    "t": 1593709405000,
    "lat": 54.35104333333334,
    "lng": 18.597913333333334,
    "PM10": 3.0,
    "PM25": 7.0,
    "temp": 3.0,
    "RH": 3.4000000953674316
  }, {
    "t": 1593709406000,
    "lat": 54.35104333333334,
    "lng": 18.597913333333334,
    "PM10": 24.0,
    "PM25": 7.0
  }, {"t": 1593709407000, "lat": 54.35104333333334, "lng": 18.597915, "PM10": 24.0, "PM25": 7.0}, {
    "t": 1593709408000,
    "lat": 54.35104333333334,
    "lng": 18.597915,
    "PM10": 22.0,
    "PM25": 6.0
  }, {"t": 1593709409000, "lat": 54.35104333333334, "lng": 18.597915, "PM10": 22.0, "PM25": 6.0}, {
    "t": 1593709410000,
    "lat": 54.35105,
    "lng": 18.59794,
    "PM10": 22.0,
    "PM25": 6.0,
    "temp": 24.899999618530273,
    "RH": 53.79999923706055
  }, {"t": 1593709411000, "lat": 54.35105, "lng": 18.59794, "PM10": 20.0, "PM25": 5.0}, {
    "t": 1593709412000,
    "lat": 54.35105,
    "lng": 18.59794,
    "PM10": 20.0,
    "PM25": 5.0
  }, {"t": 1593709413000, "lat": 54.35106, "lng": 18.59794, "PM10": 18.5, "PM25": 5.0}, {
    "t": 1593709414000,
    "lat": 54.351065,
    "lng": 18.597945,
    "PM10": 17.0,
    "PM25": 5.0,
    "temp": 24.799999237060547,
    "RH": 53.099998474121094
  }, {"t": 1593709415000, "lat": 54.351065, "lng": 18.597945, "PM10": 3.0, "PM25": 10.0}, {
    "t": 1593709416000,
    "lat": 54.351065,
    "lng": 18.597945,
    "PM25": 10.0
  }, {
    "t": 1593709417000,
    "lat": 54.35105333333333,
    "lng": 18.597948333333335,
    "PM10": 3.0,
    "PM25": 11.0
  }, {
    "t": 1593709418000,
    "lat": 54.35105333333333,
    "lng": 18.597948333333335,
    "PM10": 26.0,
    "PM25": 11.0
  }, {"t": 1593709419000, "lat": 54.35105333333333, "lng": 18.597948333333335, "PM10": 26.0}, {
    "t": 1593709420000,
    "lat": 54.35104833333333,
    "lng": 18.597941666666667,
    "PM10": 28.0,
    "PM25": 13.0,
    "temp": 24.700000762939453,
    "RH": 52.099998474121094
  }, {
    "t": 1593709421000,
    "lat": 54.35104833333333,
    "lng": 18.597941666666667,
    "PM10": 28.0,
    "PM25": 15.0
  }, {
    "t": 1593709422000,
    "lat": 54.35104166666667,
    "lng": 18.597933333333334,
    "PM10": 35.666666666666664,
    "PM25": 19.333333333333332
  }, {"t": 1593709423000, "lat": 54.35104166666667, "lng": 18.597933333333334, "PM25": 26.0}, {
    "t": 1593709424000,
    "lat": 54.351025,
    "lng": 18.597923333333334,
    "PM10": 45.0,
    "PM25": 23.0
  }, {
    "t": 1593709425000,
    "lat": 54.351025,
    "lng": 18.597923333333334,
    "PM10": 40.0,
    "PM25": 29.0
  }, {"t": 1593709426000, "lat": 54.351025, "lng": 18.597923333333334, "PM10": 45.0}, {
    "t": 1593709427000,
    "lat": 54.35101,
    "lng": 18.59791166666667,
    "PM10": 57.0,
    "PM25": 36.0,
    "RH": 51.400001525878906
  }, {"t": 1593709428000, "lat": 54.35101, "lng": 18.59791166666667, "PM10": 74.0, "PM25": 50.0}, {
    "t": 1593709429000,
    "lat": 54.35101,
    "lng": 18.59791166666667,
    "PM10": 89.0,
    "PM25": 68.0
  }, {
    "t": 1593709430000,
    "lat": 54.35102166666667,
    "lng": 18.597833333333334,
    "PM10": 107.0,
    "PM25": 85.0,
    "temp": 24.600000381469727
  }, {
    "t": 1593709431000,
    "lat": 54.351011666666665,
    "lng": 18.597765,
    "PM10": 111.66666666666667,
    "PM25": 90.0,
    "temp": 24.5,
    "RH": 51.29999923706055
  }, {
    "t": 1593709432000,
    "lat": 54.351011666666665,
    "lng": 18.597765,
    "PM10": 121.0,
    "PM25": 100.0
  }, {
    "t": 1593709433000,
    "lat": 54.351011666666665,
    "lng": 18.597765,
    "PM10": 121.0,
    "PM25": 100.0
  }, {
    "t": 1593709434000,
    "lat": 54.351005,
    "lng": 18.597726666666667,
    "PM10": 136.0,
    "PM25": 117.0
  }, {
    "t": 1593709435000,
    "lat": 54.351005,
    "lng": 18.597726666666667,
    "PM10": 137.0,
    "PM25": 117.0
  }, {"t": 1593709436000, "lat": 54.351005, "lng": 18.597726666666667, "PM25": 117.0}, {
    "t": 1593709437000,
    "lat": 54.351013333333334,
    "lng": 18.597696666666668,
    "PM10": 142.5,
    "PM25": 133.5
  }, {"t": 1593709438000, "lat": 54.351013333333334, "lng": 18.597696666666668, "PM10": 157.0}, {
    "t": 1593709439000,
    "lat": 54.351031666666664,
    "lng": 18.597661666666667,
    "PM10": 170.0,
    "PM25": 150.0
  }, {
    "t": 1593709440000,
    "lat": 54.351031666666664,
    "lng": 18.597661666666667,
    "PM10": 183.0,
    "PM25": 162.0
  }, {
    "t": 1593709441000,
    "lat": 54.351031666666664,
    "lng": 18.597661666666667,
    "PM10": 182.0,
    "PM25": 168.0
  }, {
    "t": 1593709442000,
    "lat": 54.35104166666667,
    "lng": 18.597663333333333,
    "PM10": 180.0,
    "PM25": 172.0
  }, {
    "t": 1593709443000,
    "lat": 54.35104166666667,
    "lng": 18.597663333333333,
    "PM10": 176.0,
    "PM25": 169.0
  }, {
    "t": 1593709444000,
    "lat": 54.35104166666667,
    "lng": 18.597661666666667,
    "PM10": 176.0,
    "PM25": 166.0
  }, {
    "t": 1593709445000,
    "lat": 54.35104166666667,
    "lng": 18.597661666666667,
    "PM10": 171.0,
    "PM25": 163.0
  }, {
    "t": 1593709446000,
    "lat": 54.35104166666667,
    "lng": 18.597661666666667,
    "PM10": 171.0,
    "PM25": 165.0
  }, {
    "t": 1593709447000,
    "lat": 54.351045,
    "lng": 18.597663333333333,
    "PM10": 173.0,
    "PM25": 164.0
  }, {
    "t": 1593709448000,
    "lat": 54.351045,
    "lng": 18.597663333333333,
    "PM10": 170.0,
    "PM25": 163.0
  }, {"t": 1593709449000, "lat": 54.351045, "lng": 18.597663333333333, "PM10": 169.0}, {
    "t": 1593709450000,
    "lat": 54.351045,
    "lng": 18.59766,
    "PM10": 169.0,
    "PM25": 163.0
  }, {"t": 1593709451000, "lat": 54.351045, "lng": 18.59766, "PM10": 166.0, "PM25": 161.0}, {
    "t": 1593709452000,
    "lat": 54.351045,
    "lng": 18.59766,
    "PM10": 166.0,
    "PM25": 161.0
  }, {
    "t": 1593709453000,
    "lat": 54.35102833333333,
    "lng": 18.597673333333333,
    "PM10": 166.0,
    "PM25": 161.0,
    "temp": 24.200000762939453,
    "RH": 51.400001525878906
  }, {
    "t": 1593709454000,
    "lat": 54.35102833333333,
    "lng": 18.597673333333333,
    "PM10": 168.0,
    "PM25": 163.0
  }, {
    "t": 1593709455000,
    "lat": 54.35100333333333,
    "lng": 18.597718333333333,
    "PM10": 168.0,
    "PM25": 163.0,
    "temp": 24.100000381469727,
    "RH": 51.5
  }, {
    "t": 1593709456000,
    "lat": 54.35100333333333,
    "lng": 18.597718333333333,
    "PM10": 149.0,
    "PM25": 145.0
  }, {
    "t": 1593709457000,
    "lat": 54.350991666666665,
    "lng": 18.597891666666666,
    "PM10": 123.0,
    "PM25": 118.0,
    "temp": 24.200000762939453,
    "RH": 51.5
  }, {
    "t": 1593709458000,
    "lat": 54.350991666666665,
    "lng": 18.597891666666666,
    "PM10": 102.0,
    "PM25": 96.0
  }, {
    "t": 1593709459000,
    "lat": 54.350991666666665,
    "lng": 18.597891666666666,
    "PM10": 89.0,
    "PM25": 83.0
  }, {
    "t": 1593709460000,
    "lat": 54.350991666666665,
    "lng": 18.597891666666666,
    "PM10": 79.0,
    "PM25": 70.0
  }, {"t": 1593709461000, "lat": 54.351031666666664, "lng": 18.59798, "PM10": 77.0, "PM25": 65.0}, {
    "t": 1593709462000,
    "lat": 54.351031666666664,
    "lng": 18.59798,
    "PM10": 76.0,
    "PM25": 63.0
  }, {"t": 1593709463000, "lat": 54.351031666666664, "lng": 18.59798, "PM25": 59.0}, {
    "t": 1593709464000,
    "lat": 54.351076666666664,
    "lng": 18.598148333333334,
    "PM10": 74.0,
    "PM25": 50.0
  }, {
    "t": 1593709465000,
    "lat": 54.351076666666664,
    "lng": 18.598148333333334,
    "PM10": 68.5,
    "PM25": 50.0
  }, {
    "t": 1593709466000,
    "lat": 54.351063333333336,
    "lng": 18.598233333333333,
    "PM10": 69.0,
    "PM25": 50.0,
    "temp": 24.399999618530273,
    "RH": 51.70000076293945
  }, {
    "t": 1593709467000,
    "lat": 54.351063333333336,
    "lng": 18.598233333333333,
    "PM10": 70.0,
    "PM25": 51.0
  }, {
    "t": 1593709468000,
    "lat": 54.351063333333336,
    "lng": 18.598233333333333,
    "PM10": 76.0,
    "PM25": 58.0
  }, {
    "t": 1593709469000,
    "lat": 54.35102833333333,
    "lng": 18.598266666666667,
    "PM10": 82.0,
    "PM25": 68.0
  }, {
    "t": 1593709470000,
    "lat": 54.35102833333333,
    "lng": 18.598266666666667,
    "PM10": 94.0,
    "PM25": 78.0
  }, {
    "t": 1593709471000,
    "lat": 54.35102833333333,
    "lng": 18.598266666666667,
    "PM10": 100.0,
    "PM25": 86.0
  }, {
    "t": 1593709472000,
    "lat": 54.35102333333333,
    "lng": 18.598238333333335,
    "PM10": 102.0,
    "PM25": 88.0
  }, {
    "t": 1593709473000,
    "lat": 54.35102333333333,
    "lng": 18.598238333333335,
    "PM10": 106.0,
    "PM25": 94.0
  }, {
    "t": 1593709474000,
    "lat": 54.351011666666665,
    "lng": 18.598228333333335,
    "PM10": 122.0,
    "PM25": 112.0,
    "temp": 24.200000762939453,
    "RH": 51.900001525878906
  }, {
    "t": 1593709475000,
    "lat": 54.351011666666665,
    "lng": 18.598228333333335,
    "PM10": 142.0,
    "PM25": 132.0,
    "temp": 24.200000762939453,
    "RH": 51.900001525878906
  }, {
    "t": 1593709476000,
    "lat": 54.35101,
    "lng": 18.598228333333335,
    "PM10": 154.0,
    "PM25": 147.0
  }, {
    "t": 1593709477000,
    "lat": 54.35101,
    "lng": 18.598228333333335,
    "PM10": 158.0,
    "PM25": 153.0
  }, {"t": 1593709478000, "lat": 54.35101, "lng": 18.598228333333335, "PM10": 162.0}, {
    "t": 1593709479000,
    "lat": 54.35100333333333,
    "lng": 18.59824,
    "PM10": 159.0,
    "PM25": 150.0
  }, {
    "t": 1593709480000,
    "lat": 54.35100333333333,
    "lng": 18.59824,
    "PM10": 155.0,
    "PM25": 145.0
  }, {
    "t": 1593709481000,
    "lat": 54.351005,
    "lng": 18.598245,
    "PM10": 153.0,
    "PM25": 143.0,
    "temp": 24.100000381469727,
    "RH": 51.900001525878906
  }, {
    "t": 1593709482000,
    "lat": 54.351005,
    "lng": 18.598245,
    "PM10": 153.0,
    "PM25": 143.0,
    "temp": 24.100000381469727,
    "RH": 52.099998474121094
  }, {"t": 1593709483000, "lat": 54.351005, "lng": 18.598245, "PM10": 147.0, "PM25": 136.0}, {
    "t": 1593709484000,
    "lat": 54.35100666666666,
    "lng": 18.59825,
    "PM10": 141.0,
    "PM25": 129.0,
    "temp": 24.100000381469727,
    "RH": 52.099998474121094
  }, {
    "t": 1593709485000,
    "lat": 54.35100666666666,
    "lng": 18.59825,
    "PM10": 129.0,
    "PM25": 109.5
  }, {
    "t": 1593709486000,
    "lat": 54.35100833333333,
    "lng": 18.598256666666668,
    "PM10": 115.0,
    "PM25": 89.0
  }, {
    "t": 1593709487000,
    "lat": 54.35100833333333,
    "lng": 18.598256666666668,
    "PM10": 101.0,
    "PM25": 74.0
  }, {"t": 1593709488000, "lat": 54.35100833333333, "lng": 18.598256666666668, "PM10": 85.0}, {
    "t": 1593709489000,
    "lat": 54.35101,
    "lng": 18.598258333333334,
    "PM10": 75.0,
    "PM25": 61.0
  }, {"t": 1593709490000, "lat": 54.35101, "lng": 18.598258333333334, "PM10": 68.0, "PM25": 51.0}, {
    "t": 1593709491000,
    "lat": 54.35101,
    "lng": 18.598258333333334,
    "PM10": 60.0,
    "PM25": 44.0
  }, {"t": 1593709492000, "lat": 54.351011666666665, "lng": 18.59826, "PM10": 55.0, "PM25": 40.0}, {
    "t": 1593709493000,
    "lat": 54.351011666666665,
    "lng": 18.59826,
    "PM10": 50.0,
    "PM25": 34.0
  }, {"t": 1593709494000, "lat": 54.351011666666665, "lng": 18.59826, "PM10": 42.5, "PM25": 27.0}, {
    "t": 1593709495000,
    "lat": 54.35100333333333,
    "lng": 18.598256666666668,
    "PM10": 42.0,
    "PM25": 26.0
  }, {
    "t": 1593709496000,
    "lat": 54.35100333333333,
    "lng": 18.598256666666668,
    "PM10": 37.0,
    "PM25": 23.0,
    "temp": 24.0,
    "RH": 52.400001525878906
  }, {
    "t": 1593709497000,
    "lat": 54.35100333333333,
    "lng": 18.598256666666668,
    "PM10": 37.0,
    "PM25": 23.0
  }, {
    "t": 1593709498000,
    "lat": 54.35100333333333,
    "lng": 18.598256666666668,
    "PM10": 37.0,
    "PM25": 23.0
  }, {
    "t": 1593709499000,
    "lat": 54.35096333333333,
    "lng": 18.598073333333332,
    "PM10": 52.0,
    "PM25": 38.0,
    "temp": 24.0,
    "RH": 52.599998474121094
  }, {
    "t": 1593709500000,
    "lat": 54.35096333333333,
    "lng": 18.598073333333332,
    "PM10": 63.0,
    "PM25": 47.0
  }, {"t": 1593709501000, "lat": 54.35096333333333, "lng": 18.598073333333332, "PM25": 61.0}, {
    "t": 1593709502000,
    "lat": 54.350986666666664,
    "lng": 18.598025,
    "PM10": 76.0,
    "PM25": 86.0,
    "temp": 24.299999237060547,
    "RH": 52.599998474121094
  }, {
    "t": 1593709503000,
    "lat": 54.350986666666664,
    "lng": 18.598025,
    "PM10": 103.0,
    "PM25": 95.5
  }, {
    "t": 1593709504000,
    "lat": 54.35103,
    "lng": 18.598051666666667,
    "PM10": 105.0,
    "PM25": 93.0,
    "temp": 24.399999618530273,
    "RH": 52.599998474121094
  }, {
    "t": 1593709505000,
    "lat": 54.35103,
    "lng": 18.598051666666667,
    "PM10": 105.0,
    "PM25": 95.0,
    "temp": 24.5,
    "RH": 52.5
  }, {"t": 1593709506000, "lat": 54.35103, "lng": 18.598051666666667, "PM10": 106.0}, {
    "t": 1593709507000,
    "lat": 54.35103,
    "lng": 18.598051666666667,
    "PM10": 113.0,
    "PM25": 100.0,
    "temp": 24.399999618530273,
    "RH": 52.400001525878906
  }, {
    "t": 1593709508000,
    "lat": 54.35103,
    "lng": 18.598051666666667,
    "PM10": 129.0,
    "PM25": 116.0
  }, {
    "t": 1593709509000,
    "lat": 54.35103,
    "lng": 18.598051666666667,
    "PM10": 133.0,
    "PM25": 119.0
  }, {
    "t": 1593709510000,
    "lat": 54.35102833333333,
    "lng": 18.598105,
    "PM10": 134.0,
    "PM25": 119.0
  }, {
    "t": 1593709511000,
    "lat": 54.35102833333333,
    "lng": 18.598105,
    "PM10": 129.0,
    "PM25": 115.0
  }, {
    "t": 1593709512000,
    "lat": 54.35102833333333,
    "lng": 18.598105,
    "PM10": 129.0,
    "PM25": 115.0
  }, {
    "t": 1593709513000,
    "lat": 54.35102833333333,
    "lng": 18.598105,
    "PM10": 13.0,
    "PM25": 111.5
  }, {
    "t": 1593709514000,
    "lat": 54.35102833333333,
    "lng": 18.598105,
    "PM10": 121.0,
    "PM25": 108.0
  }, {
    "t": 1593709515000,
    "lat": 54.35101,
    "lng": 18.59810166666667,
    "PM10": 121.0,
    "PM25": 108.0
  }, {
    "t": 1593709516000,
    "lat": 54.35101,
    "lng": 18.59810166666667,
    "PM10": 123.0,
    "PM25": 111.0
  }, {
    "t": 1593709517000,
    "lat": 54.35101,
    "lng": 18.59810166666667,
    "PM10": 123.0,
    "PM25": 111.0
  }, {
    "t": 1593709518000,
    "lat": 54.35101,
    "lng": 18.59810166666667,
    "PM10": 123.0,
    "PM25": 112.5
  }, {"t": 1593709519000, "lat": 54.35101, "lng": 18.5981, "PM10": 13.0, "PM25": 119.0}, {
    "t": 1593709520000,
    "lat": 54.351011666666665,
    "lng": 18.59810166666667,
    "PM10": 131.0,
    "PM25": 123.0
  }, {
    "t": 1593709521000,
    "lat": 54.351011666666665,
    "lng": 18.59810166666667,
    "PM10": 128.0,
    "PM25": 119.0
  }, {
    "t": 1593709522000,
    "lat": 54.351011666666665,
    "lng": 18.59810166666667,
    "PM10": 121.0,
    "PM25": 112.0
  }, {
    "t": 1593709523000,
    "lat": 54.351013333333334,
    "lng": 18.598103333333334,
    "PM10": 119.0,
    "PM25": 111.0
  }, {
    "t": 1593709524000,
    "lat": 54.351013333333334,
    "lng": 18.598103333333334,
    "PM10": 117.0,
    "PM25": 109.0
  }, {
    "t": 1593709525000,
    "lat": 54.351016666666666,
    "lng": 18.598106666666666,
    "PM10": 116.0,
    "PM25": 108.5
  }, {
    "t": 1593709526000,
    "lat": 54.351016666666666,
    "lng": 18.598106666666666,
    "PM10": 113.0,
    "PM25": 103.0
  }, {"t": 1593709527000, "lat": 54.351016666666666, "lng": 18.598106666666666, "PM25": 111.0}, {
    "t": 1593709528000,
    "lat": 54.351018333333336,
    "lng": 18.59811,
    "PM10": 122.0,
    "PM25": 120.0
  }, {
    "t": 1593709529000,
    "lat": 54.351018333333336,
    "lng": 18.59811,
    "PM10": 130.0,
    "PM25": 126.0
  }, {
    "t": 1593709530000,
    "lat": 54.351018333333336,
    "lng": 18.59811,
    "PM10": 136.0,
    "PM25": 126.0
  }, {"t": 1593709531000, "lat": 54.35102, "lng": 18.59811, "PM10": 136.5, "PM25": 131.0}, {
    "t": 1593709532000,
    "lat": 54.35102,
    "lng": 18.59811,
    "PM10": 144.0,
    "PM25": 135.0
  }, {
    "t": 1593709533000,
    "lat": 54.35102166666667,
    "lng": 18.598108333333332,
    "PM10": 144.0,
    "PM25": 135.0
  }, {
    "t": 1593709534000,
    "lat": 54.35102166666667,
    "lng": 18.598108333333332,
    "PM10": 144.0,
    "PM25": 137.0
  }, {
    "t": 1593709535000,
    "lat": 54.35102166666667,
    "lng": 18.598108333333332,
    "PM10": 146.0,
    "PM25": 137.0
  }, {
    "t": 1593709536000,
    "lat": 54.35102,
    "lng": 18.598106666666666,
    "PM10": 146.0,
    "PM25": 137.0
  }, {
    "t": 1593709537000,
    "lat": 54.35102,
    "lng": 18.598106666666666,
    "PM10": 127.5,
    "PM25": 120.0
  }, {"t": 1593709538000, "lat": 54.351015, "lng": 18.59811, "PM10": 120.0, "PM25": 112.0}, {
    "t": 1593709539000,
    "lat": 54.351015,
    "lng": 18.59811,
    "PM10": 118.0,
    "PM25": 111.0
  }, {"t": 1593709540000, "lat": 54.351015, "lng": 18.59811, "PM10": 117.0, "PM25": 109.0}, {
    "t": 1593709541000,
    "lat": 54.351015,
    "lng": 18.598113333333334,
    "PM10": 117.0,
    "PM25": 109.0
  }, {
    "t": 1593709542000,
    "lat": 54.351015,
    "lng": 18.598113333333334,
    "PM10": 116.0,
    "PM25": 108.0
  }, {"t": 1593709543000, "lat": 54.351015, "lng": 18.598113333333334, "PM25": 104.0}, {
    "t": 1593709544000,
    "lat": 54.35102166666667,
    "lng": 18.598145,
    "PM10": 112.0,
    "PM25": 99.0,
    "temp": 23.799999237060547,
    "RH": 53.0
  }, {
    "t": 1593709545000,
    "lat": 54.35102166666667,
    "lng": 18.598145,
    "PM10": 108.0,
    "PM25": 88.0,
    "temp": 23.799999237060547,
    "RH": 53.0
  }, {"t": 1593709546000, "lat": 54.35102166666667, "lng": 18.598145, "PM10": 99.0, "PM25": 86.0}, {
    "t": 1593709547000,
    "lat": 54.351036666666666,
    "lng": 18.59822,
    "PM10": 98.0,
    "PM25": 82.0,
    "temp": 23.799999237060547,
    "RH": 53.099998474121094
  }, {"t": 1593709548000, "lat": 54.351036666666666, "lng": 18.59822, "PM10": 94.0, "PM25": 72.0}, {
    "t": 1593709549000,
    "lat": 54.351045,
    "lng": 18.59835,
    "PM10": 85.5,
    "PM25": 73.0
  }, {"t": 1593709550000, "lat": 54.351045, "lng": 18.59835, "PM10": 86.0, "PM25": 65.0}, {
    "t": 1593709551000,
    "lat": 54.351045,
    "lng": 18.59835,
    "PM10": 78.0,
    "PM25": 55.0
  }, {
    "t": 1593709552000,
    "lat": 54.35105166666666,
    "lng": 18.598489999999998,
    "PM10": 73.0,
    "PM25": 49.0
  }, {
    "t": 1593709553000,
    "lat": 54.35105166666666,
    "lng": 18.598489999999998,
    "PM10": 67.0,
    "PM25": 41.0
  }, {"t": 1593709554000, "lat": 54.35105166666666, "lng": 18.598489999999998, "PM10": 57.0}, {
    "t": 1593709555000,
    "lat": 54.35108,
    "lng": 18.598591666666668,
    "PM10": 49.0,
    "PM25": 34.0
  }, {"t": 1593709556000, "lat": 54.35108, "lng": 18.598591666666668, "PM10": 42.0, "PM25": 27.0}, {
    "t": 1593709557000,
    "lat": 54.35108,
    "lng": 18.598591666666668,
    "PM10": 41.0,
    "PM25": 3.0
  }, {"t": 1593709558000, "lat": 54.3511, "lng": 18.59861666666667, "PM10": 47.0, "PM25": 30.5}, {
    "t": 1593709559000,
    "lat": 54.3511,
    "lng": 18.59861666666667,
    "PM10": 51.0,
    "PM25": 36.0
  }, {
    "t": 1593709560000,
    "lat": 54.35108833333334,
    "lng": 18.598636666666668,
    "PM10": 55.0,
    "PM25": 38.0
  }, {
    "t": 1593709561000,
    "lat": 54.35108833333334,
    "lng": 18.598636666666668,
    "PM10": 55.0,
    "PM25": 39.0
  }, {
    "t": 1593709562000,
    "lat": 54.35108833333334,
    "lng": 18.598636666666668,
    "PM10": 55.0,
    "PM25": 39.0
  }, {
    "t": 1593709563000,
    "lat": 54.351076666666664,
    "lng": 18.598691666666667,
    "PM10": 56.0,
    "PM25": 40.0
  }, {
    "t": 1593709564000,
    "lat": 54.351076666666664,
    "lng": 18.598691666666667,
    "PM10": 57.0,
    "PM25": 42.0
  }, {"t": 1593709565000, "lat": 54.351076666666664, "lng": 18.598691666666667}, {
    "t": 1593709566000,
    "lat": 54.35104833333333,
    "lng": 18.598726666666668,
    "PM10": 55.0,
    "PM25": 41.0,
    "temp": 23.600000381469727,
    "RH": 53.400001525878906
  }, {
    "t": 1593709567000,
    "lat": 54.35104833333333,
    "lng": 18.598726666666668,
    "PM10": 48.666666666666664,
    "PM25": 36.666666666666664,
    "temp": 23.600000381469727,
    "RH": 53.53333282470703
  }, {
    "t": 1593709568000,
    "lat": 54.35102166666667,
    "lng": 18.598731666666666,
    "PM10": 38.0,
    "PM25": 31.0
  }, {
    "t": 1593709569000,
    "lat": 54.35102166666667,
    "lng": 18.598731666666666,
    "PM10": 35.0,
    "PM25": 29.0
  }, {
    "t": 1593709570000,
    "lat": 54.35098166666667,
    "lng": 18.598711666666667,
    "PM10": 29.0,
    "PM25": 24.0,
    "temp": 23.600000381469727
  }, {
    "t": 1593709571000,
    "lat": 54.35098166666667,
    "lng": 18.598711666666667,
    "PM10": 28.0,
    "PM25": 3.0
  }, {
    "t": 1593709572000,
    "lat": 54.35098166666667,
    "lng": 18.598711666666667,
    "PM10": 28.0,
    "PM25": 26.0
  }, {
    "t": 1593709573000,
    "lat": 54.35095833333333,
    "lng": 18.598653333333335,
    "PM10": 26.0,
    "PM25": 24.0,
    "temp": 23.5,
    "RH": 53.599998474121094
  }, {
    "t": 1593709574000,
    "lat": 54.35095833333333,
    "lng": 18.598653333333335,
    "PM10": 27.0,
    "PM25": 3.0,
    "temp": 23.399999618530273,
    "RH": 53.79999923706055
  }, {
    "t": 1593709575000,
    "lat": 54.35095833333333,
    "lng": 18.598653333333335,
    "PM10": 29.0,
    "PM25": 27.0
  }, {
    "t": 1593709576000,
    "lat": 54.35095333333334,
    "lng": 18.59859,
    "PM10": 30.0,
    "PM25": 28.0,
    "temp": 23.299999237060547,
    "RH": 53.900001525878906
  }, {
    "t": 1593709577000,
    "lat": 54.35095333333334,
    "lng": 18.59859,
    "PM10": 31.0,
    "PM25": 29.0,
    "temp": 23.299999237060547,
    "RH": 54.099998474121094
  }, {"t": 1593709578000, "lat": 54.35095833333333, "lng": 18.598565, "PM10": 33.0, "PM25": 31.0}, {
    "t": 1593709579000,
    "lat": 54.35095833333333,
    "lng": 18.598565,
    "PM10": 33.0,
    "PM25": 31.0
  }, {"t": 1593709580000, "lat": 54.35095833333333, "lng": 18.598565, "PM10": 47.0, "PM25": 40.0}, {
    "t": 1593709581000,
    "lat": 54.35099,
    "lng": 18.598541666666666,
    "PM10": 50.0,
    "PM25": 42.0
  }, {"t": 1593709582000, "lat": 54.35099, "lng": 18.598541666666666, "PM10": 51.0, "PM25": 42.0}, {
    "t": 1593709583000,
    "lat": 54.35099,
    "lng": 18.598541666666666,
    "PM25": 42.0
  }, {
    "t": 1593709584000,
    "lat": 54.35100666666666,
    "lng": 18.598573333333334,
    "PM10": 52.0,
    "PM25": 43.0,
    "temp": 23.399999618530273,
    "RH": 54.29999923706055
  }, {
    "t": 1593709585000,
    "lat": 54.35100666666666,
    "lng": 18.598573333333334,
    "PM10": 55.0,
    "PM25": 44.0
  }, {
    "t": 1593709586000,
    "lat": 54.35100666666666,
    "lng": 18.598573333333334,
    "PM10": 56.0,
    "PM25": 44.0
  }, {
    "t": 1593709587000,
    "lat": 54.35100833333333,
    "lng": 18.598611666666667,
    "PM10": 57.5,
    "PM25": 44.0
  }, {
    "t": 1593709588000,
    "lat": 54.35100833333333,
    "lng": 18.598611666666667,
    "PM10": 60.0,
    "PM25": 43.0
  }, {
    "t": 1593709589000,
    "lat": 54.351013333333334,
    "lng": 18.598621666666666,
    "PM10": 60.0,
    "PM25": 35.0
  }, {
    "t": 1593709590000,
    "lat": 54.351013333333334,
    "lng": 18.598621666666666,
    "PM10": 51.0,
    "PM25": 35.0
  }, {
    "t": 1593709591000,
    "lat": 54.351013333333334,
    "lng": 18.598621666666666,
    "PM10": 51.0,
    "PM25": 34.0
  }, {
    "t": 1593709592000,
    "lat": 54.351011666666665,
    "lng": 18.598591666666668,
    "PM10": 50.0,
    "PM25": 33.0
  }, {
    "t": 1593709593000,
    "lat": 54.351011666666665,
    "lng": 18.598591666666668,
    "PM10": 49.0,
    "PM25": 33.0
  }, {"t": 1593709594000, "lat": 54.351011666666665, "lng": 18.598591666666668, "PM10": 49.0}, {
    "t": 1593709595000,
    "lat": 54.35100666666666,
    "lng": 18.59855,
    "PM10": 47.0,
    "PM25": 32.0
  }, {"t": 1593709596000, "lat": 54.35100666666666, "lng": 18.59855, "PM10": 47.0, "PM25": 32.0}, {
    "t": 1593709597000,
    "lat": 54.35100666666666,
    "lng": 18.598448333333334,
    "PM10": 44.0,
    "PM25": 29.5,
    "temp": 23.100000381469727,
    "RH": 54.599998474121094
  }, {
    "t": 1593709598000,
    "lat": 54.35100666666666,
    "lng": 18.598448333333334,
    "PM10": 41.0,
    "PM25": 28.0
  }, {"t": 1593709599000, "lat": 54.35100666666666, "lng": 18.598448333333334, "PM10": 40.0}, {
    "t": 1593709600000,
    "lat": 54.351013333333334,
    "lng": 18.598311666666667,
    "PM10": 36.0,
    "PM25": 24.0
  }, {
    "t": 1593709601000,
    "lat": 54.351013333333334,
    "lng": 18.598311666666667,
    "PM10": 39.0,
    "PM25": 27.0
  }, {
    "t": 1593709602000,
    "lat": 54.351013333333334,
    "lng": 18.598311666666667,
    "PM10": 36.0,
    "PM25": 24.0
  }, {"t": 1593709603000, "lat": 54.35101, "lng": 18.598226666666665, "PM10": 35.0, "PM25": 22.0}, {
    "t": 1593709604000,
    "lat": 54.35101,
    "lng": 18.598226666666665,
    "PM10": 35.0,
    "PM25": 23.0
  }, {"t": 1593709605000, "lat": 54.35101, "lng": 18.598226666666665, "PM10": 36.0, "PM25": 24.0}, {
    "t": 1593709606000,
    "lat": 54.351013333333334,
    "lng": 18.59814,
    "PM10": 39.0,
    "PM25": 24.0
  }, {"t": 1593709607000, "lat": 54.351013333333334, "lng": 18.59814, "PM10": 42.0, "PM25": 26.0}, {
    "t": 1593709608000,
    "lat": 54.35099666666667,
    "lng": 18.598093333333335,
    "PM10": 42.0,
    "PM25": 26.0,
    "temp": 22.600000381469727,
    "RH": 55.5
  }, {
    "t": 1593709609000,
    "lat": 54.35099666666667,
    "lng": 18.598093333333335,
    "PM10": 42.0,
    "PM25": 26.0
  }, {
    "t": 1593709610000,
    "lat": 54.35099666666667,
    "lng": 18.598093333333335,
    "PM10": 42.0,
    "PM25": 26.0
  }, {
    "t": 1593709611000,
    "lat": 54.350986666666664,
    "lng": 18.598025,
    "PM10": 40.0,
    "PM25": 26.0
  }, {
    "t": 1593709612000,
    "lat": 54.350986666666664,
    "lng": 18.598025,
    "PM10": 40.0,
    "PM25": 26.0
  }, {
    "t": 1593709613000,
    "lat": 54.350986666666664,
    "lng": 18.598025,
    "PM10": 37.5,
    "PM25": 23.333333333333332
  }, {"t": 1593709614000, "lat": 54.35099, "lng": 18.597943333333333, "PM10": 35.0, "PM25": 22.0}, {
    "t": 1593709615000,
    "lat": 54.35099,
    "lng": 18.597943333333333,
    "PM10": 35.0,
    "PM25": 21.0
  }, {
    "t": 1593709616000,
    "lat": 54.350995,
    "lng": 18.597868333333334,
    "PM10": 32.0,
    "PM25": 21.0
  }, {
    "t": 1593709617000,
    "lat": 54.350995,
    "lng": 18.597868333333334,
    "PM10": 32.0,
    "PM25": 21.0
  }, {
    "t": 1593709618000,
    "lat": 54.350995,
    "lng": 18.597868333333334,
    "PM10": 32.0,
    "PM25": 20.0
  }, {"t": 1593709619000, "lat": 54.350985, "lng": 18.59773, "PM10": 29.0, "PM25": 20.5}, {
    "t": 1593709620000,
    "lat": 54.350985,
    "lng": 18.59773,
    "PM10": 30.0,
    "PM25": 20.0
  }, {"t": 1593709621000, "lat": 54.350985, "lng": 18.59773, "PM10": 28.0, "PM25": 20.0}, {
    "t": 1593709622000,
    "lat": 54.35097833333333,
    "lng": 18.597605,
    "PM10": 28.0,
    "PM25": 20.0
  }, {"t": 1593709623000, "lat": 54.35097833333333, "lng": 18.597605, "PM10": 3.0, "PM25": 18.0}, {
    "t": 1593709624000,
    "lat": 54.35097666666667,
    "lng": 18.597451666666668,
    "PM10": 3.0,
    "PM25": 18.0
  }, {
    "t": 1593709625000,
    "lat": 54.35097666666667,
    "lng": 18.597451666666668,
    "PM10": 3.0,
    "PM25": 18.0
  }, {
    "t": 1593709626000,
    "lat": 54.35098333333333,
    "lng": 18.597341666666665,
    "PM10": 22.0,
    "PM25": 17.0
  }, {
    "t": 1593709627000,
    "lat": 54.35098333333333,
    "lng": 18.597341666666665,
    "PM10": 22.0,
    "PM25": 17.0
  }, {
    "t": 1593709628000,
    "lat": 54.35098333333333,
    "lng": 18.597341666666665,
    "PM10": 18.0,
    "PM25": 15.0
  }, {"t": 1593709629000, "lat": 54.35098333333333, "lng": 18.597341666666665}, {
    "t": 1593709630000,
    "lat": 54.35098,
    "lng": 18.597208333333334,
    "PM10": 18.0,
    "PM25": 15.0
  }, {"t": 1593709631000, "lat": 54.35098, "lng": 18.597208333333334, "PM10": 18.0, "PM25": 15.0}, {
    "t": 1593709632000,
    "lat": 54.35098,
    "lng": 18.597208333333334,
    "PM10": 19.0,
    "PM25": 17.0
  }, {"t": 1593709633000, "lat": 54.35100333333333, "lng": 18.597255, "PM10": 19.5, "PM25": 16.0}, {
    "t": 1593709634000,
    "lat": 54.35100333333333,
    "lng": 18.597255,
    "PM10": 24.0,
    "PM25": 18.0
  }, {
    "t": 1593709635000,
    "lat": 54.351045,
    "lng": 18.597348333333333,
    "PM10": 28.0,
    "PM25": 21.0,
    "RH": 57.70000076293945
  }, {
    "t": 1593709636000,
    "lat": 54.351045,
    "lng": 18.597348333333333,
    "PM10": 30.0,
    "PM25": 24.0
  }, {
    "t": 1593709637000,
    "lat": 54.351045,
    "lng": 18.597348333333333,
    "PM10": 35.0,
    "PM25": 32.0
  }, {
    "t": 1593709638000,
    "lat": 54.35109,
    "lng": 18.597448333333332,
    "PM10": 45.0,
    "PM25": 37.0,
    "temp": 21.899999618530273,
    "RH": 57.79999923706055
  }, {
    "t": 1593709639000,
    "lat": 54.35109,
    "lng": 18.597448333333332,
    "PM10": 51.0,
    "PM25": 37.0,
    "temp": 21.899999618530273
  }, {"t": 1593709640000, "lat": 54.35109, "lng": 18.597448333333332, "PM10": 52.0, "PM25": 35.0}, {
    "t": 1593709641000,
    "lat": 54.351101666666665,
    "lng": 18.597518333333333,
    "PM10": 51.0,
    "PM25": 36.0
  }, {
    "t": 1593709642000,
    "lat": 54.351101666666665,
    "lng": 18.597518333333333,
    "PM10": 51.0,
    "PM25": 35.0
  }, {
    "t": 1593709643000,
    "lat": 54.35111166666667,
    "lng": 18.59758666666667,
    "PM10": 49.666666666666664,
    "PM25": 34.5,
    "temp": 21.899999618530273,
    "RH": 58.0
  }, {
    "t": 1593709644000,
    "lat": 54.35111166666667,
    "lng": 18.59758666666667,
    "PM10": 59.0,
    "PM25": 43.0,
    "temp": 21.899999618530273,
    "RH": 58.5
  }, {
    "t": 1593709645000,
    "lat": 54.35111166666667,
    "lng": 18.59758666666667,
    "PM10": 63.0,
    "PM25": 47.0
  }, {"t": 1593709646000, "lat": 54.35111, "lng": 18.59758666666667, "PM10": 71.0, "PM25": 56.0}, {
    "t": 1593709647000,
    "lat": 54.35111,
    "lng": 18.59758666666667,
    "PM10": 75.0,
    "PM25": 62.0
  }, {"t": 1593709648000, "lat": 54.35111, "lng": 18.59758666666667, "PM10": 77.0, "PM25": 66.0}, {
    "t": 1593709649000,
    "lat": 54.351105,
    "lng": 18.59758666666667,
    "PM10": 79.0,
    "PM25": 70.0
  }, {"t": 1593709650000, "lat": 54.351105, "lng": 18.59758666666667, "PM10": 92.0, "PM25": 82.0}, {
    "t": 1593709651000,
    "lat": 54.351105,
    "lng": 18.59758666666667,
    "PM10": 103.0,
    "PM25": 93.0
  }, {
    "t": 1593709652000,
    "lat": 54.351105,
    "lng": 18.59758666666667,
    "PM10": 109.0,
    "PM25": 100.0,
    "temp": 21.899999618530273,
    "RH": 58.20000076293945
  }, {
    "t": 1593709653000,
    "lat": 54.351105,
    "lng": 18.59758666666667,
    "PM10": 110.0,
    "PM25": 99.0,
    "temp": 21.799999237060547,
    "RH": 58.29999923706055
  }, {"t": 1593709654000, "lat": 54.351105, "lng": 18.59758666666667, "PM10": 96.0, "PM25": 85.0}, {
    "t": 1593709655000,
    "lat": 54.351105,
    "lng": 18.59758666666667,
    "PM10": 84.0,
    "PM25": 73.0
  }, {"t": 1593709656000, "lat": 54.351105, "lng": 18.59758666666667, "PM10": 86.0, "PM25": 76.0}, {
    "t": 1593709657000,
    "lat": 54.351103333333334,
    "lng": 18.59759,
    "PM10": 84.0,
    "PM25": 74.0
  }, {"t": 1593709658000, "lat": 54.351103333333334, "lng": 18.59759, "PM10": 92.0, "PM25": 82.0}, {
    "t": 1593709659000,
    "lat": 54.351103333333334,
    "lng": 18.59759,
    "PM10": 96.0,
    "PM25": 86.0
  }, {
    "t": 1593709660000,
    "lat": 54.351101666666665,
    "lng": 18.597593333333332,
    "PM10": 100.0,
    "PM25": 91.0
  }, {
    "t": 1593709661000,
    "lat": 54.351101666666665,
    "lng": 18.597593333333332,
    "PM10": 105.0,
    "PM25": 96.0
  }, {
    "t": 1593709662000,
    "lat": 54.351101666666665,
    "lng": 18.597593333333332,
    "PM10": 112.0,
    "PM25": 104.0
  }, {
    "t": 1593709663000,
    "lat": 54.3511,
    "lng": 18.597593333333332,
    "PM10": 124.0,
    "PM25": 116.0,
    "temp": 21.799999237060547,
    "RH": 58.79999923706055
  }, {
    "t": 1593709664000,
    "lat": 54.3511,
    "lng": 18.597593333333332,
    "PM10": 144.0,
    "PM25": 134.0
  }, {
    "t": 1593709665000,
    "lat": 54.3511,
    "lng": 18.597593333333332,
    "PM10": 152.0,
    "PM25": 142.0
  }, {
    "t": 1593709666000,
    "lat": 54.35109833333333,
    "lng": 18.597625,
    "PM10": 146.0,
    "PM25": 136.0
  }, {
    "t": 1593709667000,
    "lat": 54.35109833333333,
    "lng": 18.597625,
    "PM10": 132.0,
    "PM25": 122.0
  }, {
    "t": 1593709668000,
    "lat": 54.35109833333333,
    "lng": 18.597625,
    "PM10": 118.0,
    "PM25": 106.0
  }, {
    "t": 1593709669000,
    "lat": 54.35111333333333,
    "lng": 18.597681666666666,
    "PM10": 102.0,
    "PM25": 90.0
  }, {
    "t": 1593709670000,
    "lat": 54.35111333333333,
    "lng": 18.597681666666666,
    "PM10": 102.0,
    "PM25": 90.0
  }, {
    "t": 1593709671000,
    "lat": 54.35111333333333,
    "lng": 18.597681666666666,
    "PM10": 104.0,
    "PM25": 92.0
  }, {"t": 1593709672000, "lat": 54.35111333333333, "lng": 18.597681666666666}, {
    "t": 1593709673000,
    "lat": 54.35112,
    "lng": 18.597706666666667,
    "PM10": 86.5,
    "PM25": 74.5
  }, {
    "t": 1593709674000,
    "lat": 54.351148333333335,
    "lng": 18.597838333333332,
    "PM10": 69.5,
    "PM25": 51.5
  }, {
    "t": 1593709675000,
    "lat": 54.351148333333335,
    "lng": 18.597838333333332,
    "PM10": 56.0,
    "PM25": 41.0
  }, {
    "t": 1593709676000,
    "lat": 54.351148333333335,
    "lng": 18.597838333333332,
    "PM10": 45.0,
    "PM25": 31.0
  }, {
    "t": 1593709677000,
    "lat": 54.35115666666667,
    "lng": 18.59789333333333,
    "PM10": 41.0,
    "PM25": 27.0,
    "temp": 21.799999237060547,
    "RH": 58.900001525878906
  }, {
    "t": 1593709678000,
    "lat": 54.35115666666667,
    "lng": 18.59789333333333,
    "PM10": 39.0,
    "PM25": 3.0,
    "temp": 21.799999237060547,
    "RH": 58.900001525878906
  }, {
    "t": 1593709679000,
    "lat": 54.35115666666667,
    "lng": 18.59789333333333,
    "PM10": 41.0,
    "PM25": 27.0
  }, {"t": 1593709680000, "lat": 54.35115666666667, "lng": 18.59789333333333}, {
    "t": 1593709681000,
    "lat": 54.35115666666667,
    "lng": 18.59792,
    "PM10": 46.0,
    "PM25": 30.0
  }, {"t": 1593709682000, "lat": 54.35115666666667, "lng": 18.59792, "PM10": 45.0, "PM25": 28.0}, {
    "t": 1593709683000,
    "lat": 54.35115,
    "lng": 18.598038333333335,
    "PM10": 43.5,
    "PM25": 27.0
  }, {"t": 1593709684000, "lat": 54.35115, "lng": 18.598038333333335, "PM10": 42.0, "PM25": 26.0}, {
    "t": 1593709685000,
    "lat": 54.35115,
    "lng": 18.598038333333335,
    "PM10": 42.0,
    "PM25": 26.0
  }, {
    "t": 1593709686000,
    "lat": 54.351148333333335,
    "lng": 18.598096666666667,
    "PM10": 53.0,
    "PM25": 39.0
  }, {
    "t": 1593709687000,
    "lat": 54.351148333333335,
    "lng": 18.598096666666667,
    "PM10": 53.0,
    "PM25": 39.0
  }, {
    "t": 1593709688000,
    "lat": 54.351148333333335,
    "lng": 18.598096666666667,
    "PM10": 52.0,
    "PM25": 39.0
  }, {
    "t": 1593709689000,
    "lat": 54.35113166666667,
    "lng": 18.598125,
    "PM10": 51.0,
    "PM25": 39.0,
    "temp": 21.799999237060547,
    "RH": 59.0
  }, {"t": 1593709690000, "lat": 54.35113166666667, "lng": 18.598125, "PM10": 61.0, "PM25": 47.0}, {
    "t": 1593709691000,
    "lat": 54.35113166666667,
    "lng": 18.598125,
    "PM10": 67.0,
    "PM25": 53.0
  }, {
    "t": 1593709692000,
    "lat": 54.351115,
    "lng": 18.598166666666668,
    "PM10": 72.0,
    "PM25": 62.0
  }, {
    "t": 1593709693000,
    "lat": 54.351115,
    "lng": 18.598166666666668,
    "PM10": 75.0,
    "PM25": 68.5
  }, {
    "t": 1593709694000,
    "lat": 54.351115,
    "lng": 18.598166666666668,
    "PM10": 76.0,
    "PM25": 67.0
  }, {"t": 1593709695000, "lat": 54.3511, "lng": 18.598198333333332, "PM10": 70.0, "PM25": 74.0}, {
    "t": 1593709696000,
    "lat": 54.3511,
    "lng": 18.598198333333332,
    "PM10": 77.0,
    "PM25": 86.0
  }, {"t": 1593709697000, "lat": 54.35109, "lng": 18.598201666666668, "PM10": 86.0, "PM25": 87.0}, {
    "t": 1593709698000,
    "lat": 54.35109,
    "lng": 18.598201666666668,
    "PM10": 87.0,
    "PM25": 87.0
  }, {"t": 1593709699000, "lat": 54.35109, "lng": 18.598201666666668, "PM10": 87.0, "PM25": 95.0}, {
    "t": 1593709700000,
    "lat": 54.351083333333335,
    "lng": 18.598235,
    "PM10": 95.0,
    "PM25": 95.0,
    "temp": 21.799999237060547,
    "RH": 59.0
  }, {
    "t": 1593709701000,
    "lat": 54.351083333333335,
    "lng": 18.598235,
    "PM10": 88.5,
    "PM25": 88.5
  }, {"t": 1593709702000, "lat": 54.35107333333333, "lng": 18.598325, "PM10": 77.0, "PM25": 73.0}, {
    "t": 1593709703000,
    "lat": 54.35107333333333,
    "lng": 18.598325,
    "PM10": 73.0,
    "PM25": 62.0
  }, {"t": 1593709704000, "lat": 54.35107333333333, "lng": 18.598325, "PM10": 70.0, "PM25": 56.0}, {
    "t": 1593709705000,
    "lat": 54.35107333333333,
    "lng": 18.598325
  }, {"t": 1593709706000, "lat": 54.351065, "lng": 18.59848, "PM10": 67.0, "PM25": 52.0}, {
    "t": 1593709707000,
    "lat": 54.351065,
    "lng": 18.59848,
    "PM10": 65.0,
    "PM25": 51.0
  }, {"t": 1593709708000, "lat": 54.351065, "lng": 18.59848, "PM10": 66.0, "PM25": 51.0}, {
    "t": 1593709709000,
    "lat": 54.35107166666667,
    "lng": 18.598461666666665,
    "PM10": 64.0,
    "PM25": 48.0,
    "temp": 21.700000762939453,
    "RH": 58.900001525878906
  }, {
    "t": 1593709710000,
    "lat": 54.35107166666667,
    "lng": 18.598461666666665,
    "PM10": 59.0,
    "PM25": 42.0
  }, {
    "t": 1593709711000,
    "lat": 54.35107166666667,
    "lng": 18.598461666666665,
    "PM10": 58.0,
    "PM25": 41.0
  }, {"t": 1593709712000, "lat": 54.351075, "lng": 18.59826, "PM10": 63.5, "PM25": 45.5}, {
    "t": 1593709713000,
    "lat": 54.351075,
    "lng": 18.59826,
    "PM10": 65.0,
    "PM25": 48.0
  }, {"t": 1593709714000, "lat": 54.351075, "lng": 18.59815, "PM10": 69.5, "PM25": 51.5}, {
    "t": 1593709715000,
    "lat": 54.351075,
    "lng": 18.59815,
    "PM10": 74.0,
    "PM25": 57.0
  }, {"t": 1593709716000, "lat": 54.351075, "lng": 18.59815, "PM10": 74.0, "PM25": 57.0}, {
    "t": 1593709717000,
    "lat": 54.351081666666666,
    "lng": 18.597991666666665,
    "PM10": 74.0,
    "PM25": 57.0
  }, {
    "t": 1593709718000,
    "lat": 54.351081666666666,
    "lng": 18.597991666666665,
    "PM10": 76.0,
    "PM25": 61.0
  }, {
    "t": 1593709719000,
    "lat": 54.351081666666666,
    "lng": 18.597991666666665,
    "PM10": 76.0,
    "PM25": 62.0
  }, {"t": 1593709720000, "lat": 54.351081666666666, "lng": 18.597991666666665}, {
    "t": 1593709721000,
    "lat": 54.35108666666667,
    "lng": 18.59784,
    "PM10": 78.5,
    "PM25": 68.0
  }, {"t": 1593709722000, "lat": 54.35108666666667, "lng": 18.59784, "PM10": 80.0, "PM25": 70.0}, {
    "t": 1593709723000,
    "lat": 54.35109833333333,
    "lng": 18.597701666666666,
    "PM10": 81.0,
    "PM25": 72.0
  }, {
    "t": 1593709724000,
    "lat": 54.35109833333333,
    "lng": 18.597701666666666,
    "PM10": 82.0,
    "PM25": 73.0
  }, {
    "t": 1593709725000,
    "lat": 54.35109833333333,
    "lng": 18.597701666666666,
    "PM10": 83.0,
    "PM25": 75.0
  }, {
    "t": 1593709726000,
    "lat": 54.35109833333333,
    "lng": 18.597691666666666,
    "PM10": 92.0,
    "PM25": 82.0
  }, {
    "t": 1593709727000,
    "lat": 54.35109833333333,
    "lng": 18.597691666666666,
    "PM10": 101.0,
    "PM25": 91.0
  }, {
    "t": 1593709728000,
    "lat": 54.35109833333333,
    "lng": 18.597691666666666,
    "PM10": 106.0,
    "PM25": 95.0
  }, {
    "t": 1593709729000,
    "lat": 54.35109,
    "lng": 18.59771,
    "PM10": 114.0,
    "PM25": 102.5,
    "temp": 21.600000381469727,
    "RH": 59.0
  }, {"t": 1593709730000, "lat": 54.35109, "lng": 18.59771, "PM10": 114.0, "PM25": 107.0}, {
    "t": 1593709731000,
    "lat": 54.35109,
    "lng": 18.59771,
    "PM10": 118.0,
    "PM25": 112.0
  }, {
    "t": 1593709732000,
    "lat": 54.35108833333334,
    "lng": 18.597716666666667,
    "PM10": 123.0,
    "PM25": 118.0,
    "temp": 21.600000381469727,
    "RH": 59.29999923706055
  }, {
    "t": 1593709733000,
    "lat": 54.35108833333334,
    "lng": 18.597716666666667,
    "PM10": 131.0,
    "PM25": 129.0
  }, {"t": 1593709734000, "lat": 54.35109, "lng": 18.59772, "PM10": 145.0, "PM25": 134.0}, {
    "t": 1593709735000,
    "lat": 54.35109,
    "lng": 18.59772,
    "PM10": 145.0,
    "PM25": 13.0
  }, {"t": 1593709736000, "lat": 54.35109, "lng": 18.59772, "PM10": 137.0, "PM25": 121.0}, {
    "t": 1593709737000,
    "lat": 54.35108666666667,
    "lng": 18.597716666666667,
    "PM10": 133.0,
    "PM25": 117.0
  }, {
    "t": 1593709738000,
    "lat": 54.35108666666667,
    "lng": 18.597716666666667,
    "PM10": 129.0,
    "PM25": 120.0
  }, {
    "t": 1593709739000,
    "lat": 54.35108666666667,
    "lng": 18.597716666666667,
    "PM10": 132.0,
    "PM25": 119.0
  }, {
    "t": 1593709740000,
    "lat": 54.351085,
    "lng": 18.59771,
    "PM10": 130.0,
    "PM25": 117.0,
    "temp": 21.600000381469727,
    "RH": 59.400001525878906
  }, {
    "t": 1593709741000,
    "lat": 54.351085,
    "lng": 18.59771,
    "PM10": 129.0,
    "PM25": 112.0,
    "temp": 21.600000381469727,
    "RH": 59.099998474121094
  }, {"t": 1593709742000, "lat": 54.351085, "lng": 18.59771, "PM10": 124.0, "PM25": 107.0}, {
    "t": 1593709743000,
    "lat": 54.351085,
    "lng": 18.59771,
    "PM10": 116.5,
    "PM25": 104.0
  }, {"t": 1593709744000, "lat": 54.351085, "lng": 18.597705, "PM10": 112.0, "PM25": 101.0}, {
    "t": 1593709745000,
    "lat": 54.35108666666667,
    "lng": 18.59770333333333,
    "PM10": 117.0,
    "PM25": 107.0
  }, {
    "t": 1593709746000,
    "lat": 54.35108666666667,
    "lng": 18.59770333333333,
    "PM10": 117.0,
    "PM25": 107.0
  }, {
    "t": 1593709747000,
    "lat": 54.35108666666667,
    "lng": 18.59770333333333,
    "PM10": 117.0,
    "PM25": 107.0
  }, {"t": 1593709748000, "lat": 54.35108666666667, "lng": 18.59770333333333, "PM25": 111.0}, {
    "t": 1593709749000,
    "lat": 54.35108833333334,
    "lng": 18.59770333333333,
    "PM10": 119.0,
    "PM25": 111.0,
    "temp": 21.600000381469727,
    "RH": 59.20000076293945
  }, {
    "t": 1593709750000,
    "lat": 54.35108833333334,
    "lng": 18.59770333333333,
    "PM10": 119.0,
    "PM25": 111.0,
    "temp": 21.600000381469727,
    "RH": 59.20000076293945
  }, {
    "t": 1593709751000,
    "lat": 54.35108833333334,
    "lng": 18.59770333333333,
    "PM10": 118.33333333333333,
    "PM25": 111.0
  }, {"t": 1593709752000, "lat": 54.35109, "lng": 18.597705, "PM10": 121.0, "PM25": 113.0}, {
    "t": 1593709753000,
    "lat": 54.35109,
    "lng": 18.597705,
    "PM10": 122.0,
    "PM25": 115.0
  }, {"t": 1593709754000, "lat": 54.35109166666667, "lng": 18.5977, "PM10": 126.0, "PM25": 120.0}, {
    "t": 1593709755000,
    "lat": 54.35109166666667,
    "lng": 18.5977,
    "PM10": 135.0,
    "PM25": 129.0
  }, {"t": 1593709756000, "lat": 54.35109166666667, "lng": 18.5977, "PM10": 137.0, "PM25": 132.0}, {
    "t": 1593709757000,
    "lat": 54.35109166666667,
    "lng": 18.5977
  }, {
    "t": 1593709758000,
    "lat": 54.35109166666667,
    "lng": 18.597696666666668,
    "PM10": 138.0,
    "PM25": 134.0
  }, {
    "t": 1593709759000,
    "lat": 54.35109166666667,
    "lng": 18.597696666666668,
    "PM10": 143.0,
    "PM25": 138.0
  }, {
    "t": 1593709760000,
    "lat": 54.35109333333333,
    "lng": 18.597696666666668,
    "PM10": 146.5,
    "PM25": 140.5
  }, {
    "t": 1593709761000,
    "lat": 54.35109333333333,
    "lng": 18.597696666666668,
    "PM10": 147.5,
    "PM25": 143.0
  }, {"t": 1593709762000, "lat": 54.35109333333333, "lng": 18.597696666666668}, {
    "t": 1593709763000,
    "lat": 54.35109333333333,
    "lng": 18.597696666666668,
    "PM10": 146.0,
    "PM25": 142.0
  }, {
    "t": 1593709764000,
    "lat": 54.35109333333333,
    "lng": 18.597696666666668,
    "PM10": 148.0,
    "PM25": 144.0
  }, {
    "t": 1593709765000,
    "lat": 54.35109333333333,
    "lng": 18.597696666666668,
    "PM10": 154.0,
    "PM25": 150.0
  }, {
    "t": 1593709766000,
    "lat": 54.351095,
    "lng": 18.597686666666668,
    "PM10": 154.0,
    "PM25": 150.0
  }, {
    "t": 1593709767000,
    "lat": 54.351095,
    "lng": 18.597686666666668,
    "PM10": 153.0,
    "PM25": 146.0
  }, {
    "t": 1593709768000,
    "lat": 54.351095,
    "lng": 18.597686666666668,
    "PM10": 149.0,
    "PM25": 144.0
  }, {
    "t": 1593709769000,
    "lat": 54.351095,
    "lng": 18.597683333333332,
    "PM10": 148.0,
    "PM25": 144.5
  }, {
    "t": 1593709770000,
    "lat": 54.351095,
    "lng": 18.597683333333332,
    "PM10": 147.0,
    "PM25": 144.0
  }, {
    "t": 1593709771000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 149.0,
    "PM25": 145.0,
    "temp": 21.5,
    "RH": 59.400001525878906
  }, {
    "t": 1593709772000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 145.0,
    "PM25": 142.0
  }, {
    "t": 1593709773000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 138.0,
    "PM25": 133.0
  }, {
    "t": 1593709774000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 135.0,
    "PM25": 129.0,
    "temp": 21.5,
    "RH": 59.5
  }, {
    "t": 1593709775000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 133.0,
    "PM25": 127.0
  }, {
    "t": 1593709776000,
    "lat": 54.35109833333333,
    "lng": 18.597681666666666,
    "PM10": 133.0,
    "PM25": 127.0
  }, {
    "t": 1593709777000,
    "lat": 54.35109833333333,
    "lng": 18.59768,
    "PM10": 133.0,
    "PM25": 127.0
  }, {
    "t": 1593709778000,
    "lat": 54.35109833333333,
    "lng": 18.59768,
    "PM10": 140.0,
    "PM25": 134.0
  }, {
    "t": 1593709779000,
    "lat": 54.35109833333333,
    "lng": 18.59768,
    "PM10": 139.0,
    "PM25": 134.0
  }, {
    "t": 1593709780000,
    "lat": 54.35109833333333,
    "lng": 18.59768,
    "PM10": 138.0,
    "PM25": 129.0
  }, {
    "t": 1593709781000,
    "lat": 54.351095,
    "lng": 18.597676666666665,
    "PM10": 132.0,
    "PM25": 129.0
  }, {
    "t": 1593709782000,
    "lat": 54.351095,
    "lng": 18.597676666666665,
    "PM10": 137.0,
    "PM25": 130.0
  }, {
    "t": 1593709783000,
    "lat": 54.35108666666667,
    "lng": 18.597678333333334,
    "PM10": 136.0,
    "PM25": 133.0
  }, {
    "t": 1593709784000,
    "lat": 54.35108666666667,
    "lng": 18.597678333333334,
    "PM10": 140.0,
    "PM25": 139.0
  }, {
    "t": 1593709785000,
    "lat": 54.35108666666667,
    "lng": 18.597678333333334,
    "PM10": 146.0,
    "PM25": 139.0
  }, {
    "t": 1593709786000,
    "lat": 54.35108666666667,
    "lng": 18.597678333333334,
    "PM10": 145.0,
    "PM25": 137.0
  }, {"t": 1593709787000, "lat": 54.351045, "lng": 18.597765, "PM10": 139.0, "PM25": 131.0}, {
    "t": 1593709788000,
    "lat": 54.351045,
    "lng": 18.597765,
    "PM10": 139.0,
    "PM25": 129.0
  }, {"t": 1593709789000, "lat": 54.351005, "lng": 18.59808, "PM10": 134.0, "PM25": 13.0}, {
    "t": 1593709790000,
    "lat": 54.351005,
    "lng": 18.59808,
    "PM10": 133.0,
    "PM25": 123.0
  }, {"t": 1593709791000, "lat": 54.351005, "lng": 18.59808, "PM10": 134.0, "PM25": 123.0}, {
    "t": 1593709792000,
    "lat": 54.35107333333333,
    "lng": 18.598306666666666,
    "PM10": 13.5,
    "PM25": 117.0,
    "temp": 21.600000381469727,
    "RH": 59.70000076293945
  }, {
    "t": 1593709793000,
    "lat": 54.35107333333333,
    "lng": 18.598306666666666,
    "PM10": 118.0,
    "PM25": 111.0
  }, {
    "t": 1593709794000,
    "lat": 54.35107333333333,
    "lng": 18.598306666666666,
    "PM10": 110.0,
    "PM25": 104.0
  }, {
    "t": 1593709795000,
    "lat": 54.35109166666667,
    "lng": 18.59831,
    "PM10": 108.0,
    "PM25": 103.0,
    "temp": 21.5,
    "RH": 59.70000076293945
  }, {
    "t": 1593709796000,
    "lat": 54.35109166666667,
    "lng": 18.59831,
    "PM10": 111.0,
    "PM25": 106.0
  }, {
    "t": 1593709797000,
    "lat": 54.35109166666667,
    "lng": 18.59831,
    "PM10": 110.0,
    "PM25": 106.0
  }, {
    "t": 1593709798000,
    "lat": 54.351103333333334,
    "lng": 18.59822,
    "PM10": 102.0,
    "PM25": 100.0
  }, {
    "t": 1593709799000,
    "lat": 54.351103333333334,
    "lng": 18.59822,
    "PM10": 103.0,
    "PM25": 102.0
  }, {"t": 1593709800000, "lat": 54.351103333333334, "lng": 18.59822}, {
    "t": 1593709801000,
    "lat": 54.35111166666667,
    "lng": 18.59808,
    "PM10": 103.0,
    "PM25": 103.0,
    "temp": 21.5,
    "RH": 59.70000076293945
  }, {
    "t": 1593709802000,
    "lat": 54.35111166666667,
    "lng": 18.59808,
    "PM10": 115.0,
    "PM25": 114.0
  }, {
    "t": 1593709803000,
    "lat": 54.35111166666667,
    "lng": 18.59808,
    "PM10": 13.0,
    "PM25": 124.0
  }, {
    "t": 1593709804000,
    "lat": 54.35111166666667,
    "lng": 18.59808,
    "PM10": 129.0,
    "PM25": 129.0
  }, {
    "t": 1593709805000,
    "lat": 54.35111166666667,
    "lng": 18.597718333333333,
    "PM10": 129.0,
    "PM25": 131.0
  }, {
    "t": 1593709806000,
    "lat": 54.35111166666667,
    "lng": 18.597718333333333,
    "PM10": 132.0,
    "PM25": 131.0
  }, {
    "t": 1593709807000,
    "lat": 54.351108333333336,
    "lng": 18.597668333333335,
    "PM10": 128.0,
    "PM25": 128.0
  }, {
    "t": 1593709808000,
    "lat": 54.351108333333336,
    "lng": 18.597668333333335,
    "PM10": 135.0,
    "PM25": 133.0
  }, {
    "t": 1593709809000,
    "lat": 54.351108333333336,
    "lng": 18.597663333333333,
    "PM10": 151.0,
    "PM25": 149.0
  }, {
    "t": 1593709810000,
    "lat": 54.351108333333336,
    "lng": 18.597663333333333,
    "PM10": 162.0,
    "PM25": 160.0
  }, {
    "t": 1593709811000,
    "lat": 54.351108333333336,
    "lng": 18.597663333333333,
    "PM10": 173.0,
    "PM25": 171.0
  }, {
    "t": 1593709812000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 182.0,
    "PM25": 178.5
  }, {
    "t": 1593709813000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 179.0,
    "PM25": 183.0
  }, {
    "t": 1593709814000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 185.0,
    "PM25": 191.0
  }, {
    "t": 1593709815000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 197.0,
    "PM25": 199.0
  }, {
    "t": 1593709816000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 211.0,
    "PM25": 209.0
  }, {
    "t": 1593709817000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 211.0,
    "PM25": 210.0
  }, {
    "t": 1593709818000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 210.0,
    "PM25": 210.0
  }, {
    "t": 1593709819000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 210.0,
    "PM25": 210.0
  }, {
    "t": 1593709820000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 210.0,
    "PM25": 196.0
  }, {
    "t": 1593709821000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 193.0,
    "PM25": 189.0
  }, {
    "t": 1593709822000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 185.0,
    "PM25": 183.0
  }, {
    "t": 1593709823000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 182.0,
    "PM25": 179.0
  }, {
    "t": 1593709824000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 176.0,
    "PM25": 171.5
  }, {
    "t": 1593709825000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 171.0,
    "PM25": 165.0
  }, {
    "t": 1593709826000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 167.0,
    "PM25": 153.0
  }, {
    "t": 1593709827000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 155.0,
    "PM25": 151.0
  }, {
    "t": 1593709828000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 151.0,
    "PM25": 147.0
  }, {
    "t": 1593709829000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 160.0,
    "PM25": 156.0
  }, {"t": 1593709830000, "lat": 54.351105, "lng": 18.597656666666666}, {
    "t": 1593709831000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 162.5,
    "PM25": 160.0
  }, {
    "t": 1593709832000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 168.0,
    "PM25": 166.0
  }, {
    "t": 1593709833000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 166.0,
    "PM25": 164.0
  }, {
    "t": 1593709834000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 166.0,
    "PM25": 164.0
  }, {
    "t": 1593709835000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 166.0,
    "PM25": 163.0
  }, {"t": 1593709836000, "lat": 54.351105, "lng": 18.597656666666666, "PM10": 165.0}, {
    "t": 1593709837000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 155.0,
    "PM25": 153.0
  }, {
    "t": 1593709838000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 161.0,
    "PM25": 158.0
  }, {
    "t": 1593709839000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 160.0,
    "PM25": 157.0
  }, {
    "t": 1593709840000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 144.0,
    "PM25": 139.5
  }, {
    "t": 1593709841000,
    "lat": 54.351105,
    "lng": 18.597656666666666,
    "PM10": 131.0,
    "PM25": 13.0
  }, {
    "t": 1593709842000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 120.0,
    "PM25": 112.0
  }, {
    "t": 1593709843000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 111.0,
    "PM25": 103.0
  }, {
    "t": 1593709844000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 101.0,
    "PM25": 92.0
  }, {"t": 1593709845000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 95.0, "PM25": 83.0}, {
    "t": 1593709846000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 91.0,
    "PM25": 71.0
  }, {"t": 1593709847000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 81.0, "PM25": 60.0}, {
    "t": 1593709848000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 76.0,
    "PM25": 60.0
  }, {"t": 1593709849000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 76.0, "PM25": 59.0}, {
    "t": 1593709850000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 71.5,
    "PM25": 52.0
  }, {"t": 1593709851000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 66.0, "PM25": 50.0}, {
    "t": 1593709852000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 60.0,
    "PM25": 43.0
  }, {"t": 1593709853000, "lat": 54.351105, "lng": 18.59765833333333}, {
    "t": 1593709854000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 54.0,
    "PM25": 39.0
  }, {"t": 1593709855000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 50.0, "PM25": 35.0}, {
    "t": 1593709856000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 42.0,
    "PM25": 28.0
  }, {"t": 1593709857000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 36.0, "PM25": 19.0}, {
    "t": 1593709858000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 30.0,
    "PM25": 13.0
  }, {"t": 1593709859000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 31.0, "PM25": 24.0}, {
    "t": 1593709860000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 44.0,
    "PM25": 35.0
  }, {"t": 1593709861000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 50.0, "PM25": 35.0}, {
    "t": 1593709862000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 50.0,
    "PM25": 35.0
  }, {"t": 1593709863000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 49.0, "PM25": 35.0}, {
    "t": 1593709864000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 49.0,
    "PM25": 35.0
  }, {"t": 1593709865000, "lat": 54.351105, "lng": 18.59765833333333}, {
    "t": 1593709866000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 48.0,
    "PM25": 34.0
  }, {"t": 1593709867000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 48.0, "PM25": 34.0}, {
    "t": 1593709868000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 51.0,
    "PM25": 37.333333333333336
  }, {"t": 1593709869000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 54.0, "PM25": 40.0}, {
    "t": 1593709870000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 55.0,
    "PM25": 40.0
  }, {"t": 1593709871000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 55.0, "PM25": 37.0}, {
    "t": 1593709872000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 52.0,
    "PM25": 40.0
  }, {"t": 1593709873000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 56.0, "PM25": 41.0}, {
    "t": 1593709874000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 58.0,
    "PM25": 43.0
  }, {"t": 1593709875000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 59.0, "PM25": 43.0}, {
    "t": 1593709876000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 59.0,
    "PM25": 43.0
  }, {"t": 1593709877000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 59.0, "PM25": 44.0}, {
    "t": 1593709878000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 61.0,
    "PM25": 45.0
  }, {"t": 1593709879000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 63.0, "PM25": 46.0}, {
    "t": 1593709880000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 62.0,
    "PM25": 45.0
  }, {"t": 1593709881000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 61.0, "PM25": 43.0}, {
    "t": 1593709882000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 60.0,
    "PM25": 43.0
  }, {"t": 1593709883000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 60.0, "PM25": 43.0}, {
    "t": 1593709884000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 59.0,
    "PM25": 43.0
  }, {"t": 1593709885000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 60.0, "PM25": 43.0}, {
    "t": 1593709886000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 61.0,
    "PM25": 44.0
  }, {"t": 1593709887000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 60.0, "PM25": 43.0}, {
    "t": 1593709888000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 56.0,
    "PM25": 41.0
  }, {"t": 1593709889000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 56.0, "PM25": 41.0}, {
    "t": 1593709890000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 56.0,
    "PM25": 41.0
  }, {"t": 1593709891000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 60.0, "PM25": 44.0}, {
    "t": 1593709892000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 61.0,
    "PM25": 45.0
  }, {"t": 1593709893000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 61.0, "PM25": 45.0}, {
    "t": 1593709894000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 61.0,
    "PM25": 45.0
  }, {"t": 1593709895000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 61.0, "PM25": 45.0}, {
    "t": 1593709896000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 61.0,
    "PM25": 45.0
  }, {"t": 1593709897000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 61.0, "PM25": 44.5}, {
    "t": 1593709898000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 60.0,
    "PM25": 43.0
  }, {"t": 1593709899000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 60.0, "PM25": 38.0}, {
    "t": 1593709900000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 54.0,
    "PM25": 33.0
  }, {"t": 1593709901000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 49.0, "PM25": 30.0}, {
    "t": 1593709902000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 46.0,
    "PM25": 30.0
  }, {"t": 1593709903000, "lat": 54.351105, "lng": 18.59765833333333, "PM10": 46.0, "PM25": 30.0}, {
    "t": 1593709904000,
    "lat": 54.351105,
    "lng": 18.59765833333333,
    "PM10": 44.5,
    "PM25": 27.0
  }, {"t": 1593709905000, "lat": 54.3511, "lng": 18.597655, "PM10": 43.0, "PM25": 27.0}, {
    "t": 1593709906000,
    "lat": 54.3511,
    "lng": 18.597655
  }, {"t": 1593709907000, "lat": 54.351095, "lng": 18.59765, "PM10": 44.0, "PM25": 30.0}, {
    "t": 1593709908000,
    "lat": 54.351095,
    "lng": 18.59765,
    "PM10": 43.0,
    "PM25": 29.0
  }, {"t": 1593709909000, "lat": 54.351095, "lng": 18.59765, "PM10": 41.0, "PM25": 27.0}, {
    "t": 1593709910000,
    "lat": 54.351095,
    "lng": 18.59765,
    "PM10": 40.0,
    "PM25": 3.0
  }, {
    "t": 1593709911000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 38.0,
    "PM25": 23.0
  }, {
    "t": 1593709912000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 36.0,
    "PM25": 23.0
  }, {"t": 1593709913000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 36.0, "PM25": 23.0}, {
    "t": 1593709914000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 36.5,
    "PM25": 23.5
  }, {"t": 1593709915000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 33.0, "PM25": 17.0}, {
    "t": 1593709916000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 34.0,
    "PM25": 16.0
  }, {"t": 1593709917000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 33.0, "PM25": 15.0}, {
    "t": 1593709918000,
    "lat": 54.35109166666667,
    "lng": 18.597645
  }, {"t": 1593709919000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 33.0, "PM25": 15.0}, {
    "t": 1593709920000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 33.0,
    "PM25": 15.0
  }, {"t": 1593709921000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 31.0, "PM25": 16.0}, {
    "t": 1593709922000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 31.0,
    "PM25": 16.0,
    "RH": 64.0
  }, {"t": 1593709923000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 31.0, "PM25": 16.0}, {
    "t": 1593709924000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 31.0,
    "PM25": 16.0,
    "temp": 21.600000381469727,
    "RH": 64.19999694824219
  }, {"t": 1593709925000, "lat": 54.35109166666667, "lng": 18.597645, "PM10": 29.0, "PM25": 17.0}, {
    "t": 1593709926000,
    "lat": 54.35109166666667,
    "lng": 18.597645,
    "PM10": 29.0,
    "PM25": 17.0
  }, {"t": 1593709927000, "lat": 54.35109166666667, "lng": 18.597645, "PM25": 17.0}, {
    "t": 1593709928000,
    "lat": 54.35109166666667,
    "lng": 18.597648333333332,
    "PM10": 29.0,
    "PM25": 16.0,
    "temp": 21.700000762939453,
    "RH": 64.4000015258789
  }, {
    "t": 1593709929000,
    "lat": 54.35109166666667,
    "lng": 18.597648333333332,
    "PM10": 27.0,
    "PM25": 16.0,
    "temp": 21.700000762939453
  }, {"t": 1593709930000, "lat": 54.35109, "lng": 18.597648333333332, "PM10": 27.0, "PM25": 17.0}, {
    "t": 1593709931000,
    "lat": 54.35109,
    "lng": 18.597648333333332,
    "PM10": 27.0,
    "PM25": 17.0
  }, {"t": 1593709932000, "lat": 54.35109, "lng": 18.597648333333332, "PM10": 27.0, "PM25": 17.0}, {
    "t": 1593709933000,
    "lat": 54.35109,
    "lng": 18.597648333333332
  }, {"t": 1593709934000, "lat": 54.35109, "lng": 18.597641666666668, "PM10": 3.0, "PM25": 17.0}, {
    "t": 1593709935000,
    "lat": 54.35109,
    "lng": 18.597641666666668,
    "PM10": 3.0,
    "PM25": 17.0
  }, {"t": 1593709936000, "lat": 54.35109, "lng": 18.59764, "PM10": 26.5, "PM25": 18.5}, {
    "t": 1593709937000,
    "lat": 54.35109,
    "lng": 18.59764,
    "PM10": 26.0,
    "PM25": 18.0
  }, {"t": 1593709938000, "lat": 54.35109, "lng": 18.59764, "PM10": 26.0, "PM25": 20.0}, {
    "t": 1593709939000,
    "lat": 54.35109,
    "lng": 18.59764,
    "PM25": 20.0
  }, {
    "t": 1593709940000,
    "lat": 54.35109166666667,
    "lng": 18.597641666666668,
    "PM10": 26.0,
    "PM25": 20.0
  }, {
    "t": 1593709941000,
    "lat": 54.35109166666667,
    "lng": 18.597641666666668,
    "PM10": 3.5,
    "PM25": 22.0
  }, {
    "t": 1593709942000,
    "lat": 54.35109333333333,
    "lng": 18.597643333333334,
    "PM10": 3.0,
    "PM25": 22.0,
    "temp": 21.799999237060547,
    "RH": 65.5
  }, {
    "t": 1593709943000,
    "lat": 54.35109333333333,
    "lng": 18.597643333333334,
    "PM10": 24.0,
    "PM25": 23.0
  }, {
    "t": 1593709944000,
    "lat": 54.35109333333333,
    "lng": 18.597643333333334,
    "PM10": 24.0,
    "PM25": 23.0
  }, {"t": 1593709945000, "lat": 54.35109333333333, "lng": 18.597643333333334, "PM25": 23.0}, {
    "t": 1593709946000,
    "lat": 54.35109333333333,
    "lng": 18.597643333333334,
    "PM10": 24.0,
    "PM25": 22.0
  }, {
    "t": 1593709947000,
    "lat": 54.35109333333333,
    "lng": 18.597643333333334,
    "PM10": 23.0,
    "PM25": 22.0
  }, {
    "t": 1593709948000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 23.666666666666668,
    "PM25": 22.5
  }, {
    "t": 1593709949000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0
  }, {
    "t": 1593709950000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0
  }, {"t": 1593709951000, "lat": 54.35109333333333, "lng": 18.597646666666666, "PM25": 23.0}, {
    "t": 1593709952000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0,
    "temp": 21.799999237060547,
    "RH": 66.0999984741211
  }, {
    "t": 1593709953000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0
  }, {
    "t": 1593709954000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0
  }, {
    "t": 1593709955000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 3.0,
    "PM25": 23.0
  }, {
    "t": 1593709956000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 26.0,
    "PM25": 24.0
  }, {
    "t": 1593709957000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 27.0,
    "PM25": 3.0
  }, {"t": 1593709958000, "lat": 54.35109333333333, "lng": 18.597646666666666, "PM25": 3.0}, {
    "t": 1593709959000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 27.0,
    "PM25": 24.0
  }, {
    "t": 1593709960000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 26.0,
    "PM25": 24.0
  }, {
    "t": 1593709961000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 24.5,
    "PM25": 22.0
  }, {
    "t": 1593709962000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 23.0,
    "PM25": 22.0
  }, {
    "t": 1593709963000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 22.0,
    "PM25": 21.0
  }, {"t": 1593709964000, "lat": 54.35109333333333, "lng": 18.597646666666666, "PM25": 19.0}, {
    "t": 1593709965000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 20.0,
    "PM25": 19.0
  }, {
    "t": 1593709966000,
    "lat": 54.35109333333333,
    "lng": 18.597646666666666,
    "PM10": 20.0,
    "PM25": 19.0
  }, {"t": 1593709967000, "lat": 54.35109166666667, "lng": 18.59765, "PM10": 18.0, "PM25": 16.0}, {
    "t": 1593709968000,
    "lat": 54.35109166666667,
    "lng": 18.59765,
    "PM10": 17.0,
    "PM25": 16.0
  }, {"t": 1593709969000, "lat": 54.35109166666667, "lng": 18.59765, "PM10": 15.0, "PM25": 14.0}, {
    "t": 1593709970000,
    "lat": 54.35109166666667,
    "lng": 18.59765,
    "PM25": 14.0
  }, {"t": 1593709971000, "lat": 54.35109, "lng": 18.59765833333333, "PM10": 15.0, "PM25": 14.0}, {
    "t": 1593709972000,
    "lat": 54.35109,
    "lng": 18.59765833333333,
    "PM10": 15.0,
    "PM25": 13.0
  }, {"t": 1593709973000, "lat": 54.35109, "lng": 18.59765833333333, "PM10": 13.0, "PM25": 13.0}, {
    "t": 1593709974000,
    "lat": 54.35109,
    "lng": 18.597666666666665,
    "PM10": 13.0,
    "PM25": 13.0,
    "temp": 21.899999618530273,
    "RH": 68.5999984741211
  }, {"t": 1593709975000, "lat": 54.35109, "lng": 18.597666666666665, "PM10": 11.0, "PM25": 11.0}, {
    "t": 1593709976000,
    "lat": 54.35109,
    "lng": 18.597666666666665,
    "PM10": 12.0,
    "PM25": 12.0
  }, {
    "t": 1593709977000,
    "lat": 54.35108833333334,
    "lng": 18.59767,
    "PM10": 12.0,
    "PM25": 11.5,
    "temp": 21.899999618530273,
    "RH": 69.0
  }, {"t": 1593709978000, "lat": 54.35108833333334, "lng": 18.59767, "PM10": 11.0, "PM25": 10.0}, {
    "t": 1593709979000,
    "lat": 54.35108833333334,
    "lng": 18.59767,
    "PM10": 9.333333333333334,
    "PM25": 9.0
  }, {"t": 1593709980000, "lat": 54.35108666666667, "lng": 18.59767, "PM10": 8.0, "PM25": 8.0}, {
    "t": 1593709981000,
    "lat": 54.35108666666667,
    "lng": 18.59767,
    "PM10": 8.0,
    "PM25": 8.0
  }, {"t": 1593709982000, "lat": 54.35108666666667, "lng": 18.59767, "PM25": 6.0}, {
    "t": 1593709983000,
    "lat": 54.351085,
    "lng": 18.597673333333333,
    "PM10": 6.0,
    "PM25": 6.0,
    "temp": 21.899999618530273,
    "RH": 66.4000015258789
  }, {
    "t": 1593709984000,
    "lat": 54.351085,
    "lng": 18.597673333333333,
    "PM10": 6.0,
    "PM25": 6.0,
    "temp": 22.0,
    "RH": 67.19999694824219
  }, {"t": 1593709985000, "lat": 54.351085, "lng": 18.597673333333333, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593709986000,
    "lat": 54.351083333333335,
    "lng": 18.597675,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.0,
    "RH": 68.30000305175781
  }, {"t": 1593709987000, "lat": 54.351083333333335, "lng": 18.597675, "PM10": 5.0, "PM25": 3.0}, {
    "t": 1593709988000,
    "lat": 54.351083333333335,
    "lng": 18.597675,
    "PM25": 3.0
  }, {
    "t": 1593709989000,
    "lat": 54.351083333333335,
    "lng": 18.597675,
    "PM10": 5.0,
    "PM25": 3.0,
    "temp": 22.0,
    "RH": 68.69999694824219
  }, {"t": 1593709990000, "lat": 54.351083333333335, "lng": 18.597675, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593709991000,
    "lat": 54.351083333333335,
    "lng": 18.597675,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593709992000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 5.666666666666667,
    "PM25": 4.0
  }, {
    "t": 1593709993000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593709994000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593709995000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 7.0,
    "PM25": 5.0,
    "temp": 22.0,
    "RH": 70.0
  }, {
    "t": 1593709996000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 7.0,
    "PM25": 5.0,
    "temp": 22.0,
    "RH": 67.30000305175781
  }, {
    "t": 1593709997000,
    "lat": 54.351081666666666,
    "lng": 18.597676666666665,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593709998000, "lat": 54.351081666666666, "lng": 18.597676666666665, "PM25": 5.0}, {
    "t": 1593709999000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710000000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710001000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710002000,
    "lat": 54.351076666666664,
    "lng": 18.597671666666667,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710003000,
    "lat": 54.351076666666664,
    "lng": 18.597671666666667,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593710004000, "lat": 54.351076666666664, "lng": 18.597671666666667, "PM25": 5.0}, {
    "t": 1593710005000,
    "lat": 54.351076666666664,
    "lng": 18.59767,
    "PM10": 7.5,
    "PM25": 4.0
  }, {"t": 1593710006000, "lat": 54.351076666666664, "lng": 18.59767, "PM10": 7.0, "PM25": 4.0}, {
    "t": 1593710007000,
    "lat": 54.351075,
    "lng": 18.59767,
    "PM10": 7.0,
    "PM25": 4.0,
    "temp": 22.100000381469727,
    "RH": 66.80000305175781
  }, {"t": 1593710008000, "lat": 54.351075, "lng": 18.59767, "PM10": 8.0, "PM25": 6.0}, {
    "t": 1593710009000,
    "lat": 54.351075,
    "lng": 18.59767,
    "PM10": 8.0,
    "PM25": 6.0
  }, {"t": 1593710010000, "lat": 54.351075, "lng": 18.59767, "PM25": 6.0}, {
    "t": 1593710011000,
    "lat": 54.35107166666667,
    "lng": 18.59767,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593710012000, "lat": 54.35107166666667, "lng": 18.59767, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593710013000,
    "lat": 54.35107166666667,
    "lng": 18.59767,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710014000,
    "lat": 54.35106833333333,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710015000,
    "lat": 54.35106833333333,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710016000, "lat": 54.35106833333333, "lng": 18.597673333333333, "PM25": 5.0}, {
    "t": 1593710017000,
    "lat": 54.35107,
    "lng": 18.597668333333335,
    "PM10": 7.0,
    "PM25": 6.0,
    "temp": 22.100000381469727,
    "RH": 65.0
  }, {"t": 1593710018000, "lat": 54.35107, "lng": 18.597668333333335, "PM10": 8.0, "PM25": 6.5}, {
    "t": 1593710019000,
    "lat": 54.35107,
    "lng": 18.597668333333335,
    "PM10": 8.0,
    "PM25": 7.0,
    "temp": 22.100000381469727,
    "RH": 65.19999694824219
  }, {"t": 1593710020000, "lat": 54.35107, "lng": 18.597665, "PM10": 8.0, "PM25": 7.0}, {
    "t": 1593710021000,
    "lat": 54.35107,
    "lng": 18.597665,
    "PM10": 7.0,
    "PM25": 7.0
  }, {"t": 1593710022000, "lat": 54.35107, "lng": 18.597665, "PM25": 7.0}, {
    "t": 1593710023000,
    "lat": 54.35107,
    "lng": 18.597665,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710024000, "lat": 54.35107, "lng": 18.597665, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710025000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.100000381469727,
    "RH": 64.5
  }, {
    "t": 1593710026000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.200000762939453,
    "RH": 64.19999694824219
  }, {
    "t": 1593710027000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.200000762939453,
    "RH": 64.0999984741211
  }, {"t": 1593710028000, "lat": 54.35107, "lng": 18.597666666666665, "PM25": 5.0}, {
    "t": 1593710029000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710030000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710031000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 6.666666666666667,
    "PM25": 5.0
  }, {"t": 1593710032000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593710033000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593710034000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593710035000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM25": 5.0
  }, {"t": 1593710036000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593710037000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 7.0,
    "PM25": 4.5
  }, {"t": 1593710038000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593710039000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593710040000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593710041000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM25": 4.0
  }, {"t": 1593710042000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593710043000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593710044000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 4.0}, {
    "t": 1593710045000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 7.0,
    "PM25": 4.0
  }, {"t": 1593710046000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 4.0}, {
    "t": 1593710047000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 7.0,
    "PM25": 4.0
  }, {"t": 1593710048000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 8.0, "PM25": 4.0}, {
    "t": 1593710049000,
    "lat": 54.35107,
    "lng": 18.597666666666665,
    "PM10": 8.0,
    "PM25": 4.0
  }, {"t": 1593710050000, "lat": 54.35107, "lng": 18.597666666666665, "PM10": 7.0, "PM25": 5.0}, {
    "t": 1593710051000,
    "lat": 54.35107166666667,
    "lng": 18.597668333333335,
    "PM10": 7.0,
    "PM25": 5.0,
    "temp": 22.399999618530273,
    "RH": 62.900001525878906
  }, {
    "t": 1593710052000,
    "lat": 54.35107166666667,
    "lng": 18.597668333333335,
    "PM10": 7.0,
    "PM25": 6.0,
    "temp": 22.399999618530273,
    "RH": 63.0
  }, {
    "t": 1593710053000,
    "lat": 54.35107166666667,
    "lng": 18.597668333333335,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 22.399999618530273,
    "RH": 63.29999923706055
  }, {
    "t": 1593710054000,
    "lat": 54.35107166666667,
    "lng": 18.597668333333335,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710055000,
    "lat": 54.35107166666667,
    "lng": 18.597668333333335,
    "PM10": 8.5,
    "PM25": 6.0,
    "temp": 22.399999618530273,
    "RH": 63.70000076293945
  }, {"t": 1593710056000, "lat": 54.35107166666667, "lng": 18.597675, "PM10": 10.0, "PM25": 7.0}, {
    "t": 1593710057000,
    "lat": 54.35107166666667,
    "lng": 18.597675,
    "PM25": 9.0
  }, {
    "t": 1593710058000,
    "lat": 54.35107333333333,
    "lng": 18.597676666666665,
    "PM10": 10.0,
    "PM25": 9.0
  }, {
    "t": 1593710059000,
    "lat": 54.35107333333333,
    "lng": 18.597676666666665,
    "PM10": 10.0,
    "PM25": 9.0
  }, {
    "t": 1593710060000,
    "lat": 54.35107333333333,
    "lng": 18.597676666666665,
    "PM10": 10.0,
    "PM25": 11.0
  }, {"t": 1593710061000, "lat": 54.35107333333333, "lng": 18.597675, "PM10": 12.0, "PM25": 11.0}, {
    "t": 1593710062000,
    "lat": 54.35107333333333,
    "lng": 18.597675,
    "PM10": 12.0,
    "PM25": 10.5
  }, {"t": 1593710063000, "lat": 54.35107333333333, "lng": 18.597675, "PM25": 11.0}, {
    "t": 1593710064000,
    "lat": 54.351075,
    "lng": 18.597676666666665,
    "PM10": 13.0,
    "PM25": 11.0
  }, {
    "t": 1593710065000,
    "lat": 54.351075,
    "lng": 18.597676666666665,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710066000,
    "lat": 54.351075,
    "lng": 18.597676666666665,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710067000,
    "lat": 54.351075,
    "lng": 18.597676666666665,
    "PM10": 13.0,
    "PM25": 12.0
  }, {"t": 1593710068000, "lat": 54.35108, "lng": 18.59768, "PM10": 13.0, "PM25": 12.0}, {
    "t": 1593710069000,
    "lat": 54.35108,
    "lng": 18.59768,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710070000,
    "lat": 54.351081666666666,
    "lng": 18.597686666666668,
    "PM10": 13.0,
    "PM25": 13.0,
    "temp": 22.5,
    "RH": 65.5
  }, {
    "t": 1593710071000,
    "lat": 54.351081666666666,
    "lng": 18.597686666666668,
    "PM10": 14.0,
    "PM25": 13.0
  }, {
    "t": 1593710072000,
    "lat": 54.351081666666666,
    "lng": 18.597686666666668,
    "PM10": 14.0,
    "PM25": 13.0
  }, {
    "t": 1593710073000,
    "lat": 54.351081666666666,
    "lng": 18.597681666666666,
    "PM10": 13.5,
    "PM25": 13.0
  }, {
    "t": 1593710074000,
    "lat": 54.351081666666666,
    "lng": 18.597681666666666,
    "PM10": 13.0,
    "PM25": 13.0
  }, {
    "t": 1593710075000,
    "lat": 54.351081666666666,
    "lng": 18.597681666666666,
    "PM10": 13.0,
    "PM25": 13.0
  }, {"t": 1593710076000, "lat": 54.351081666666666, "lng": 18.597681666666666, "PM25": 14.0}, {
    "t": 1593710077000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 14.0,
    "PM25": 14.0
  }, {
    "t": 1593710078000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 14.0,
    "PM25": 13.0
  }, {
    "t": 1593710079000,
    "lat": 54.351078333333334,
    "lng": 18.597676666666665,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710080000,
    "lat": 54.351078333333334,
    "lng": 18.597671666666667,
    "PM10": 12.0,
    "PM25": 12.0,
    "temp": 22.600000381469727,
    "RH": 63.599998474121094
  }, {
    "t": 1593710081000,
    "lat": 54.351078333333334,
    "lng": 18.597671666666667,
    "PM10": 11.0,
    "PM25": 11.0,
    "temp": 22.600000381469727,
    "RH": 64.19999694824219
  }, {
    "t": 1593710082000,
    "lat": 54.351078333333334,
    "lng": 18.597671666666667,
    "PM10": 11.0,
    "PM25": 11.0,
    "temp": 22.600000381469727,
    "RH": 64.30000305175781
  }, {
    "t": 1593710083000,
    "lat": 54.351075,
    "lng": 18.597666666666665,
    "PM10": 11.0,
    "PM25": 10.0
  }, {
    "t": 1593710084000,
    "lat": 54.351075,
    "lng": 18.597666666666665,
    "PM10": 10.0,
    "PM25": 10.0
  }, {
    "t": 1593710085000,
    "lat": 54.351075,
    "lng": 18.597666666666665,
    "PM10": 10.0,
    "PM25": 10.0
  }, {"t": 1593710086000, "lat": 54.351075, "lng": 18.597666666666665, "PM10": 8.5, "PM25": 7.0}, {
    "t": 1593710087000,
    "lat": 54.351055,
    "lng": 18.597653333333334,
    "PM10": 7.0,
    "PM25": 7.0
  }, {"t": 1593710088000, "lat": 54.351055, "lng": 18.597653333333334, "PM10": 7.0, "PM25": 7.0}, {
    "t": 1593710089000,
    "lat": 54.351055,
    "lng": 18.597653333333334
  }, {
    "t": 1593710090000,
    "lat": 54.35105,
    "lng": 18.597655,
    "PM10": 7.0,
    "PM25": 7.0,
    "temp": 22.700000762939453,
    "RH": 65.5999984741211
  }, {"t": 1593710091000, "lat": 54.35105, "lng": 18.597655, "PM10": 7.0, "PM25": 7.0}, {
    "t": 1593710092000,
    "lat": 54.35105,
    "lng": 18.597655,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710093000,
    "lat": 54.35105,
    "lng": 18.597653333333334,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.700000762939453,
    "RH": 65.80000305175781
  }, {
    "t": 1593710094000,
    "lat": 54.35105,
    "lng": 18.597653333333334,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.700000762939453,
    "RH": 65.5
  }, {
    "t": 1593710095000,
    "lat": 54.35105,
    "lng": 18.597653333333334,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.700000762939453,
    "RH": 65.19999694824219
  }, {"t": 1593710096000, "lat": 54.35104833333333, "lng": 18.59766, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710097000,
    "lat": 54.35104833333333,
    "lng": 18.59766,
    "PM10": 4.0,
    "PM25": 5.0
  }, {"t": 1593710098000, "lat": 54.35104833333333, "lng": 18.59766, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710099000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 4.5,
    "PM25": 4.0,
    "temp": 22.700000762939453,
    "RH": 64.30000305175781
  }, {
    "t": 1593710100000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710101000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710102000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 22.700000762939453,
    "RH": 64.30000305175781
  }, {
    "t": 1593710103000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710104000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710105000,
    "lat": 54.35104833333333,
    "lng": 18.597663333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 22.799999237060547,
    "RH": 63.900001525878906
  }, {
    "t": 1593710106000,
    "lat": 54.35105166666666,
    "lng": 18.597665,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 22.799999237060547,
    "RH": 64.0
  }, {
    "t": 1593710107000,
    "lat": 54.35105166666666,
    "lng": 18.597665,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 22.799999237060547,
    "RH": 64.0
  }, {"t": 1593710108000, "lat": 54.35105166666666, "lng": 18.597665, "PM10": 3.0, "PM25": 3.0}, {
    "t": 1593710109000,
    "lat": 54.35105166666666,
    "lng": 18.597666666666665,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710110000,
    "lat": 54.35105166666666,
    "lng": 18.597666666666665,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710111000,
    "lat": 54.35105166666666,
    "lng": 18.597666666666665,
    "PM10": 3.0,
    "PM25": 3.0
  }, {"t": 1593710112000, "lat": 54.35105166666666, "lng": 18.597666666666665, "PM25": 4.0}, {
    "t": 1593710113000,
    "lat": 54.35105166666666,
    "lng": 18.597666666666665,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710114000,
    "lat": 54.35105166666666,
    "lng": 18.597666666666665,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710115000,
    "lat": 54.35105,
    "lng": 18.597671666666667,
    "PM10": 4.5,
    "PM25": 5.0,
    "temp": 22.899999618530273,
    "RH": 61.79999923706055
  }, {"t": 1593710116000, "lat": 54.35105, "lng": 18.597671666666667, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710117000,
    "lat": 54.35105,
    "lng": 18.597671666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710118000, "lat": 54.35105, "lng": 18.597671666666667, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710119000,
    "lat": 54.35105166666666,
    "lng": 18.597668333333335,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710120000,
    "lat": 54.35105166666666,
    "lng": 18.597668333333335,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710121000, "lat": 54.35105166666666, "lng": 18.597668333333335, "PM25": 4.0}, {
    "t": 1593710122000,
    "lat": 54.35105333333333,
    "lng": 18.597668333333335,
    "PM10": 4.0,
    "PM25": 5.0,
    "temp": 23.0,
    "RH": 62.400001525878906
  }, {
    "t": 1593710123000,
    "lat": 54.35105333333333,
    "lng": 18.597668333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710124000,
    "lat": 54.35105333333333,
    "lng": 18.597668333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710125000, "lat": 54.35105333333333, "lng": 18.597668333333335, "PM10": 5.0}, {
    "t": 1593710126000,
    "lat": 54.351055,
    "lng": 18.597673333333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710127000, "lat": 54.351055, "lng": 18.597673333333333, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710128000,
    "lat": 54.351055,
    "lng": 18.597673333333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710129000, "lat": 54.351055, "lng": 18.597673333333333, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710130000,
    "lat": 54.351055,
    "lng": 18.597673333333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710131000, "lat": 54.351055, "lng": 18.597673333333333, "PM10": 5.0}, {
    "t": 1593710132000,
    "lat": 54.351055,
    "lng": 18.597676666666665,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 23.0,
    "RH": 61.20000076293945
  }, {"t": 1593710133000, "lat": 54.351055, "lng": 18.597676666666665, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710134000,
    "lat": 54.351055,
    "lng": 18.597676666666665,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710135000, "lat": 54.351056666666665, "lng": 18.597685, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710136000,
    "lat": 54.351056666666665,
    "lng": 18.597685,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710137000, "lat": 54.351056666666665, "lng": 18.597685, "PM25": 5.0}, {
    "t": 1593710138000,
    "lat": 54.351056666666665,
    "lng": 18.597693333333332,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710139000,
    "lat": 54.351056666666665,
    "lng": 18.597693333333332,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710140000,
    "lat": 54.351056666666665,
    "lng": 18.597693333333332,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710141000,
    "lat": 54.351055,
    "lng": 18.597696666666668,
    "PM10": 4.0,
    "PM25": 4.0,
    "RH": 60.0
  }, {"t": 1593710142000, "lat": 54.351055, "lng": 18.597696666666668, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710143000,
    "lat": 54.351055,
    "lng": 18.597696666666668,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710144000, "lat": 54.351055, "lng": 18.597696666666668, "PM25": 4.0}, {
    "t": 1593710145000,
    "lat": 54.351055,
    "lng": 18.597696666666668,
    "PM10": 4.5,
    "PM25": 5.0,
    "temp": 23.100000381469727
  }, {"t": 1593710146000, "lat": 54.351055, "lng": 18.597696666666668, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710147000,
    "lat": 54.351055,
    "lng": 18.597696666666668,
    "PM10": 5.0
  }, {"t": 1593710148000, "lat": 54.351055, "lng": 18.597691666666666, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710149000,
    "lat": 54.351055,
    "lng": 18.597691666666666,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710150000, "lat": 54.351055, "lng": 18.597691666666666, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710151000,
    "lat": 54.35105166666666,
    "lng": 18.597693333333332,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710152000,
    "lat": 54.35105166666666,
    "lng": 18.597693333333332,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710153000,
    "lat": 54.35105166666666,
    "lng": 18.597693333333332,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710154000,
    "lat": 54.35104833333333,
    "lng": 18.597696666666668,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710155000,
    "lat": 54.35104833333333,
    "lng": 18.597696666666668,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710156000,
    "lat": 54.35104833333333,
    "lng": 18.597696666666668,
    "PM10": 3.0,
    "PM25": 3.0
  }, {"t": 1593710157000, "lat": 54.35104833333333, "lng": 18.597696666666668, "PM25": 3.0}, {
    "t": 1593710158000,
    "lat": 54.351045,
    "lng": 18.59770333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.200000762939453,
    "RH": 59.0
  }, {"t": 1593710159000, "lat": 54.351045, "lng": 18.59770333333333, "PM10": 3.0, "PM25": 3.0}, {
    "t": 1593710160000,
    "lat": 54.351045,
    "lng": 18.59770333333333,
    "PM10": 3.0,
    "PM25": 3.0
  }, {"t": 1593710161000, "lat": 54.351045, "lng": 18.59770333333333, "PM10": 3.0}, {
    "t": 1593710162000,
    "lat": 54.35104166666667,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710163000,
    "lat": 54.35104166666667,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710164000,
    "lat": 54.35104166666667,
    "lng": 18.597706666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710165000,
    "lat": 54.35104,
    "lng": 18.597713333333335,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 23.299999237060547,
    "RH": 58.70000076293945
  }, {"t": 1593710166000, "lat": 54.35104, "lng": 18.597713333333335, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710167000,
    "lat": 54.35104,
    "lng": 18.597713333333335,
    "PM25": 4.0
  }, {
    "t": 1593710168000,
    "lat": 54.35104,
    "lng": 18.597708333333333,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 23.299999237060547,
    "RH": 58.5
  }, {"t": 1593710169000, "lat": 54.35104, "lng": 18.597708333333333, "PM10": 4.0, "PM25": 3.0}, {
    "t": 1593710170000,
    "lat": 54.35104,
    "lng": 18.597708333333333,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710171000,
    "lat": 54.35104,
    "lng": 18.597708333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.200000762939453,
    "RH": 58.0
  }, {
    "t": 1593710172000,
    "lat": 54.351038333333335,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.299999237060547,
    "RH": 58.0
  }, {
    "t": 1593710173000,
    "lat": 54.351038333333335,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 3.0
  }, {"t": 1593710174000, "lat": 54.351038333333335, "lng": 18.597706666666667, "PM25": 3.0}, {
    "t": 1593710175000,
    "lat": 54.351038333333335,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 4.0,
    "temp": 23.299999237060547,
    "RH": 57.79999923706055
  }, {
    "t": 1593710176000,
    "lat": 54.351038333333335,
    "lng": 18.597706666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710177000,
    "lat": 54.351036666666666,
    "lng": 18.597706666666667,
    "PM10": 3.6666666666666665,
    "PM25": 3.5,
    "temp": 23.299999237060547,
    "RH": 57.5
  }, {
    "t": 1593710178000,
    "lat": 54.351036666666666,
    "lng": 18.597706666666667,
    "PM10": 3.0,
    "PM25": 3.0
  }, {
    "t": 1593710179000,
    "lat": 54.351036666666666,
    "lng": 18.597706666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710180000,
    "lat": 54.351036666666666,
    "lng": 18.597706666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710181000,
    "lat": 54.351036666666666,
    "lng": 18.597706666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710182000, "lat": 54.351035, "lng": 18.597708333333333, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710183000,
    "lat": 54.351035,
    "lng": 18.597708333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710184000, "lat": 54.351035, "lng": 18.597708333333333, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710185000,
    "lat": 54.351035,
    "lng": 18.59771,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710186000, "lat": 54.351035, "lng": 18.59771, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710187000,
    "lat": 54.351035,
    "lng": 18.59771,
    "PM10": 3.0,
    "PM25": 3.0
  }, {"t": 1593710188000, "lat": 54.351035, "lng": 18.59771, "PM25": 3.0}, {
    "t": 1593710189000,
    "lat": 54.351035,
    "lng": 18.597708333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.399999618530273,
    "RH": 57.099998474121094
  }, {
    "t": 1593710190000,
    "lat": 54.351035,
    "lng": 18.597708333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.399999618530273,
    "RH": 57.0
  }, {
    "t": 1593710191000,
    "lat": 54.351035,
    "lng": 18.597708333333333,
    "PM10": 3.0,
    "PM25": 3.0,
    "temp": 23.399999618530273,
    "RH": 57.0
  }, {"t": 1593710192000, "lat": 54.35103333333333, "lng": 18.59771, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710193000,
    "lat": 54.35103333333333,
    "lng": 18.59771,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710194000, "lat": 54.35103333333333, "lng": 18.59771, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710195000,
    "lat": 54.35103333333333,
    "lng": 18.597711666666665,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710196000,
    "lat": 54.35103333333333,
    "lng": 18.597711666666665,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710197000, "lat": 54.35103333333333, "lng": 18.597711666666665, "PM25": 4.0}, {
    "t": 1593710198000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 23.5,
    "RH": 58.0
  }, {
    "t": 1593710199000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710200000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710201000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710202000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 5.5,
    "PM25": 5.0
  }, {
    "t": 1593710203000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710204000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710205000,
    "lat": 54.35103333333333,
    "lng": 18.597713333333335,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710206000, "lat": 54.351035, "lng": 18.597708333333333, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710207000,
    "lat": 54.351035,
    "lng": 18.597708333333333,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710208000, "lat": 54.351035, "lng": 18.597708333333333, "PM25": 5.0}, {
    "t": 1593710209000,
    "lat": 54.351035,
    "lng": 18.597701666666666,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710210000, "lat": 54.351035, "lng": 18.597701666666666, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710211000,
    "lat": 54.351031666666664,
    "lng": 18.597693333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710212000,
    "lat": 54.351031666666664,
    "lng": 18.597693333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710213000,
    "lat": 54.351031666666664,
    "lng": 18.597693333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710214000,
    "lat": 54.351031666666664,
    "lng": 18.597693333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710215000,
    "lat": 54.351031666666664,
    "lng": 18.597685,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 23.5,
    "RH": 56.20000076293945
  }, {"t": 1593710216000, "lat": 54.351031666666664, "lng": 18.597685, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710217000,
    "lat": 54.351031666666664,
    "lng": 18.597685,
    "PM25": 6.0
  }, {
    "t": 1593710218000,
    "lat": 54.35103333333333,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 6.0,
    "temp": 23.600000381469727,
    "RH": 56.400001525878906
  }, {
    "t": 1593710219000,
    "lat": 54.35103333333333,
    "lng": 18.597673333333333,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710220000,
    "lat": 54.35103333333333,
    "lng": 18.597673333333333,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710221000,
    "lat": 54.35103333333333,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593710222000,
    "lat": 54.35103333333333,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 23.600000381469727,
    "RH": 56.75
  }, {
    "t": 1593710223000,
    "lat": 54.35103333333333,
    "lng": 18.597666666666665,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710224000, "lat": 54.35103333333333, "lng": 18.597666666666665, "PM25": 6.0}, {
    "t": 1593710225000,
    "lat": 54.351035,
    "lng": 18.59765833333333,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710226000, "lat": 54.351035, "lng": 18.59765833333333, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710227000,
    "lat": 54.351035,
    "lng": 18.59765833333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710228000,
    "lat": 54.351036666666666,
    "lng": 18.597651666666668,
    "PM10": 5.0,
    "PM25": 6.0
  }, {
    "t": 1593710229000,
    "lat": 54.351036666666666,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 7.0
  }, {
    "t": 1593710230000,
    "lat": 54.351036666666666,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 7.0
  }, {
    "t": 1593710231000,
    "lat": 54.351036666666666,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710232000, "lat": 54.35104166666667, "lng": 18.597645, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710233000,
    "lat": 54.35104166666667,
    "lng": 18.597645,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710234000, "lat": 54.35104166666667, "lng": 18.597645, "PM10": 5.5, "PM25": 5.0}, {
    "t": 1593710235000,
    "lat": 54.35104666666667,
    "lng": 18.597645,
    "PM10": 6.0,
    "PM25": 6.0,
    "temp": 23.700000762939453,
    "RH": 56.79999923706055
  }, {"t": 1593710236000, "lat": 54.35104666666667, "lng": 18.597645, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710237000,
    "lat": 54.35104666666667,
    "lng": 18.597645,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710238000,
    "lat": 54.35104833333333,
    "lng": 18.597633333333334,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 23.700000762939453,
    "RH": 57.099998474121094
  }, {
    "t": 1593710239000,
    "lat": 54.35104833333333,
    "lng": 18.597633333333334,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710240000,
    "lat": 54.35104833333333,
    "lng": 18.597633333333334,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710241000,
    "lat": 54.35104833333333,
    "lng": 18.597633333333334,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710242000,
    "lat": 54.35105,
    "lng": 18.59763166666667,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 23.700000762939453,
    "RH": 57.5
  }, {"t": 1593710243000, "lat": 54.35105, "lng": 18.59763166666667, "PM10": 8.0, "PM25": 6.0}, {
    "t": 1593710244000,
    "lat": 54.35105,
    "lng": 18.59763166666667,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710245000,
    "lat": 54.35105,
    "lng": 18.59763166666667,
    "PM10": 7.0,
    "PM25": 6.0,
    "RH": 57.0
  }, {"t": 1593710246000, "lat": 54.35105, "lng": 18.597633333333334, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710247000,
    "lat": 54.35105,
    "lng": 18.597633333333334,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710248000,
    "lat": 54.35104833333333,
    "lng": 18.597645,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 23.700000762939453,
    "RH": 57.099998474121094
  }, {
    "t": 1593710249000,
    "lat": 54.35104833333333,
    "lng": 18.597645,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 23.700000762939453,
    "RH": 57.099998474121094
  }, {
    "t": 1593710250000,
    "lat": 54.35104833333333,
    "lng": 18.597645,
    "PM10": 8.0,
    "PM25": 6.0,
    "temp": 23.700000762939453
  }, {"t": 1593710251000, "lat": 54.35104833333333, "lng": 18.597645, "PM10": 8.0, "PM25": 6.0}, {
    "t": 1593710252000,
    "lat": 54.351055,
    "lng": 18.597651666666668,
    "PM10": 8.0,
    "PM25": 6.0
  }, {"t": 1593710253000, "lat": 54.351055, "lng": 18.597651666666668, "PM10": 10.0, "PM25": 6.0}, {
    "t": 1593710254000,
    "lat": 54.351055,
    "lng": 18.597651666666668,
    "PM10": 10.0,
    "PM25": 5.0
  }, {
    "t": 1593710255000,
    "lat": 54.35105333333333,
    "lng": 18.597656666666666,
    "PM10": 9.0,
    "PM25": 5.0
  }, {
    "t": 1593710256000,
    "lat": 54.35105333333333,
    "lng": 18.597656666666666,
    "PM10": 9.0,
    "PM25": 5.0
  }, {
    "t": 1593710257000,
    "lat": 54.35105333333333,
    "lng": 18.597656666666666,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710258000,
    "lat": 54.35104833333333,
    "lng": 18.597666666666665,
    "PM10": 10.0,
    "PM25": 6.0
  }, {
    "t": 1593710259000,
    "lat": 54.35104833333333,
    "lng": 18.597666666666665,
    "PM10": 10.0,
    "PM25": 6.0
  }, {
    "t": 1593710260000,
    "lat": 54.35104833333333,
    "lng": 18.597666666666665,
    "PM10": 10.0,
    "PM25": 6.0
  }, {"t": 1593710261000, "lat": 54.35105, "lng": 18.597671666666667, "PM10": 10.0, "PM25": 6.0}, {
    "t": 1593710262000,
    "lat": 54.35105,
    "lng": 18.597671666666667,
    "PM10": 9.0,
    "PM25": 6.0
  }, {"t": 1593710263000, "lat": 54.35105, "lng": 18.597671666666667, "PM10": 9.0, "PM25": 6.0}, {
    "t": 1593710264000,
    "lat": 54.35105,
    "lng": 18.597671666666667,
    "PM25": 6.0
  }, {
    "t": 1593710265000,
    "lat": 54.351058333333334,
    "lng": 18.597671666666667,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710266000,
    "lat": 54.351058333333334,
    "lng": 18.597671666666667,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710267000,
    "lat": 54.351058333333334,
    "lng": 18.597671666666667,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710268000,
    "lat": 54.351056666666665,
    "lng": 18.597668333333335,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710269000,
    "lat": 54.351056666666665,
    "lng": 18.597668333333335,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710270000,
    "lat": 54.351056666666665,
    "lng": 18.597668333333335,
    "PM10": 9.0,
    "PM25": 7.0
  }, {
    "t": 1593710271000,
    "lat": 54.351056666666665,
    "lng": 18.597668333333335,
    "PM10": 9.0,
    "PM25": 7.0
  }, {
    "t": 1593710272000,
    "lat": 54.351058333333334,
    "lng": 18.597661666666667,
    "PM10": 8.666666666666666,
    "PM25": 6.5
  }, {
    "t": 1593710273000,
    "lat": 54.351058333333334,
    "lng": 18.597661666666667,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593710274000, "lat": 54.351058333333334, "lng": 18.597661666666667, "PM25": 5.0}, {
    "t": 1593710275000,
    "lat": 54.351056666666665,
    "lng": 18.59765,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593710276000, "lat": 54.351056666666665, "lng": 18.59765, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710277000,
    "lat": 54.351056666666665,
    "lng": 18.59765,
    "PM10": 8.0,
    "PM25": 6.0
  }, {"t": 1593710278000, "lat": 54.351056666666665, "lng": 18.59765, "PM10": 8.0, "PM25": 6.0}, {
    "t": 1593710279000,
    "lat": 54.35105333333333,
    "lng": 18.597655,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710280000, "lat": 54.35105333333333, "lng": 18.597655, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710281000,
    "lat": 54.35105,
    "lng": 18.59765833333333,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710282000, "lat": 54.35105, "lng": 18.59765833333333, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710283000,
    "lat": 54.35105,
    "lng": 18.59765833333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710284000, "lat": 54.35105, "lng": 18.59765833333333, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710285000,
    "lat": 54.35105,
    "lng": 18.597651666666668,
    "PM10": 5.0,
    "PM25": 5.5
  }, {"t": 1593710286000, "lat": 54.35105, "lng": 18.597651666666668, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710287000,
    "lat": 54.35105,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710288000, "lat": 54.35105, "lng": 18.597651666666668, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710289000,
    "lat": 54.35105,
    "lng": 18.597651666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710290000, "lat": 54.35104833333333, "lng": 18.59765, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710291000,
    "lat": 54.35104833333333,
    "lng": 18.59765,
    "PM10": 5.0,
    "PM25": 6.0
  }, {"t": 1593710292000, "lat": 54.35104833333333, "lng": 18.59765, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710293000,
    "lat": 54.35104666666667,
    "lng": 18.597655,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710294000, "lat": 54.35104666666667, "lng": 18.597655, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710295000,
    "lat": 54.35104666666667,
    "lng": 18.597655,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710296000, "lat": 54.35104666666667, "lng": 18.597655, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710297000,
    "lat": 54.35104333333334,
    "lng": 18.59766,
    "PM10": 7.0,
    "PM25": 5.0
  }, {"t": 1593710298000, "lat": 54.35104333333334, "lng": 18.59766, "PM10": 7.0, "PM25": 5.5}, {
    "t": 1593710299000,
    "lat": 54.35104333333334,
    "lng": 18.59766,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710300000, "lat": 54.351038333333335, "lng": 18.597665, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710301000,
    "lat": 54.351038333333335,
    "lng": 18.597665,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710302000, "lat": 54.351038333333335, "lng": 18.597665, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710303000,
    "lat": 54.351038333333335,
    "lng": 18.597665,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710304000,
    "lat": 54.351031666666664,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710305000,
    "lat": 54.351031666666664,
    "lng": 18.597673333333333,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710306000, "lat": 54.35103, "lng": 18.597678333333334, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710307000,
    "lat": 54.35103,
    "lng": 18.597678333333334,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710308000, "lat": 54.35103, "lng": 18.597678333333334, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710309000,
    "lat": 54.35103,
    "lng": 18.597678333333334,
    "PM25": 6.0
  }, {"t": 1593710310000, "lat": 54.351035, "lng": 18.597676666666665, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710311000,
    "lat": 54.351035,
    "lng": 18.597676666666665,
    "PM10": 7.0,
    "PM25": 5.5
  }, {"t": 1593710312000, "lat": 54.351035, "lng": 18.597676666666665, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710313000,
    "lat": 54.351038333333335,
    "lng": 18.597675,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710314000, "lat": 54.351038333333335, "lng": 18.597675, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710315000,
    "lat": 54.351038333333335,
    "lng": 18.597675,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710316000, "lat": 54.351038333333335, "lng": 18.597675, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710317000,
    "lat": 54.351038333333335,
    "lng": 18.597675,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710318000, "lat": 54.351038333333335, "lng": 18.597675, "PM25": 5.0}, {
    "t": 1593710319000,
    "lat": 54.351036666666666,
    "lng": 18.597676666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710320000,
    "lat": 54.351036666666666,
    "lng": 18.597676666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710321000,
    "lat": 54.351036666666666,
    "lng": 18.597676666666665,
    "PM10": 6.0,
    "PM25": 6.0
  }, {
    "t": 1593710322000,
    "lat": 54.351036666666666,
    "lng": 18.597676666666665,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593710323000,
    "lat": 54.35104333333334,
    "lng": 18.597671666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710324000,
    "lat": 54.35104333333334,
    "lng": 18.597671666666667,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710325000,
    "lat": 54.35104333333334,
    "lng": 18.597671666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710326000,
    "lat": 54.35104666666667,
    "lng": 18.597661666666667,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710327000,
    "lat": 54.35104666666667,
    "lng": 18.597661666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710328000,
    "lat": 54.35104666666667,
    "lng": 18.597661666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710329000, "lat": 54.35104666666667, "lng": 18.59766, "PM10": 4.5, "PM25": 4.5}, {
    "t": 1593710330000,
    "lat": 54.35104666666667,
    "lng": 18.59766,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710331000, "lat": 54.35104666666667, "lng": 18.59766, "PM25": 4.0}, {
    "t": 1593710332000,
    "lat": 54.351038333333335,
    "lng": 18.597668333333335,
    "PM10": 4.0,
    "PM25": 5.0
  }, {
    "t": 1593710333000,
    "lat": 54.351038333333335,
    "lng": 18.597668333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710334000,
    "lat": 54.351038333333335,
    "lng": 18.597668333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710335000,
    "lat": 54.351038333333335,
    "lng": 18.597668333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710336000,
    "lat": 54.35104333333334,
    "lng": 18.597661666666667,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710337000,
    "lat": 54.35104333333334,
    "lng": 18.597661666666667,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710338000, "lat": 54.35104333333334, "lng": 18.597661666666667, "PM25": 5.0}, {
    "t": 1593710339000,
    "lat": 54.351045,
    "lng": 18.597653333333334,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710340000, "lat": 54.351045, "lng": 18.597653333333334, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710341000,
    "lat": 54.351045,
    "lng": 18.597653333333334,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710342000,
    "lat": 54.35104333333334,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710343000,
    "lat": 54.35104333333334,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710344000,
    "lat": 54.35104333333334,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710345000,
    "lat": 54.35104333333334,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710346000,
    "lat": 54.35104333333334,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710347000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710348000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710349000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710350000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 6.0,
    "PM25": 6.0
  }, {
    "t": 1593710351000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710352000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710353000,
    "lat": 54.35104166666667,
    "lng": 18.597651666666668,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710354000, "lat": 54.351045, "lng": 18.597648333333332, "PM10": 7.0, "PM25": 6.0}, {
    "t": 1593710355000,
    "lat": 54.351045,
    "lng": 18.597648333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {"t": 1593710356000, "lat": 54.351045, "lng": 18.597648333333332, "PM10": 9.0, "PM25": 6.0}, {
    "t": 1593710357000,
    "lat": 54.35104666666667,
    "lng": 18.597648333333332,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710358000,
    "lat": 54.35104666666667,
    "lng": 18.597648333333332,
    "PM10": 9.0,
    "PM25": 6.0
  }, {
    "t": 1593710359000,
    "lat": 54.35104666666667,
    "lng": 18.597648333333332,
    "PM10": 9.0,
    "PM25": 6.0
  }, {"t": 1593710360000, "lat": 54.351045, "lng": 18.59766, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593710361000,
    "lat": 54.351045,
    "lng": 18.59766,
    "PM10": 7.0,
    "PM25": 4.0
  }, {"t": 1593710362000, "lat": 54.351045, "lng": 18.59766, "PM10": 7.5, "PM25": 4.5}, {
    "t": 1593710363000,
    "lat": 54.35104,
    "lng": 18.597673333333333,
    "PM10": 9.0,
    "PM25": 7.0,
    "temp": 23.899999618530273,
    "RH": 55.900001525878906
  }, {"t": 1593710364000, "lat": 54.35104, "lng": 18.597673333333333, "PM10": 9.0, "PM25": 7.0}, {
    "t": 1593710365000,
    "lat": 54.35104,
    "lng": 18.597673333333333,
    "PM10": 9.0,
    "PM25": 7.0
  }, {"t": 1593710366000, "lat": 54.35104, "lng": 18.597673333333333, "PM25": 14.0}, {
    "t": 1593710367000,
    "lat": 54.35103333333333,
    "lng": 18.597605,
    "PM10": 16.0,
    "PM25": 16.0,
    "temp": 23.799999237060547,
    "RH": 54.79999923706055
  }, {"t": 1593710368000, "lat": 54.35103333333333, "lng": 18.597605, "PM10": 20.0, "PM25": 19.0}, {
    "t": 1593710369000,
    "lat": 54.35103333333333,
    "lng": 18.597605,
    "PM10": 27.0,
    "PM25": 27.5
  }, {"t": 1593710370000, "lat": 54.35103333333333, "lng": 18.597605, "PM10": 42.0, "PM25": 35.0}, {
    "t": 1593710371000,
    "lat": 54.35103333333333,
    "lng": 18.597605,
    "PM10": 47.5,
    "PM25": 36.0,
    "temp": 23.700000762939453,
    "RH": 54.599998474121094
  }, {
    "t": 1593710372000,
    "lat": 54.351035,
    "lng": 18.597538333333333,
    "PM10": 50.0,
    "PM25": 36.0
  }, {
    "t": 1593710373000,
    "lat": 54.351035,
    "lng": 18.597538333333333,
    "PM10": 66.0,
    "PM25": 50.0
  }, {
    "t": 1593710374000,
    "lat": 54.351035,
    "lng": 18.597538333333333,
    "PM10": 76.0,
    "PM25": 63.0
  }, {"t": 1593710375000, "lat": 54.351025, "lng": 18.59742333333333, "PM10": 85.0, "PM25": 76.0}, {
    "t": 1593710376000,
    "lat": 54.351025,
    "lng": 18.59742333333333,
    "PM10": 100.0,
    "PM25": 93.0
  }, {
    "t": 1593710377000,
    "lat": 54.351025,
    "lng": 18.59742333333333,
    "PM10": 114.0,
    "PM25": 107.0
  }, {
    "t": 1593710378000,
    "lat": 54.35102,
    "lng": 18.597358333333332,
    "PM10": 114.0,
    "PM25": 107.0
  }, {
    "t": 1593710379000,
    "lat": 54.35102,
    "lng": 18.597358333333332,
    "PM10": 114.0,
    "PM25": 123.0
  }, {
    "t": 1593710380000,
    "lat": 54.35102,
    "lng": 18.597358333333332,
    "PM10": 128.0,
    "PM25": 123.0
  }, {
    "t": 1593710381000,
    "lat": 54.35102,
    "lng": 18.597358333333332,
    "PM10": 128.0,
    "PM25": 123.0
  }, {"t": 1593710382000, "lat": 54.351025, "lng": 18.597345, "PM10": 128.0, "PM25": 132.0}, {
    "t": 1593710383000,
    "lat": 54.351025,
    "lng": 18.597345,
    "PM10": 136.0,
    "PM25": 132.0
  }, {"t": 1593710384000, "lat": 54.351025, "lng": 18.597345, "PM10": 136.0}, {
    "t": 1593710385000,
    "lat": 54.351081666666666,
    "lng": 18.597368333333332,
    "PM10": 137.0,
    "PM25": 133.0
  }, {
    "t": 1593710386000,
    "lat": 54.351081666666666,
    "lng": 18.597368333333332,
    "PM10": 156.0,
    "PM25": 149.0
  }, {
    "t": 1593710387000,
    "lat": 54.351081666666666,
    "lng": 18.597368333333332,
    "PM10": 166.0,
    "PM25": 160.0
  }, {
    "t": 1593710388000,
    "lat": 54.351081666666666,
    "lng": 18.597368333333332,
    "PM10": 159.0,
    "PM25": 153.0
  }, {
    "t": 1593710389000,
    "lat": 54.35111833333333,
    "lng": 18.597358333333332,
    "PM10": 148.0,
    "PM25": 140.0,
    "temp": 23.899999618530273,
    "RH": 53.900001525878906
  }, {
    "t": 1593710390000,
    "lat": 54.35111833333333,
    "lng": 18.597358333333332,
    "PM10": 133.0,
    "PM25": 13.0
  }, {
    "t": 1593710391000,
    "lat": 54.35111833333333,
    "lng": 18.597358333333332,
    "PM10": 122.0,
    "PM25": 113.0
  }, {
    "t": 1593710392000,
    "lat": 54.351145,
    "lng": 18.597381666666667,
    "PM10": 109.5,
    "PM25": 97.5,
    "temp": 23.799999237060547,
    "RH": 53.900001525878906
  }, {
    "t": 1593710393000,
    "lat": 54.351145,
    "lng": 18.597381666666667,
    "PM10": 96.0,
    "PM25": 83.0,
    "temp": 23.799999237060547,
    "RH": 53.900001525878906
  }, {
    "t": 1593710394000,
    "lat": 54.351145,
    "lng": 18.597381666666667,
    "PM10": 99.0,
    "PM25": 87.0
  }, {"t": 1593710395000, "lat": 54.35115166666667, "lng": 18.597585, "PM10": 85.0, "PM25": 74.0}, {
    "t": 1593710396000,
    "lat": 54.35115166666667,
    "lng": 18.597585,
    "PM10": 76.0,
    "PM25": 62.0
  }, {"t": 1593710397000, "lat": 54.35115166666667, "lng": 18.597585, "PM10": 76.0, "PM25": 62.0}, {
    "t": 1593710398000,
    "lat": 54.35115166666667,
    "lng": 18.597585,
    "PM25": 58.0
  }, {"t": 1593710399000, "lat": 54.35118, "lng": 18.597905, "PM10": 74.0, "PM25": 57.0}, {
    "t": 1593710400000,
    "lat": 54.35118,
    "lng": 18.597905,
    "PM10": 72.0,
    "PM25": 53.0
  }, {"t": 1593710401000, "lat": 54.35118, "lng": 18.597905, "PM10": 69.0, "PM25": 50.0}, {
    "t": 1593710402000,
    "lat": 54.35118,
    "lng": 18.597905,
    "PM10": 64.0,
    "PM25": 45.0
  }, {
    "t": 1593710403000,
    "lat": 54.35122,
    "lng": 18.598288333333333,
    "PM10": 54.0,
    "PM25": 34.0,
    "temp": 23.799999237060547,
    "RH": 53.70000076293945
  }, {"t": 1593710404000, "lat": 54.35122, "lng": 18.598288333333333, "PM10": 33.0, "PM25": 22.0}, {
    "t": 1593710405000,
    "lat": 54.35122,
    "lng": 18.598288333333333,
    "PM10": 24.5,
    "PM25": 22.0,
    "temp": 23.799999237060547,
    "RH": 53.79999923706055
  }, {
    "t": 1593710406000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 24.0,
    "PM25": 24.0,
    "temp": 23.799999237060547,
    "RH": 53.70000076293945
  }, {
    "t": 1593710407000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 45.0,
    "PM25": 39.0
  }, {
    "t": 1593710408000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 46.0,
    "PM25": 40.0
  }, {
    "t": 1593710409000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 45.0,
    "PM25": 39.0
  }, {
    "t": 1593710410000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 45.0,
    "PM25": 38.0
  }, {
    "t": 1593710411000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 45.0,
    "PM25": 38.0
  }, {
    "t": 1593710412000,
    "lat": 54.351148333333335,
    "lng": 18.598486666666666,
    "PM10": 45.0,
    "PM25": 39.0
  }, {"t": 1593710413000, "lat": 54.35106, "lng": 18.598223333333333, "PM10": 47.0, "PM25": 39.5}, {
    "t": 1593710414000,
    "lat": 54.35106,
    "lng": 18.598223333333333,
    "PM10": 47.0,
    "PM25": 41.0
  }, {"t": 1593710415000, "lat": 54.35106, "lng": 18.598223333333333, "PM10": 48.0, "PM25": 41.0}, {
    "t": 1593710416000,
    "lat": 54.35106,
    "lng": 18.598223333333333,
    "PM10": 49.0,
    "PM25": 40.0
  }, {"t": 1593710417000, "lat": 54.35106, "lng": 18.598223333333333, "PM10": 48.0, "PM25": 28.0}, {
    "t": 1593710418000,
    "lat": 54.35106,
    "lng": 18.598223333333333,
    "PM10": 29.0,
    "PM25": 28.0
  }, {"t": 1593710419000, "lat": 54.35106, "lng": 18.598223333333333, "PM10": 29.0, "PM25": 29.0}, {
    "t": 1593710420000,
    "lat": 54.35106,
    "lng": 18.598223333333333,
    "PM10": 33.5,
    "PM25": 32.0
  }, {
    "t": 1593710421000,
    "lat": 54.351005,
    "lng": 18.597593333333332,
    "PM10": 40.0,
    "PM25": 35.0
  }, {
    "t": 1593710422000,
    "lat": 54.351005,
    "lng": 18.597593333333332,
    "PM10": 39.0,
    "PM25": 34.0
  }, {
    "t": 1593710423000,
    "lat": 54.351005,
    "lng": 18.597593333333332,
    "PM10": 40.0,
    "PM25": 35.0
  }, {
    "t": 1593710424000,
    "lat": 54.35086166666667,
    "lng": 18.597356666666666,
    "PM10": 40.0,
    "PM25": 35.0,
    "RH": 55.900001525878906
  }, {
    "t": 1593710425000,
    "lat": 54.35086166666667,
    "lng": 18.597356666666666,
    "PM10": 47.0,
    "PM25": 38.0
  }, {
    "t": 1593710426000,
    "lat": 54.35086166666667,
    "lng": 18.597356666666666,
    "PM10": 47.0,
    "PM25": 38.0
  }, {"t": 1593710427000, "lat": 54.35086166666667, "lng": 18.597356666666666, "PM25": 37.0}, {
    "t": 1593710428000,
    "lat": 54.35075,
    "lng": 18.597495,
    "PM10": 47.0,
    "PM25": 40.0,
    "temp": 22.399999618530273
  }, {"t": 1593710429000, "lat": 54.35075, "lng": 18.597495, "PM10": 52.0, "PM25": 45.0}, {
    "t": 1593710430000,
    "lat": 54.35075,
    "lng": 18.597495,
    "PM10": 59.0,
    "PM25": 46.0
  }, {"t": 1593710431000, "lat": 54.35075, "lng": 18.597495, "PM10": 61.0, "PM25": 44.0}, {
    "t": 1593710432000,
    "lat": 54.350741666666664,
    "lng": 18.597773333333333,
    "PM10": 60.0,
    "PM25": 41.0,
    "temp": 22.399999618530273,
    "RH": 56.5
  }, {
    "t": 1593710433000,
    "lat": 54.350741666666664,
    "lng": 18.597773333333333,
    "PM10": 56.5,
    "PM25": 40.5
  }, {
    "t": 1593710434000,
    "lat": 54.350741666666664,
    "lng": 18.597773333333333,
    "PM10": 55.0,
    "PM25": 38.0,
    "temp": 22.200000762939453,
    "RH": 56.79999923706055
  }, {"t": 1593710435000, "lat": 54.35077, "lng": 18.598263333333332, "PM10": 52.0, "PM25": 37.0}, {
    "t": 1593710436000,
    "lat": 54.35077,
    "lng": 18.598263333333332,
    "PM10": 52.0,
    "PM25": 36.0
  }, {"t": 1593710437000, "lat": 54.35077, "lng": 18.598263333333332, "PM10": 52.0, "PM25": 36.0}, {
    "t": 1593710438000,
    "lat": 54.350805,
    "lng": 18.598573333333334,
    "PM10": 52.0,
    "PM25": 36.0,
    "temp": 22.100000381469727,
    "RH": 57.20000076293945
  }, {
    "t": 1593710439000,
    "lat": 54.350805,
    "lng": 18.598573333333334,
    "PM10": 47.0,
    "PM25": 32.0
  }, {
    "t": 1593710440000,
    "lat": 54.350805,
    "lng": 18.598573333333334,
    "PM10": 47.0,
    "PM25": 32.0
  }, {"t": 1593710441000, "lat": 54.35083, "lng": 18.598698333333335, "PM10": 47.0, "PM25": 32.0}, {
    "t": 1593710442000,
    "lat": 54.35083,
    "lng": 18.598698333333335,
    "PM10": 47.0,
    "PM25": 41.0
  }, {"t": 1593710443000, "lat": 54.35083, "lng": 18.598698333333335, "PM10": 56.0, "PM25": 49.0}, {
    "t": 1593710444000,
    "lat": 54.35083,
    "lng": 18.598698333333335,
    "PM10": 66.0,
    "PM25": 59.0
  }, {
    "t": 1593710445000,
    "lat": 54.350836666666666,
    "lng": 18.59868833333333,
    "PM10": 78.0,
    "PM25": 72.0,
    "temp": 22.299999237060547,
    "RH": 57.5
  }, {
    "t": 1593710446000,
    "lat": 54.350836666666666,
    "lng": 18.59868833333333,
    "PM10": 97.0,
    "PM25": 87.0
  }, {
    "t": 1593710447000,
    "lat": 54.350836666666666,
    "lng": 18.59868833333333,
    "PM10": 112.0,
    "PM25": 101.0
  }, {
    "t": 1593710448000,
    "lat": 54.350836666666666,
    "lng": 18.59868833333333,
    "PM10": 123.0,
    "PM25": 114.0
  }, {
    "t": 1593710449000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 135.0,
    "PM25": 126.0
  }, {
    "t": 1593710450000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 147.0,
    "PM25": 138.0
  }, {
    "t": 1593710451000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 149.5,
    "PM25": 139.0
  }, {
    "t": 1593710452000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 149.0,
    "PM25": 138.0,
    "temp": 22.299999237060547,
    "RH": 58.0
  }, {
    "t": 1593710453000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 142.0,
    "PM25": 133.0,
    "RH": 58.099998474121094
  }, {
    "t": 1593710454000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 134.0,
    "PM25": 13.0
  }, {
    "t": 1593710455000,
    "lat": 54.350838333333336,
    "lng": 18.59869,
    "PM10": 129.0,
    "PM25": 119.0
  }, {
    "t": 1593710456000,
    "lat": 54.35084333333333,
    "lng": 18.59868,
    "PM10": 117.0,
    "PM25": 107.0,
    "temp": 22.299999237060547
  }, {"t": 1593710457000, "lat": 54.35084333333333, "lng": 18.59868, "PM10": 109.0, "PM25": 99.0}, {
    "t": 1593710458000,
    "lat": 54.35084333333333,
    "lng": 18.59868,
    "PM10": 100.0,
    "PM25": 90.0
  }, {"t": 1593710459000, "lat": 54.35084333333333, "lng": 18.59868, "PM10": 100.0, "PM25": 90.0}, {
    "t": 1593710460000,
    "lat": 54.350926666666666,
    "lng": 18.59848,
    "PM10": 94.5,
    "PM25": 84.5,
    "temp": 22.15000057220459,
    "RH": 58.29999923706055
  }, {
    "t": 1593710461000,
    "lat": 54.350926666666666,
    "lng": 18.59848,
    "PM10": 78.0,
    "PM25": 66.0,
    "temp": 22.100000381469727,
    "RH": 58.400001525878906
  }, {"t": 1593710462000, "lat": 54.350926666666666, "lng": 18.59848, "PM10": 72.0, "PM25": 56.0}, {
    "t": 1593710463000,
    "lat": 54.350986666666664,
    "lng": 18.598291666666668,
    "PM10": 63.0,
    "PM25": 47.0
  }, {
    "t": 1593710464000,
    "lat": 54.350986666666664,
    "lng": 18.598291666666668,
    "PM10": 57.0,
    "PM25": 42.0
  }, {
    "t": 1593710465000,
    "lat": 54.350986666666664,
    "lng": 18.598291666666668,
    "PM10": 55.0,
    "PM25": 40.0
  }, {
    "t": 1593710466000,
    "lat": 54.350986666666664,
    "lng": 18.598291666666668,
    "PM10": 50.0,
    "PM25": 35.0
  }, {
    "t": 1593710467000,
    "lat": 54.351058333333334,
    "lng": 18.598093333333335,
    "PM10": 50.0,
    "PM25": 35.0,
    "temp": 22.100000381469727,
    "RH": 58.5
  }, {
    "t": 1593710468000,
    "lat": 54.351058333333334,
    "lng": 18.598093333333335,
    "PM10": 47.0,
    "PM25": 32.0,
    "temp": 22.100000381469727,
    "RH": 58.599998474121094
  }, {
    "t": 1593710469000,
    "lat": 54.351058333333334,
    "lng": 18.598093333333335,
    "PM10": 48.0,
    "PM25": 33.0
  }, {
    "t": 1593710470000,
    "lat": 54.351058333333334,
    "lng": 18.598093333333335,
    "PM10": 52.0,
    "PM25": 37.0
  }, {
    "t": 1593710471000,
    "lat": 54.351036666666666,
    "lng": 18.597903333333335,
    "PM10": 56.0,
    "PM25": 39.5,
    "temp": 22.100000381469727,
    "RH": 58.599998474121094
  }, {
    "t": 1593710472000,
    "lat": 54.351036666666666,
    "lng": 18.597903333333335,
    "PM10": 57.0,
    "PM25": 40.0
  }, {"t": 1593710473000, "lat": 54.351036666666666, "lng": 18.597903333333335, "PM25": 41.0}, {
    "t": 1593710474000,
    "lat": 54.35102166666667,
    "lng": 18.597836666666666,
    "PM10": 58.0,
    "PM25": 44.0
  }, {
    "t": 1593710475000,
    "lat": 54.35102166666667,
    "lng": 18.597836666666666,
    "PM10": 61.0,
    "PM25": 52.0
  }, {
    "t": 1593710476000,
    "lat": 54.35102166666667,
    "lng": 18.597836666666666,
    "PM10": 70.0,
    "PM25": 61.0
  }, {
    "t": 1593710477000,
    "lat": 54.35102166666667,
    "lng": 18.597836666666666,
    "PM10": 75.0,
    "PM25": 63.0
  }, {
    "t": 1593710478000,
    "lat": 54.351016666666666,
    "lng": 18.597808333333333,
    "PM10": 76.0,
    "PM25": 76.0,
    "temp": 22.100000381469727,
    "RH": 58.70000076293945
  }, {
    "t": 1593710479000,
    "lat": 54.351016666666666,
    "lng": 18.597808333333333,
    "PM10": 86.0,
    "PM25": 92.0,
    "temp": 22.100000381469727,
    "RH": 58.900001525878906
  }, {
    "t": 1593710480000,
    "lat": 54.351016666666666,
    "lng": 18.597808333333333,
    "PM10": 109.33333333333333,
    "PM25": 104.5
  }, {"t": 1593710481000, "lat": 54.351025, "lng": 18.597745, "PM10": 13.0, "PM25": 116.0}, {
    "t": 1593710482000,
    "lat": 54.351025,
    "lng": 18.597745,
    "PM10": 132.0,
    "PM25": 123.0
  }, {"t": 1593710483000, "lat": 54.351025, "lng": 18.597745, "PM10": 131.0, "PM25": 120.0}, {
    "t": 1593710484000,
    "lat": 54.351031666666664,
    "lng": 18.597648333333332,
    "PM10": 121.0,
    "PM25": 110.0
  }, {
    "t": 1593710485000,
    "lat": 54.351031666666664,
    "lng": 18.597648333333332,
    "PM10": 111.0,
    "PM25": 101.0
  }, {
    "t": 1593710486000,
    "lat": 54.351031666666664,
    "lng": 18.597648333333332,
    "PM10": 111.0,
    "PM25": 101.0
  }, {"t": 1593710487000, "lat": 54.351031666666664, "lng": 18.597648333333332, "PM25": 101.0}, {
    "t": 1593710488000,
    "lat": 54.35105333333333,
    "lng": 18.59761,
    "PM10": 111.0,
    "PM25": 109.0,
    "temp": 22.100000381469727,
    "RH": 59.29999923706055
  }, {
    "t": 1593710489000,
    "lat": 54.35105333333333,
    "lng": 18.59761,
    "PM10": 118.0,
    "PM25": 104.0,
    "temp": 22.100000381469727,
    "RH": 59.400001525878906
  }, {"t": 1593710490000, "lat": 54.35105333333333, "lng": 18.59761, "PM10": 106.0, "PM25": 83.5}, {
    "t": 1593710491000,
    "lat": 54.35105333333333,
    "lng": 18.59761,
    "PM10": 87.0,
    "PM25": 67.0
  }, {"t": 1593710492000, "lat": 54.35105333333333, "lng": 18.59761, "PM10": 78.0, "PM25": 57.0}, {
    "t": 1593710493000,
    "lat": 54.35105333333333,
    "lng": 18.59761,
    "PM10": 72.0,
    "PM25": 51.0
  }, {"t": 1593710494000, "lat": 54.35105333333333, "lng": 18.59761, "PM10": 69.0, "PM25": 58.0}, {
    "t": 1593710495000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 74.0,
    "PM25": 57.0,
    "RH": 61.70000076293945
  }, {
    "t": 1593710496000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 70.0,
    "PM25": 53.0
  }, {
    "t": 1593710497000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 63.0,
    "PM25": 46.0
  }, {
    "t": 1593710498000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 55.0,
    "PM25": 39.0
  }, {
    "t": 1593710499000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 52.0,
    "PM25": 36.0
  }, {
    "t": 1593710500000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 51.0,
    "PM25": 35.0
  }, {
    "t": 1593710501000,
    "lat": 54.35105333333333,
    "lng": 18.597606666666668,
    "PM10": 49.0,
    "PM25": 34.0
  }, {
    "t": 1593710502000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 49.0,
    "PM25": 34.0,
    "temp": 22.100000381469727,
    "RH": 62.099998474121094
  }, {
    "t": 1593710503000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 49.0,
    "PM25": 34.0,
    "temp": 22.100000381469727,
    "RH": 62.099998474121094
  }, {
    "t": 1593710504000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 44.0,
    "PM25": 31.0,
    "temp": 22.100000381469727
  }, {
    "t": 1593710505000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 44.0,
    "PM25": 31.0
  }, {
    "t": 1593710506000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 42.5,
    "PM25": 30.0
  }, {
    "t": 1593710507000,
    "lat": 54.35105333333333,
    "lng": 18.597603333333332,
    "PM10": 41.0,
    "PM25": 29.0
  }, {"t": 1593710508000, "lat": 54.35105333333333, "lng": 18.597603333333332, "PM25": 29.0}, {
    "t": 1593710509000,
    "lat": 54.351055,
    "lng": 18.597596666666668,
    "PM10": 41.0,
    "PM25": 3.0,
    "temp": 22.100000381469727,
    "RH": 62.099998474121094
  }, {
    "t": 1593710510000,
    "lat": 54.351055,
    "lng": 18.597596666666668,
    "PM10": 35.0,
    "PM25": 3.0
  }, {
    "t": 1593710511000,
    "lat": 54.351055,
    "lng": 18.597596666666668,
    "PM10": 35.0,
    "PM25": 3.0
  }, {
    "t": 1593710512000,
    "lat": 54.351055,
    "lng": 18.597596666666668,
    "PM10": 35.0,
    "PM25": 11.0
  }, {
    "t": 1593710513000,
    "lat": 54.351055,
    "lng": 18.597591666666666,
    "PM10": 20.0,
    "PM25": 11.0
  }, {"t": 1593710514000, "lat": 54.351055, "lng": 18.597591666666666, "PM10": 20.0, "PM25": 5.0}, {
    "t": 1593710515000,
    "lat": 54.351055,
    "lng": 18.597591666666666,
    "PM10": 11.0,
    "PM25": 5.0
  }, {
    "t": 1593710516000,
    "lat": 54.351058333333334,
    "lng": 18.597588333333334,
    "PM10": 11.0,
    "PM25": 5.0
  }, {
    "t": 1593710517000,
    "lat": 54.351058333333334,
    "lng": 18.597588333333334,
    "PM10": 11.0,
    "PM25": 5.0
  }, {
    "t": 1593710518000,
    "lat": 54.351058333333334,
    "lng": 18.597588333333334,
    "PM10": 11.0,
    "PM25": 5.0
  }, {
    "t": 1593710519000,
    "lat": 54.351058333333334,
    "lng": 18.597588333333334,
    "PM10": 11.0,
    "PM25": 5.0
  }, {
    "t": 1593710520000,
    "lat": 54.35106,
    "lng": 18.597591666666666,
    "PM10": 9.0,
    "PM25": 5.0,
    "temp": 22.200000762939453,
    "RH": 62.0
  }, {"t": 1593710521000, "lat": 54.35106, "lng": 18.597591666666666, "PM10": 9.0, "PM25": 5.0}, {
    "t": 1593710522000,
    "lat": 54.35106,
    "lng": 18.597591666666666,
    "PM10": 8.0,
    "PM25": 5.0
  }, {"t": 1593710523000, "lat": 54.35106, "lng": 18.597591666666666, "PM10": 8.0, "PM25": 5.0}, {
    "t": 1593710524000,
    "lat": 54.351061666666666,
    "lng": 18.597593333333332,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710525000,
    "lat": 54.351061666666666,
    "lng": 18.597593333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710526000,
    "lat": 54.351061666666666,
    "lng": 18.597593333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710527000,
    "lat": 54.351063333333336,
    "lng": 18.597593333333332,
    "PM10": 7.0,
    "PM25": 6.0,
    "temp": 22.200000762939453,
    "RH": 62.29999923706055
  }, {
    "t": 1593710528000,
    "lat": 54.351063333333336,
    "lng": 18.597593333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710529000,
    "lat": 54.351063333333336,
    "lng": 18.597593333333332,
    "PM10": 7.0,
    "PM25": 6.0
  }, {
    "t": 1593710530000,
    "lat": 54.351063333333336,
    "lng": 18.597593333333332,
    "PM10": 8.0,
    "PM25": 6.0
  }, {"t": 1593710531000, "lat": 54.351065, "lng": 18.597591666666666, "PM10": 8.0, "PM25": 6.0}, {
    "t": 1593710532000,
    "lat": 54.351065,
    "lng": 18.597591666666666,
    "PM10": 8.5,
    "PM25": 6.5
  }, {"t": 1593710533000, "lat": 54.351065, "lng": 18.597591666666666, "PM10": 9.0, "PM25": 7.0}, {
    "t": 1593710534000,
    "lat": 54.35106666666667,
    "lng": 18.597591666666666,
    "PM10": 9.0,
    "PM25": 7.0,
    "temp": 22.200000762939453,
    "RH": 62.400001525878906
  }, {
    "t": 1593710535000,
    "lat": 54.35106666666667,
    "lng": 18.597591666666666,
    "PM10": 11.0,
    "PM25": 8.0,
    "temp": 22.200000762939453,
    "RH": 63.79999923706055
  }, {
    "t": 1593710536000,
    "lat": 54.35106666666667,
    "lng": 18.597591666666666,
    "PM10": 10.0,
    "PM25": 7.0
  }, {
    "t": 1593710537000,
    "lat": 54.35106666666667,
    "lng": 18.597591666666666,
    "PM10": 10.0,
    "PM25": 7.0
  }, {
    "t": 1593710538000,
    "lat": 54.35106833333333,
    "lng": 18.59759,
    "PM10": 11.0,
    "PM25": 8.0,
    "temp": 22.200000762939453,
    "RH": 64.19999694824219
  }, {"t": 1593710539000, "lat": 54.35106833333333, "lng": 18.59759, "PM10": 11.0, "PM25": 8.0}, {
    "t": 1593710540000,
    "lat": 54.35106833333333,
    "lng": 18.59759,
    "PM10": 12.0,
    "PM25": 10.0
  }, {
    "t": 1593710541000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 12.0,
    "PM25": 10.0
  }, {
    "t": 1593710542000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 13.0,
    "PM25": 11.0
  }, {
    "t": 1593710543000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710544000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710545000,
    "lat": 54.35106666666667,
    "lng": 18.597588333333334,
    "PM10": 13.5,
    "PM25": 12.0,
    "temp": 22.299999237060547,
    "RH": 66.8499984741211
  }, {
    "t": 1593710546000,
    "lat": 54.35106666666667,
    "lng": 18.597588333333334,
    "PM10": 14.0,
    "PM25": 12.0
  }, {
    "t": 1593710547000,
    "lat": 54.35106666666667,
    "lng": 18.597588333333334,
    "PM10": 14.0,
    "PM25": 12.0
  }, {
    "t": 1593710548000,
    "lat": 54.35106666666667,
    "lng": 18.597588333333334,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710549000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710550000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 13.0,
    "PM25": 12.0
  }, {
    "t": 1593710551000,
    "lat": 54.35106833333333,
    "lng": 18.59758666666667,
    "PM10": 15.0,
    "PM25": 14.0
  }, {
    "t": 1593710552000,
    "lat": 54.35106833333333,
    "lng": 18.597588333333334,
    "PM10": 15.0,
    "PM25": 14.0
  }, {
    "t": 1593710553000,
    "lat": 54.35106833333333,
    "lng": 18.597588333333334,
    "PM10": 15.0,
    "PM25": 14.0
  }, {
    "t": 1593710554000,
    "lat": 54.35106833333333,
    "lng": 18.597588333333334,
    "PM10": 15.0,
    "PM25": 14.0
  }, {
    "t": 1593710555000,
    "lat": 54.35106833333333,
    "lng": 18.597588333333334,
    "PM10": 15.0,
    "PM25": 14.0
  }, {
    "t": 1593710556000,
    "lat": 54.35106833333333,
    "lng": 18.597585,
    "PM10": 16.0,
    "PM25": 15.0,
    "temp": 22.299999237060547,
    "RH": 66.4000015258789
  }, {"t": 1593710557000, "lat": 54.35106833333333, "lng": 18.597585, "PM10": 16.0, "PM25": 15.0}, {
    "t": 1593710558000,
    "lat": 54.35106833333333,
    "lng": 18.597585,
    "PM10": 16.0,
    "PM25": 15.0
  }, {
    "t": 1593710559000,
    "lat": 54.35106833333333,
    "lng": 18.597576666666665,
    "PM10": 15.0,
    "PM25": 14.0
  }, {"t": 1593710560000, "lat": 54.35106833333333, "lng": 18.597585, "PM10": 15.0, "PM25": 13.5}, {
    "t": 1593710561000,
    "lat": 54.35106833333333,
    "lng": 18.597576666666665,
    "PM10": 14.0,
    "PM25": 13.0
  }, {
    "t": 1593710562000,
    "lat": 54.35106833333333,
    "lng": 18.597576666666665,
    "PM10": 14.0,
    "PM25": 14.0
  }, {
    "t": 1593710563000,
    "lat": 54.35106833333333,
    "lng": 18.597573333333333,
    "PM10": 15.0,
    "PM25": 13.0,
    "temp": 22.299999237060547,
    "RH": 65.9000015258789
  }, {
    "t": 1593710564000,
    "lat": 54.35106833333333,
    "lng": 18.597573333333333,
    "PM10": 14.0,
    "PM25": 13.0
  }, {
    "t": 1593710565000,
    "lat": 54.35106833333333,
    "lng": 18.597573333333333,
    "PM10": 14.0,
    "PM25": 12.0
  }, {
    "t": 1593710566000,
    "lat": 54.35106833333333,
    "lng": 18.597573333333333,
    "PM10": 13.0,
    "PM25": 11.0
  }, {
    "t": 1593710567000,
    "lat": 54.35106833333333,
    "lng": 18.597561666666667,
    "PM10": 13.0,
    "PM25": 11.0,
    "temp": 22.299999237060547,
    "RH": 66.4000015258789
  }, {
    "t": 1593710568000,
    "lat": 54.35106833333333,
    "lng": 18.597561666666667,
    "PM10": 13.0,
    "PM25": 11.0
  }, {
    "t": 1593710569000,
    "lat": 54.35106833333333,
    "lng": 18.597561666666667,
    "PM10": 13.0,
    "PM25": 10.0
  }, {"t": 1593710570000, "lat": 54.35106833333333, "lng": 18.597555, "PM10": 11.0, "PM25": 10.0}, {
    "t": 1593710571000,
    "lat": 54.35106833333333,
    "lng": 18.597555,
    "PM10": 11.0,
    "PM25": 9.0
  }, {"t": 1593710572000, "lat": 54.35106833333333, "lng": 18.597555, "PM10": 11.0, "PM25": 9.0}, {
    "t": 1593710573000,
    "lat": 54.35106833333333,
    "lng": 18.597555,
    "PM10": 11.0,
    "PM25": 9.0
  }, {"t": 1593710574000, "lat": 54.35107, "lng": 18.597551666666668, "PM10": 11.0, "PM25": 9.0}, {
    "t": 1593710575000,
    "lat": 54.35107,
    "lng": 18.597551666666668,
    "PM10": 10.0,
    "PM25": 9.0
  }, {"t": 1593710576000, "lat": 54.35107, "lng": 18.597551666666668, "PM10": 10.0, "PM25": 8.5}, {
    "t": 1593710577000,
    "lat": 54.35107,
    "lng": 18.597551666666668,
    "PM10": 9.0,
    "PM25": 8.0
  }, {"t": 1593710578000, "lat": 54.35107, "lng": 18.597551666666668, "PM10": 9.0, "PM25": 8.0}, {
    "t": 1593710579000,
    "lat": 54.35107,
    "lng": 18.597551666666668,
    "PM10": 9.0,
    "PM25": 7.0
  }, {"t": 1593710580000, "lat": 54.35107, "lng": 18.597551666666668, "PM10": 9.0, "PM25": 7.0}, {
    "t": 1593710581000,
    "lat": 54.35107,
    "lng": 18.597555,
    "PM10": 9.0,
    "PM25": 6.0
  }, {"t": 1593710582000, "lat": 54.35107, "lng": 18.597551666666668, "PM10": 8.0, "PM25": 6.5}, {
    "t": 1593710583000,
    "lat": 54.35107,
    "lng": 18.597555,
    "PM10": 8.0,
    "PM25": 6.0
  }, {
    "t": 1593710584000,
    "lat": 54.35107166666667,
    "lng": 18.597551666666668,
    "PM10": 7.0,
    "PM25": 5.0,
    "temp": 22.299999237060547,
    "RH": 63.5
  }, {
    "t": 1593710585000,
    "lat": 54.35107166666667,
    "lng": 18.597551666666668,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593710586000,
    "lat": 54.35107166666667,
    "lng": 18.597551666666668,
    "PM10": 7.0,
    "PM25": 5.0
  }, {
    "t": 1593710587000,
    "lat": 54.35107166666667,
    "lng": 18.597551666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710588000, "lat": 54.351075, "lng": 18.597545, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710589000,
    "lat": 54.351075,
    "lng": 18.597545,
    "PM10": 4.5,
    "PM25": 4.5
  }, {"t": 1593710590000, "lat": 54.351075, "lng": 18.597545, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710591000,
    "lat": 54.351075,
    "lng": 18.597545,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710592000,
    "lat": 54.351078333333334,
    "lng": 18.597543333333334,
    "PM10": 6.0,
    "PM25": 6.0,
    "temp": 22.399999618530273,
    "RH": 62.0
  }, {
    "t": 1593710593000,
    "lat": 54.351078333333334,
    "lng": 18.597543333333334,
    "PM10": 6.0,
    "PM25": 6.0
  }, {
    "t": 1593710594000,
    "lat": 54.351078333333334,
    "lng": 18.597543333333334,
    "PM10": 6.0,
    "PM25": 6.0
  }, {
    "t": 1593710595000,
    "lat": 54.351078333333334,
    "lng": 18.597543333333334,
    "PM10": 6.0,
    "PM25": 6.0
  }, {
    "t": 1593710596000,
    "lat": 54.351081666666666,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.399999618530273,
    "RH": 61.20000076293945
  }, {
    "t": 1593710597000,
    "lat": 54.351081666666666,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710598000,
    "lat": 54.351081666666666,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710599000,
    "lat": 54.351081666666666,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710600000,
    "lat": 54.351083333333335,
    "lng": 18.597545,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.399999618530273,
    "RH": 62.29999923706055
  }, {
    "t": 1593710601000,
    "lat": 54.351083333333335,
    "lng": 18.597545,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.399999618530273,
    "RH": 62.599998474121094
  }, {
    "t": 1593710602000,
    "lat": 54.351083333333335,
    "lng": 18.597545,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.399999618530273
  }, {
    "t": 1593710603000,
    "lat": 54.351081666666666,
    "lng": 18.597545,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.399999618530273,
    "RH": 63.79999923706055
  }, {"t": 1593710604000, "lat": 54.351081666666666, "lng": 18.597545, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710605000,
    "lat": 54.351081666666666,
    "lng": 18.597545,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710606000, "lat": 54.351081666666666, "lng": 18.597545, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710607000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710608000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710609000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710610000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710611000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710612000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710613000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710614000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 4.0,
    "PM25": 4.0,
    "temp": 22.399999618530273,
    "RH": 64.69999694824219
  }, {
    "t": 1593710615000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 4.5,
    "PM25": 4.5,
    "temp": 22.399999618530273,
    "RH": 65.0999984741211
  }, {
    "t": 1593710616000,
    "lat": 54.351083333333335,
    "lng": 18.597541666666668,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710617000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710618000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710619000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710620000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710621000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710622000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710623000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710624000,
    "lat": 54.351083333333335,
    "lng": 18.597543333333334,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710625000,
    "lat": 54.351081666666666,
    "lng": 18.597538333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710626000,
    "lat": 54.351081666666666,
    "lng": 18.597538333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {
    "t": 1593710627000,
    "lat": 54.351081666666666,
    "lng": 18.597538333333333,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710628000, "lat": 54.351081666666666, "lng": 18.597535, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710629000,
    "lat": 54.351081666666666,
    "lng": 18.597535,
    "PM10": 4.0,
    "PM25": 4.0
  }, {"t": 1593710630000, "lat": 54.351081666666666, "lng": 18.597535, "PM10": 4.0, "PM25": 4.0}, {
    "t": 1593710631000,
    "lat": 54.351081666666666,
    "lng": 18.597535,
    "PM10": 4.0,
    "PM25": 3.0
  }, {
    "t": 1593710632000,
    "lat": 54.35108,
    "lng": 18.597535,
    "PM10": 4.0,
    "PM25": 3.0,
    "temp": 22.5,
    "RH": 67.19999694824219
  }, {
    "t": 1593710633000,
    "lat": 54.35108,
    "lng": 18.597535,
    "PM10": 4.0,
    "PM25": 3.0,
    "temp": 22.5,
    "RH": 67.5
  }, {
    "t": 1593710634000,
    "lat": 54.35108,
    "lng": 18.597535,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 22.5,
    "RH": 55.0
  }, {"t": 1593710635000, "lat": 54.35108, "lng": 18.597535, "PM25": 4.0}, {
    "t": 1593710636000,
    "lat": 54.351083333333335,
    "lng": 18.597535,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 22.399999618530273,
    "RH": 67.5999984741211
  }, {"t": 1593710637000, "lat": 54.351083333333335, "lng": 18.597535, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710638000,
    "lat": 54.351083333333335,
    "lng": 18.597535,
    "PM10": 5.0,
    "PM25": 4.0
  }, {"t": 1593710639000, "lat": 54.351083333333335, "lng": 18.597535, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710640000,
    "lat": 54.351085,
    "lng": 18.597525,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 22.5,
    "RH": 66.5999984741211
  }, {"t": 1593710641000, "lat": 54.351085, "lng": 18.597525, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710642000,
    "lat": 54.351085,
    "lng": 18.597525,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710643000,
    "lat": 54.351085,
    "lng": 18.597525,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.5,
    "RH": 67.5999984741211
  }, {"t": 1593710644000, "lat": 54.35108666666667, "lng": 18.59752, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710645000,
    "lat": 54.35108666666667,
    "lng": 18.59752,
    "PM10": 5.0,
    "PM25": 4.0
  }, {"t": 1593710646000, "lat": 54.351085, "lng": 18.597515, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710647000,
    "lat": 54.351085,
    "lng": 18.597515,
    "PM10": 5.0,
    "PM25": 4.0
  }, {"t": 1593710648000, "lat": 54.351085, "lng": 18.597515, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710649000,
    "lat": 54.351085,
    "lng": 18.597515,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710650000, "lat": 54.351085, "lng": 18.597515, "PM10": 6.0, "PM25": 6.0}, {
    "t": 1593710651000,
    "lat": 54.351085,
    "lng": 18.597515,
    "PM10": 6.0,
    "PM25": 6.0
  }, {"t": 1593710652000, "lat": 54.351085, "lng": 18.597515, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710653000,
    "lat": 54.351085,
    "lng": 18.597515,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710654000,
    "lat": 54.351083333333335,
    "lng": 18.597521666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710655000,
    "lat": 54.351083333333335,
    "lng": 18.597521666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710656000,
    "lat": 54.351083333333335,
    "lng": 18.597521666666665,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710657000,
    "lat": 54.351083333333335,
    "lng": 18.597521666666665,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710658000,
    "lat": 54.351083333333335,
    "lng": 18.597521666666665,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710659000,
    "lat": 54.351083333333335,
    "lng": 18.597525,
    "PM10": 5.0,
    "PM25": 5.0,
    "RH": 65.5
  }, {"t": 1593710660000, "lat": 54.351083333333335, "lng": 18.597525, "PM10": 5.0, "PM25": 5.0}, {
    "t": 1593710661000,
    "lat": 54.351083333333335,
    "lng": 18.597525,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710662000,
    "lat": 54.351083333333335,
    "lng": 18.597523333333335,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.600000381469727,
    "RH": 65.9000015258789
  }, {
    "t": 1593710663000,
    "lat": 54.351083333333335,
    "lng": 18.597523333333335,
    "PM10": 5.0,
    "PM25": 5.0,
    "temp": 22.600000381469727
  }, {
    "t": 1593710664000,
    "lat": 54.351083333333335,
    "lng": 18.597523333333335,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710665000,
    "lat": 54.351083333333335,
    "lng": 18.597523333333335,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710666000,
    "lat": 54.351083333333335,
    "lng": 18.597518333333333,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.600000381469727,
    "RH": 66.5
  }, {
    "t": 1593710667000,
    "lat": 54.351083333333335,
    "lng": 18.597518333333333,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593710668000,
    "lat": 54.351083333333335,
    "lng": 18.597518333333333,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710669000,
    "lat": 54.351083333333335,
    "lng": 18.597518333333333,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710670000,
    "lat": 54.351083333333335,
    "lng": 18.597516666666667,
    "PM10": 5.5,
    "PM25": 4.0,
    "RH": 65.5
  }, {
    "t": 1593710671000,
    "lat": 54.351083333333335,
    "lng": 18.597516666666667,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593710672000,
    "lat": 54.351083333333335,
    "lng": 18.597516666666667,
    "PM10": 5.5,
    "PM25": 4.0
  }, {"t": 1593710673000, "lat": 54.351083333333335, "lng": 18.597516666666667, "PM25": 4.0}, {
    "t": 1593710674000,
    "lat": 54.351081666666666,
    "lng": 18.597515,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 22.600000381469727
  }, {"t": 1593710675000, "lat": 54.351081666666666, "lng": 18.597515, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710676000,
    "lat": 54.351081666666666,
    "lng": 18.597515,
    "PM10": 5.0,
    "PM25": 5.0
  }, {"t": 1593710677000, "lat": 54.351081666666666, "lng": 18.597515, "PM10": 6.0, "PM25": 5.0}, {
    "t": 1593710678000,
    "lat": 54.351083333333335,
    "lng": 18.597511666666666,
    "PM10": 6.0,
    "PM25": 5.0,
    "temp": 22.600000381469727,
    "RH": 63.29999923706055
  }, {
    "t": 1593710679000,
    "lat": 54.351083333333335,
    "lng": 18.597511666666666,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710680000,
    "lat": 54.351083333333335,
    "lng": 18.597511666666666,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710681000,
    "lat": 54.351083333333335,
    "lng": 18.597513333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710682000,
    "lat": 54.351083333333335,
    "lng": 18.597513333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710683000,
    "lat": 54.351083333333335,
    "lng": 18.597513333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {
    "t": 1593710684000,
    "lat": 54.351083333333335,
    "lng": 18.597513333333332,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710685000, "lat": 54.351085, "lng": 18.597515, "PM10": 5.5, "PM25": 4.5}, {
    "t": 1593710686000,
    "lat": 54.351085,
    "lng": 18.597515,
    "PM10": 5.0,
    "PM25": 4.0
  }, {"t": 1593710687000, "lat": 54.351085, "lng": 18.597515, "PM10": 5.0, "PM25": 4.0}, {
    "t": 1593710688000,
    "lat": 54.35108666666667,
    "lng": 18.597516666666667,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710689000,
    "lat": 54.35108666666667,
    "lng": 18.597516666666667,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710690000,
    "lat": 54.35108666666667,
    "lng": 18.597516666666667,
    "PM10": 8.0,
    "PM25": 5.0
  }, {
    "t": 1593710691000,
    "lat": 54.35109166666667,
    "lng": 18.597518333333333,
    "PM10": 5.0,
    "PM25": 4.0,
    "temp": 22.600000381469727,
    "RH": 62.599998474121094
  }, {
    "t": 1593710692000,
    "lat": 54.35109166666667,
    "lng": 18.597518333333333,
    "PM10": 5.0,
    "PM25": 4.0
  }, {
    "t": 1593710693000,
    "lat": 54.35109166666667,
    "lng": 18.597518333333333,
    "PM10": 5.0,
    "PM25": 5.0
  }, {
    "t": 1593710694000,
    "lat": 54.35109166666667,
    "lng": 18.597518333333333,
    "PM10": 6.0,
    "PM25": 5.0
  }, {"t": 1593710695000, "lat": 54.35109166666667, "lng": 18.597518333333333}, {
    "t": 1593710696000,
    "lat": 54.35109666666666,
    "lng": 18.597521666666665,
    "PM10": 6.0,
    "PM25": 4.0
  }, {
    "t": 1593710697000,
    "lat": 54.35109666666666,
    "lng": 18.597521666666665,
    "PM10": 4.5,
    "PM25": 3.0
  }, {
    "t": 1593710698000,
    "lat": 54.35109666666666,
    "lng": 18.597521666666665,
    "PM10": 4.0,
    "PM25": 3.0
  }, {
    "t": 1593710699000,
    "lat": 54.351103333333334,
    "lng": 18.597521666666665,
    "PM10": 5.0,
    "PM25": 3.0,
    "temp": 22.700000762939453,
    "RH": 61.29999923706055
  }, {
    "t": 1593710700000,
    "lat": 54.351103333333334,
    "lng": 18.597521666666665,
    "PM10": 5.0,
    "PM25": 3.0,
    "temp": 22.700000762939453,
    "RH": 60.79999923706055
  }, {
    "t": 1593710701000,
    "lat": 54.351103333333334,
    "lng": 18.597521666666665,
    "PM10": 5.0,
    "PM25": 3.0,
    "temp": 22.700000762939453,
    "RH": 60.599998474121094
  }, {
    "t": 1593710702000,
    "lat": 54.351103333333334,
    "lng": 18.597521666666665,
    "PM10": 6.0,
    "PM25": 3.0
  }, {"t": 1593710703000, "lat": 54.351103333333334, "lng": 18.597521666666665, "PM10": 6.0}];

export const mobile_box_path = [
  [
    18.599982261657715,
    54.34961582865913
  ],
  [
    18.598544597625732,
    54.34994099225372
  ],
  [
    18.59712839126587,
    54.3502286348274
  ],
  [
    18.596720695495605,
    54.35047875716797
  ],
  [
    18.59663486480713,
    54.3508289258872
  ],
  [
    18.596527576446533,
    54.35122911505555
  ],
  [
    18.59639883041382,
    54.3516418060536
  ],
  [
    18.596291542053223,
    54.35204198730578
  ],
  [
    18.59614133834839,
    54.352492186556155
  ],
  [
    18.596034049987793,
    54.35296739152504
  ],
  [
    18.595926761627197,
    54.35345509617346
  ],
  [
    18.59584093093872,
    54.35389277490383
  ]
];

export const new_mobile_path_dawid = [
  [
    18.594127,
    54.354238
  ],
  [
    18.594352,
    54.354369,
  ],
  [
    18.594728,
    54.354255,
  ],
  [
    18.595382,
    54.354047,
  ],
  [
    18.595500,
    54.354030,
  ],
  [
    18.595500,
    54.354030,
  ],
  [
    18.595816,
    54.353568,
  ],
  [
    18.596058,
    54.352730,
  ],
  [
    18.596434,
    54.351755,
  ], [
    18.596434,
    54.351755,
  ],
  [
    18.596584,
    54.350561,
  ],
  [
    18.596327, 54.350517,
  ],
  [
    18.593956, 54.350936
  ],
  [
    18.591928, 54.352018
  ],
  [
    18.590561, 54.353567,
  ],
  [
    18.588816, 54.355436,
  ],
  [
    18.588451, 54.355905,
  ], [
    18.588451, 54.355905,
  ],
  [
    18.588451, 54.355905,
  ],
  [
    18.586799, 54.357537,
  ],
  [
    18.587293, 54.358418,
  ],
  [
    18.588988, 54.358118,
  ],
  [
    18.590930, 54.357493,
  ],

  [
    18.593226, 54.356611,
  ],
  [
    18.594245, 54.355542,
  ],

  [
    18.595457, 54.355023,
  ],
  [
    18.595564, 54.354385,
  ],
  [
    18.594802, 54.354266,
  ],
  [
    18.594238, 54.354291,
  ],

  [
    18.594238, 54.354291,
  ],

  [
    18.594238, 54.354291,
  ],

  [
    18.594238, 54.354291,
  ],

];