/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionText from "./Sections/SectionText.js";
import SectionBlogInfo from "./Sections/SectionBlogInfo.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import { BlogPosts } from "./data.js";
// import SectionComments from "./Sections/SectionComments.js";
// import SectionSimilarStories from "./Sections/SectionSimilarStories.js";

const useBlogPageStyles = makeStyles(blogPostPageStyle);

export default function BlogPostPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useBlogPageStyles();
  const splitedHref = window.location.href.split("/");
  const postId = splitedHref[splitedHref.length - 1];
  const post = BlogPosts[postId - 1];

  return (
    <div >
      <Header
        fixed
      />
      <Parallax image={post.backgroundImg} filter="dark">
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>
                {post.title}
              </h1>
              <h4 className={classes.subtitle}>
                {post.subtitle}
              </h4>
              <br />
              <Button color="rose" size="lg" round
                      onClick={() => {window.scrollBy(0,450) }}>
                <FormatAlignLeft/> Czytaj artykuł
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionText post={post} />
          <SectionBlogInfo post={post} />
          {/*<SectionComments />*/}
        </div>
      </div>
      {/*<SectionSimilarStories post={post} />*/}
      <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <a
                href="https://seadata.eu"
              >
                SeaData
              </a>{" "}
              for a better web.
            </div>
          </div>
        }
      />
    </div>
  );
}
