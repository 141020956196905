import React from "react";
// nodejs library that concatenates classes
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import BookIcon from '@material-ui/icons/Book';
// import BookIcon from '@material-ui/icons/ChromeReaderMode';
import FlightIcon from "@material-ui/icons/Flight";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { useWebPSupportCheck } from "react-use-webp-support-check";
import { isBrowser } from "react-device-detect";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import helsinkiPortImg from "assets/img/uav/helsinki-port.jpg";
import helsinkiPortImgWebP from "assets/img/uav/helsinki-port.webp";
import "./PulseButton.css"
const useStyles = makeStyles(headersStyle);


export default function SectionHeaders({ ...rest }) {
  const classes = useStyles();
  const supportsWebP = useWebPSupportCheck();

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      {/* HEADER 1 START */}
      <div>
        <Header fixed />
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${supportsWebP ? helsinkiPortImgWebP : helsinkiPortImg}")` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                {isBrowser ? <h1 className={classes.title}>Mobile Monitoring.</h1>
                  :
                  <h2 className={classes.title}>.</h2>
                }

                <h4>
                  Mobile Monitoring to platforma inspekcyjno-pomiarowa podnosząca bezpieczeństwo i skracająca czas obsługi incydentów środowiskowych realizowanych przez firmy lub jednostki samorządu.
                  <br />
                  Platforma zasilana jest danymi zbieranymi przez drony wodne, latające oraz sieć bojek.

                </h4>
                <br />

                <Button
                  className={"pulseButton"}
                  color={"success"}
                  size="lg"
                  href="/map"
                >
                  Zobacz Demo na Mapie
                  <FlightIcon style={{marginLeft: 5}}/>
                </Button>

              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>

              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {/* HEADER 1 END */}
    </div>
  );
}
