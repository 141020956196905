import React, { useCallback } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Stop from "@material-ui/icons/Stop";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import { ReactComponent as SignalTowerIcon } from "assets/svg/signal-tower.svg";
import { ReactComponent as SignalTowerNoAnimateIcon } from "assets/svg/signal-tower-no-animate.svg";
import { ReactComponent as DroneIcon } from "assets/svg/drone.svg";
import { ReactComponent as DroneNoAnimateIcon } from "assets/svg/drone-no-animate.svg";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import UavMonitoringWhiteLogo from "../../assets/img/logos/mobile-monitoring-logo-white.png";
import UavMonitoringBlackLogo from "../../assets/img/logos/mobile-monitoring-logo-black.png";
import CamdroneWhiteLogo from "../../assets/img/logos/camdrone-logo-white-small.png";
import CamdroneBlackLogo from "../../assets/img/logos/camdrone-logo-black-small.png";
import SeadataWhiteLogo from "../../assets/img/logos/seadata-logo-white-small.png";
import SeadataBlackLogo from "../../assets/img/logos/seadata-logo-black-small.png";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import headersStyle from "../../assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import blogPostPageStyle from "../../assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import Flight from "@material-ui/icons/Flight";
import Layers from "@material-ui/icons/Layers";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import _ from "lodash";
import { useDemoReplaysContext, useDevicesContext } from "../../views/UAVMap/UAVMapContainer";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Small from "../Typography/Small";
import "views/SectionsPage/Sections/PulseButton.css";
import RegularButton from "../CustomButtons/Button";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { successColor, warningColor } from "../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles(styles);
const useBlogStyles = makeStyles(blogPostPageStyle);
const useHeaderStyles = makeStyles(headersStyle);
const useHeaderLinksStyles = makeStyles(headerLinksStyle);

const defaultColor = "transparent";
const defaultChangeColorOnScroll = {
  height: 400,
  color: "white"
};

const brand = <img id={"logo"} src={UavMonitoringWhiteLogo}
                   alt="UAV Monitoring Logo"/>;

const defaultBrandImg = UavMonitoringWhiteLogo;
const scrolledBrandImg = UavMonitoringBlackLogo;
const defaultCamdroneLogo = CamdroneWhiteLogo;
const scrolledCamdroneLogo = CamdroneBlackLogo;
const defaultSeadataLogo = SeadataWhiteLogo;
const scrolledSeadataLogo = SeadataBlackLogo;
const links = (classes) => <div className={classes.collapse}>
  <List className={classes.list + " " + classes.mlAuto}>
    <ListItem className={classes.listItem}>
      <Button
        href="/#home"
        className={classes.navLink}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        Home
      </Button>
      <Button
        href="/map"
        className={classes.navLink}
        // color="secondary"
        // variant="contained"
      >
        Mapa
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Button
        href="/#products"
        className={classes.navLink}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        Oferowane Usługi
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Button
        href="/#blog"
        className={classes.navLink}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        Blog
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Button
        href="/#about"
        className={classes.navLink}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        O nas
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Button
        href="/#contact"
        className={classes.navLink}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        Kontakt
      </Button>
    </ListItem>
  </List>
  <List className={classes.list + " " + classes.mlAuto}>
    <ListItem className={classes.listItem}>
      <a style={{ paddingRight: 20 }} href={"https://camdrone.pl"}><img id={"camdrone-logo"} src={CamdroneWhiteLogo}
                                                                        alt="Camdrone logo"/></a>
    </ListItem>
    <ListItem className={classes.listItem}>
      <a style={{ marginRight: -20 }} href={"https://seadata.eu"}><img id={"seadata-logo"} src={SeadataWhiteLogo}
                                                                       alt="Seadata logo"/> </a>
    </ListItem>
  </List>
</div>;

export default function Header (props) {
  const { state: demoState, dispatch: demoDispatch } = useDemoReplaysContext();
  const { activeMission } = demoState || {};

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [latestMissions, setLatestMissions] = React.useState([]);
  const classes = { ...useStyles(), ...useBlogStyles(), ...useHeaderLinksStyles() };
  const hClasses = useHeaderStyles();
  const changeColorOnScroll = props.changeColorOnScroll || defaultChangeColorOnScroll;
  const color = props.color || defaultColor;

  const { state: devicesState, dispatch: devicesDispatch } = useDevicesContext();
  const { onlineDevices, selectedDevice } = devicesState || {};

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup () {
      if (changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  // Load Missions List - use context
  React.useEffect(() => {
    const url = "https://api.yetisense.com/uav/flights/air";
    const xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      // update the state of the component with the result here
      setLatestMissions(xhr.response);
    });

    xhr.addEventListener("error", (error) => {
      // update the state of the component with the result here
      // alert("I'm So Sorry!! I cannot Sent your message. Please send your message on email: mwenta@seadata.pl");
      console.error("Error", error);
    });

    xhr.open("GET", url);
    xhr.responseType = "json";
    xhr.send();
  }, []);

  // Load selected Flight Data
  const loadFlight = useCallback((flightId) => {
    const url = "https://api.yetisense.com/uav/mission/air/" + flightId;
    const xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      // // update the state of the component with the result here
      // dispatch && dispatch({type: "reset-flight-simulation"});
      // mobileDispatch && mobileDispatch({type: "reset-mobile-simulation"});
      // stationaryDispatch && stationaryDispatch({type: "reset-stationary-simulation"});
      demoDispatch({ type: "start-demo", activeMission: flightId, demoData: xhr.response });
    });

    xhr.addEventListener("error", (error) => {
      // update the state of the component with the result here
      // alert("I'm So Sorry!! I cannot Sent your message. Please send your message on email: mwenta@seadata.pl");
      console.error("Error", error);
    });

    xhr.open("GET", url);
    xhr.responseType = "json";
    xhr.send();
  }, []);

  const terminateDemoFlight = useCallback(() => {
    // dispatch && dispatch({ type: "reset-flight-simulation" });
    // mobileDispatch && mobileDispatch({ type: "reset-mobile-simulation" });
    // stationaryDispatch && stationaryDispatch({ type: "reset-stationary-simulation" });
  }, []);

  // if flightId is present in url load data immiditaly
  React.useEffect(() => {
    if (window.location.href.includes("flightId")) {
      const splitted = window.location.href.split("=");
      const flightId = splitted[splitted.length - 1];
      loadFlight(flightId);
    } else {
      terminateDemoFlight();
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      document.getElementById("logo").src = scrolledBrandImg;
      document.getElementById("camdrone-logo").src = scrolledCamdroneLogo;
      document.getElementById("seadata-logo").src = scrolledSeadataLogo;
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      document.getElementById("logo").src = defaultBrandImg;
      document.getElementById("camdrone-logo").src = defaultCamdroneLogo;
      document.getElementById("seadata-logo").src = defaultSeadataLogo;
    }
  };
  const { fixed, absolute, flightsControl } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  return (
    <AppBar className={appBarClasses}
            style={props.backgroundColor && { padding: 0, marginTop: -18, backgroundColor: props.backgroundColor }}>
      {flightsControl && <div style={{ width: "100%", zIndex: 999, position: "absolute", left: "40px", top: 60 }}>
        <GridContainer >
          <GridItem xs={2}>
            {activeMission ? <Link
              to="/map"
              onClick={() => {
                demoDispatch({ type: "stop-demo" });
              }}
              className={classes.dropdownLink}
              style={{
                color: "red", marginTop: "1.3rem",
                padding: 0
              }}
            ><Stop /><b>STOP DEMO</b></Link> : <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={"rose"}
              buttonText="Misje Demo"
              buttonProps={{
                style: {
                  margin: "1.5rem",
                  padding: 0
                },
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={Flight}
              dropdownList={_.map(latestMissions, missionInfo => {
                return <Link
                  key={"flightLink" + missionInfo.flightId}
                  to={"/map?flightId=" + missionInfo.flightId}
                  className={classes.dropdownLink}
                  onClick={() => loadFlight(missionInfo.flightId)}
                >
                  <Tooltip title={missionInfo.description} placement={"right"}>
                      <p>
                        <Layers className={classes.dropdownIcons}/>{missionInfo.name}
                        <span style={{ display: "block", color: "grey", fontSize: "x-small" }}>
                          czas lotu: {Math.ceil(missionInfo.duration/60)} min
                        </span>
                      </p>
                  </Tooltip>
                </Link>;
              })}
            />
            }
          </GridItem>
          {!activeMission ? <>
            <GridItem xs={2}>
              <DeviceStateButton
                serialNo="GPNT_TARAS"
                isOnline={onlineDevices.includes("GPNT_TARAS")}
                isSelected={selectedDevice === "GPNT_TARAS"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "GPNT_TARAS" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DeviceStateButton
                serialNo="MOBILE_1"
                isOnline={onlineDevices.includes("MOBILE_1")}
                isSelected={selectedDevice === "MOBILE_1"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "MOBILE_1" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DeviceStateButton
                serialNo="AIR_1"
                isOnline={onlineDevices.includes("AIR_1")}
                isSelected={selectedDevice === "AIR_1"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "AIR_1" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DeviceStateButton
                serialNo="AIR_2"
                isOnline={onlineDevices.includes("AIR_2")}
                isSelected={selectedDevice === "AIR_2"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "AIR_2" })}
              />
            </GridItem>
          </> : <>
            <GridItem xs={2}>
              <DemoDeviceStateButton
                serialNo="DEMO_STATIONARY_1"
                isOnline={onlineDevices.includes("DEMO_STATIONARY_1")}
                isSelected={selectedDevice === "DEMO_STATIONARY_1"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "DEMO_STATIONARY_1" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DemoDeviceStateButton
                serialNo="DEMO_STATIONARY_2"
                isOnline={onlineDevices.includes("DEMO_STATIONARY_2")}
                isSelected={selectedDevice === "DEMO_STATIONARY_2"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "DEMO_STATIONARY_2" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DemoDeviceStateButton
                serialNo="DEMO_MOBILE_1"
                isOnline={onlineDevices.includes("DEMO_MOBILE_1")}
                isSelected={selectedDevice === "DEMO_MOBILE_1"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "DEMO_MOBILE_1" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DemoDeviceStateButton
                serialNo="DEMO_AIR_1"
                isOnline={onlineDevices.includes("DEMO_AIR_1")}
                isSelected={selectedDevice === "DEMO_AIR_1"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "DEMO_AIR_1" })}
              />
            </GridItem>
            <GridItem xs={2}>
              <DemoDeviceStateButton
                serialNo="DEMO_AIR_2"
                isOnline={onlineDevices.includes("DEMO_AIR_2")}
                isSelected={selectedDevice === "DEMO_AIR_2"}
                onClick={() => devicesDispatch({ type: "select-device", serialNo: "DEMO_AIR_2" })}
              />
            </GridItem>
          </>}
        </GridContainer>
      </div>}

      <Toolbar className={classes.container}>
        <Button className={classes.title}>
          <Link to="/#home">{brand}</Link>
        </Button>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links(hClasses)}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu/>
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close/>
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

// Custom componenets
const DeviceStateButton = (props) => {
  const { isOnline, isSelected, serialNo, onClick } = props;

  const isOffline = !isOnline;
  const color = isSelected ? warningColor[0] : isOnline ? successColor[0] : "white";
  const colorName = isSelected ? "warning" : isOnline ? "success" : "white";
  const offlineStyle = isOffline ? { color: "darkgray" } : {};
  const tooltipText = isSelected ? "" : isOnline ? "Urządzenie jest online." : "Urządzenie jest offline.";
  const fontWeight = isSelected ? "bold" : "normal";

  return <Tooltip title={tooltipText} placement={"bottom"}><RegularButton
    onClick={isOnline ? onClick : undefined}
    color={colorName}
    simple
    size="lg"
    style={offlineStyle}
  >
    {serialNo.includes("GPNT_TARAS") && <span style={{fontWeight}}>{isSelected ? <SignalTowerIcon fill={color}/> : <SignalTowerNoAnimateIcon fill={color}/>}GPNT Taras</span>}
    {serialNo.includes("AIR_1") && <span style={{fontWeight}}>{isSelected ? <DroneIcon fill={color}/> : <DroneNoAnimateIcon fill={color}/>} Dron #1</span>}
    {serialNo.includes("AIR_2") && <span style={{fontWeight}}>{isSelected ? <DroneIcon fill={color}/> : <DroneNoAnimateIcon fill={color}/>} Dron #2</span>}
    {serialNo.includes("MOBILE") && <span style={{fontWeight}}><DirectionsCar fill={color}/>Pojazd</span>}
  </RegularButton></Tooltip>;
};

const DemoDeviceStateButton = (props) => {
  const { isOnline, isSelected, serialNo, onClick } = props;

  const isOffline = !isOnline;
  const color = isSelected ? warningColor[0] : isOnline ? successColor[0] : "white";
  const colorName = isSelected ? "warning" : isOnline ? "success" : "white";
  const offlineStyle = isOffline ? { color: "darkgray" } : {};
  const tooltipText = isSelected ? "" : isOnline ? "Urządzenie jest online." : "Urządzenie jest offline.";
  const fontWeight = isSelected ? "bold" : "normal";

  return <Tooltip title={tooltipText} placement={"bottom"}><RegularButton
    onClick={isOnline ? onClick : undefined}
    color={colorName}
    simple
    size="lg"
    style={offlineStyle}
  >
    {serialNo.includes("DEMO_STATIONARY_1") && <span style={{fontWeight}}>{isSelected ? <SignalTowerIcon fill={color}/> : <SignalTowerNoAnimateIcon fill={color}/>}Demo stacja #1</span>}
    {serialNo.includes("DEMO_STATIONARY_2") && <span style={{fontWeight}}>{isSelected ? <SignalTowerIcon fill={color}/> : <SignalTowerNoAnimateIcon fill={color}/>}Demo stacja #2</span>}
    {serialNo.includes("DEMO_AIR_1") && <span style={{fontWeight}}>{isSelected ? <DroneIcon fill={color}/> : <DroneNoAnimateIcon fill={color}/>} Demo Dron #1</span>}
    {serialNo.includes("DEMO_AIR_2") && <span style={{fontWeight}}>{isSelected ? <DroneIcon fill={color}/> : <DroneNoAnimateIcon fill={color}/>} Demo Dron #2</span>}
    {serialNo.includes("DEMO_MOBILE_1") && <span style={{fontWeight}}><DirectionsCar fill={color}/>Demo Pojazd</span>}
  </RegularButton></Tooltip>;
};