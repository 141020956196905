import React from 'react';
import {Marker} from 'react-google-maps';
// import MobileBoxIcon from 'assets/svg/water-drone.svg';
import BuoyIcon from 'assets/img/droneMission/buoy-yellow.png'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import Card from '../../../components/Card/Card';
import {pmColor, tempColor} from './color_utils';
import {MeasurementUnit} from './Measurement';
import {green, lightBlue, orange, pink, red} from '@material-ui/core/colors';
import moment from 'moment';
import {Box} from '@material-ui/core';

const InfoBoxOptions = {
  pixelOffset: { width: -60, height: -210 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
  },
  closeBoxURL: "",
  // infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const CustomCardStyle = {
  background: "rgba(0, 27, 45, 0.7)",
  marginTop: 0,
  padding: 5,
  width: 240,
  color: "white",
  paddingLeft: 10,
  // textAlign: ""
};


export default function WaterBuoyMarker() {
    const height = 40;
    const width = 40;

   return <Marker
      key={"buoy-1"}
      position={{ lat: 54.360811, lng: 18.603871 }}
      icon={{ url: BuoyIcon, scaledSize: { height, width } }}
      // onClick={() => setShowStationInfobox({ ...showStationInfobox, [serialNo]: !showStationInfobox[serialNo] })}
    >

     <InfoBox options={InfoBoxOptions}>
       <Card style={CustomCardStyle}>
         <Box style={{textAlign: 'center'}}>
           <b>Bojka - {moment().format("hh:mm:ss")}</b>

         </Box>
         <br/>

         {/*<div style={{marginBottom: 5}}>*/}
         {/*  Temp: <span style={{ color: red[300] }}>*/}
         {/*         17.4 [{MeasurementUnit.temp}]*/}
         {/*       </span>*/}
         {/*</div>*/}

         <div style={{marginBottom: 5}}>
           Tlen: <span style={{ color: "lightblue" }}>
                  6.8 [mg/L]
                </span>
         </div>
         <div style={{marginBottom: 5}}>
           pH: <span style={{ color: green[600] }}>
                  7.4
                </span>
         </div>

         <div style={{marginBottom: 5}}>
           Mętność: <span style={{ color: green[300] }}>
                  1600 [NTU]
                </span>
         </div>
         <div style={{marginBottom: 5}}>
           Przewodność: <span style={{ color: orange[300] }}>
                  13.8 [mS/cm]
                </span>
         </div>
         <div style={{marginBottom: 5}}>
           Potencjał REDOX: <span style={{ color: pink[300] }}>
                  330 [mV]
                </span>
         </div>
       </Card>
     </InfoBox>
    </Marker>;

}
