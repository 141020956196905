import React from "react";
import Card from "../../../components/Card/Card";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import Flippy, { BackSide, FrontSide } from "react-flippy";

const FrontCardStyle = {
  background: "rgba(0, 27, 45, 0.7)",
  marginBottom: 10,
  marginTop: 10,
  padding: 5,
  paddingBottom: 10,
  width: 150,
  color: "white",
  textAlign: "center"
};

const FlippedCardStyle = {
  ...FrontCardStyle,
  background: "rgba(251, 140, 0, 0.8)"
};

export default function FlippyCard(props) {
  const { onClick, isFlipped } = props;

  return <Flippy
    flipOnClick={true}
    flipDirection="vertical"
    isFlipped={isFlipped}
  >
    <FrontSide style={{ padding: 0 }}>
      <Card style={FrontCardStyle} onClick={onClick}>
        <Content {...props} >
          {props.children}
        </Content>
      </Card>
    </FrontSide>
    <BackSide style={{ padding: 0 }}>
      <Card style={FlippedCardStyle} onClick={onClick}>
        <Content {...props}>
          {props.children}
        </Content>
      </Card>
    </BackSide>
  </Flippy>;
}

const Content = (props) => {
  const { name, value, units } = props;
  return <GridContainer>
    <GridItem xs={8} style={{ paddingLeft: 5 }}>{name}</GridItem>
    <GridItem xs={4} >
      {props.children}
    </GridItem>
    <GridItem xs={12}>
      <h3
        style={{ marginTop: 0, marginBottom: 0, display: "inline-block" }}>{value ? Math.round(value) : "-:-"}</h3>
      <h4 style={{ paddingLeft: 2, display: "inline-block" }}>{units}</h4>
    </GridItem>
  </GridContainer>;
};
