import React from "react";
import { Chart } from "react-charts";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MeasurementLabel, MeasurementUnit } from "./Measurement";
import { useFlightDataContext, useMobileDataContext, useStationaryDataContext } from "../UAVMapContainer";
import _ from "lodash";

const getBounds = (measurement) => {

  switch (measurement) {
    case "PM10":
      return { chartMin: 0, chartMax: 150 };
    case "PM25":
      return { chartMin: 0, chartMax: 150 };
    case "RH":
      return { chartMin: 0, chartMax: 100 };
    case "Pressure":
      return { chartMin: 940, chartMax: 1080 };
    case "temp":
      return { chartMin: 0, chartMax: 35 };
    default:
      console.info("NOT FOUND:", measurement);
  }
};

export default function FlightChart (props) {
  const { showFlightChart, selectedMeasurement } = props;

  const { flightsData } = useFlightDataContext().state;
  const { mobileData } = useMobileDataContext().state;
  const { stationaryData } = useStationaryDataContext().state;

  const [{ min, max }, setState] = React.useState({
    min: null,
    max: null
  });

  const { chartMin, chartMax } = getBounds(selectedMeasurement);

  const series = React.useMemo(
    () => {
      const numberOfSeries = Object.keys(flightsData).length + Object.keys(mobileData).length + Object.keys(stationaryData).length;

      return _.times(numberOfSeries, () => {return { type: "line", showPoints: false };});
    },
    [flightsData, mobileData, stationaryData]
  );

  const data = React.useMemo(
    () =>
      _.map({ ...flightsData, ...mobileData, ...stationaryData }, (valesArray, serialNo) => {
        return {
          label: serialNo,
          data: valesArray
        };
      })
    ,
    [flightsData, mobileData, stationaryData]
  );

  // Use the original data object and the datum index to reference the datum's primary value.
  const getPrimary = React.useCallback(
    (datum, i, series, seriesIndex, data) => {
      return new Date(datum.t);
    },
    []
  );

  // Use data.lines[n].data[n].value as each datums secondary value
  const getSecondary = React.useCallback(
    (datum, i, series, seriesIndex) => {
      const value = series.data[i][selectedMeasurement];
      return value;
      // return Math.max(0, value + seriesIndex * 10) || null;
    },
    [selectedMeasurement]
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "time", position: "bottom", hardMin: min, hardMax: max },
      { position: "left", type: "linear", stacked: false, hardMin: chartMin, hardMax: chartMax }
    ],
    [max, min, chartMax, chartMin]
  );

  const brush = React.useMemo(
    () => ({
      onSelect: (brushData) => {
        // console.log(brushData);
        setState({
          min: Math.min(brushData.start, brushData.end),
          max: Math.max(brushData.start, brushData.end)
        });
      }
    }),
    []
  );

  return (
    // A react-chart hyper-responsively and continuously fills the available
    // space of its parent element automatically
    <div
      style={{
        width: "70vw",
        height: "200px",
        background: "rgba(0, 27, 45, 0.7)",
        padding: 15,
        borderRadius: "5px",
        marginBottom: 10
      }}
    >
      <p style={{ position: "relative", textAlign: "center", color: "white" }}>
        <Button
          color={"inherit"}
          style={{ float: "left" }}
          onClick={() =>
            setState({
              min: null,
              max: null
            })}
        >
          Reset Zoom
        </Button>
        <b>{MeasurementLabel[selectedMeasurement]}</b> [{MeasurementUnit[selectedMeasurement]}]
        <IconButton
          color={"inherit"}
          style={{ float: "right" }}
          onClick={() => showFlightChart(null)}
          size={"small"}
        >
          <CloseIcon/>
        </IconButton>
      </p>

      <div style={{ width: "100%", height: "100%" }}>
        <Chart
          data={data}
          series={series}
          axes={axes}
          primaryCursor
          getPrimary={getPrimary}
          brush={brush}
          getSecondary={getSecondary}
          tooltip dark
        />
      </div>

    </div>
  );
}