const iconSize = { height: 50, width: 50 };

export const scaleIconSize = zoom => {
  let scaleFactor = 1;
  if (zoom >= 18) {
    scaleFactor = 1.5;
  } else if (zoom >= 16) {
    scaleFactor = 1.2;
  } else if (zoom > 14) {
    scaleFactor = 1.2;
  } else if (zoom > 10) {
    scaleFactor = 1.0;
  } else {
    scaleFactor = 0.8;
  }
  return { height: iconSize.height * scaleFactor, width: iconSize.width * scaleFactor };
};