import React, { useCallback, useState } from "react";
import styled from "styled-components";
import GridContainer from "components/Grid/GridContainer";
import { ReactComponent as ThermometerCold } from "../../../assets/svg/riskMapIcons/thermometerCold.svg";
import { ReactComponent as ThermometerHot } from "../../../assets/svg/riskMapIcons/thermometerHot.svg";
import { ReactComponent as Barometer } from "../../../assets/svg/riskMapIcons/barometer.svg";
import { ReactComponent as PMIcon } from "../../../assets/svg/riskMapIcons/molecule.svg";
import { ReactComponent as HumidityIcon } from "../../../assets/svg/humidity.svg";
import GridItem from "../../../components/Grid/GridItem";
import { Measurement, MeasurementUnit } from "./Measurement";
import {
  useDevicesContext,
  useFlightDataContext,
  useMobileDataContext,
  useStationaryDataContext
} from "../UAVMapContainer";
import FlippyCard from "./FlippyCard";

const CardsPaneWrapper = styled.div`  
  width: 150px;  
  margin-right: 1vw;
`;

const iconSize = 20;

export const PopperProps = {
  style: { fontWeight: "bolder" }
};

const initialFlippedState = {
  PM10: false,
  PM25: false,
  temp: false,
  RH: false,
  pressure: false,
};

export default function FlightInfoCard (props) {
  const { showFlightChart } = props;
  const [flippedCard, setFlippedCard] = useState(initialFlippedState);

  const toggleFlipCard = useCallback(cardId => setFlippedCard({
    ...initialFlippedState,
    [cardId]: !flippedCard[cardId]
  }), [flippedCard]);

  const { state: devicesState } = useDevicesContext();
  const { state: flightState } = useFlightDataContext();
  const { state: stationaryState } = useStationaryDataContext();
  const { state: mobileState } = useMobileDataContext();

  const { selectedDevice } = devicesState;
  const { flightsData } = flightState;
  const { mobileData } = mobileState;
  const { stationaryData } = stationaryState;

  const flightDatapoint = flightsData[selectedDevice] ? flightsData[selectedDevice][flightsData[selectedDevice].length - 1]: null;
  const mobileDatapoint = mobileData[selectedDevice] ? mobileData[selectedDevice][mobileData[selectedDevice].length - 1] : null;
  const stationaryDatapoint = stationaryData[selectedDevice] ? stationaryData[selectedDevice][stationaryData[selectedDevice].length - 1]: null;

  const datapoint = flightDatapoint || mobileDatapoint || stationaryDatapoint || {};
  const { RH, temp, PM10, PM25, pressure } = datapoint;

  return (
    <CardsPaneWrapper>
      <GridContainer>
        {/* PM10 */}
        <GridItem>
          <FlippyCard
            isFlipped={flippedCard["PM10"]}
            onClick={() => {
              showFlightChart(Measurement.PM10);
              toggleFlipCard("PM10");
            }}
            name={"PM10"}
            value={PM10}
            units={MeasurementUnit.PM10}
          >
            <PMIcon width={iconSize} height={iconSize}/>
          </FlippyCard>
        </GridItem>

        {/* PM2.5 */}
        <GridItem>
          <FlippyCard
            isFlipped={flippedCard["PM25"]}
            onClick={() => {
              showFlightChart(Measurement.PM25);
              toggleFlipCard("PM25");
            }}
            name={"PM2.5"}
            value={PM25}
            units={MeasurementUnit.PM25}
          >
            <PMIcon width={iconSize} height={iconSize}/>
          </FlippyCard>
        </GridItem>

        {/*/!* TEMPERATURE *!/*/}
        {/*<GridItem>*/}
        {/*  <FlippyCard*/}
        {/*    isFlipped={flippedCard["temp"]}*/}
        {/*    onClick={() => {*/}
        {/*      showFlightChart(Measurement.Temp);*/}
        {/*      toggleFlipCard("temp");*/}
        {/*    }}*/}
        {/*    name={"Temperatura"}*/}
        {/*    value={temp}*/}
        {/*    units={MeasurementUnit.temp}*/}
        {/*  >*/}
        {/*    {temp > 10 ? <ThermometerHot width={iconSize} height={iconSize}/> :*/}
        {/*      <ThermometerCold width={iconSize} height={iconSize}/>}*/}
        {/*  </FlippyCard>*/}
        {/*</GridItem>*/}

        {/* HUMIDITY - WATER DROP */}
        <GridItem>
          <FlippyCard
            isFlipped={flippedCard["RH"]}
            onClick={() => {
              showFlightChart(Measurement.RH);
              toggleFlipCard("RH");
            }}
            name={"Wilgotność"}
            value={RH}
            units={MeasurementUnit.RH}
          >
            <Barometer style={{ float: "right" }} width={iconSize} height={iconSize} />
          </FlippyCard>
        </GridItem>

        {/* PRESSURE - BAROMETER */}
        <GridItem>
          <FlippyCard
            isFlipped={flippedCard["pressure"]}
            onClick={() => {
              showFlightChart(Measurement.Pressure);
              toggleFlipCard("pressure");
            }}
            name={"Ciśnienie"}
            value={pressure}
            units={MeasurementUnit.Pressure}
          >
            <HumidityIcon fill={"white"} style={{ float: "right" }} width={iconSize} height={iconSize} />
          </FlippyCard>
        </GridItem>
      </GridContainer>
    </CardsPaneWrapper>);
}
