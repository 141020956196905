import React, { useState } from "react";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import {
  FLIGHT_PUSHER_CHANNEL_NAME, MOBILE_PUSHER_CHANNEL_NAME,
  PUSHER_MESSAGE_TAG, STATIONARY_PUSHER_CHANNEL_NAME, useDemoReplaysContext, useDevicesContext,
  useFlightDataContext, useMapContext, useMobileDataContext,
  useStationaryDataContext
} from "./UAVMapContainer";
import { useIdleTimer } from "react-idle-timer";

const OFFLINE_TIMEOUT = 1000 * 60 * 5; // 5 min

export default function PusherListener (props) {
  const { state: demoState } = useDemoReplaysContext();
  const { state: devicesState, dispatch: devicesDispatch } = useDevicesContext();
  const { dispatch: flightDispatch } = useFlightDataContext();
  const { dispatch: stationaryDispatch } = useStationaryDataContext();
  const { dispatch: mobileDispatch } = useMobileDataContext();
  const { dispatch: mapDispatch } = useMapContext();
  const { onlineDevices, selectedDevice } = devicesState;
  const flightChannel = useChannel(FLIGHT_PUSHER_CHANNEL_NAME);
  const isDemoRunning = demoState.activeMission;
  const [offlineTimers, setOfflineTimers] = useState({
    "GPNT_TARAS": useIdleTimer({
      events: [],
      timeout: OFFLINE_TIMEOUT,
      onIdle: () => {devicesDispatch({ type: "set-device-offline", serialNo: "GPNT_TARAS" })}
    }),
    "AIR_1": useIdleTimer({
      events: [],
      timeout: OFFLINE_TIMEOUT,
      onIdle: () => {devicesDispatch({ type: "set-device-offline", serialNo: "AIR_1" })}
    }),
    "AIR_2": useIdleTimer({
      events: [],
      timeout: OFFLINE_TIMEOUT,
      onIdle: () => {devicesDispatch({ type: "set-device-offline", serialNo: "AIR_2" })}
    }),
    "MOBILE_01": useIdleTimer({
      events: [],
      timeout: OFFLINE_TIMEOUT,
      onIdle: () => {devicesDispatch({ type: "set-device-offline", serialNo: "MOBILE_01" })}
    })
  });

  useEvent(flightChannel, PUSHER_MESSAGE_TAG, (event) => {
    const { serialNo } = event;
    if (!serialNo) return; // serialNo is mandatory

    // Announce device as ONLINE if not present
    if (!onlineDevices.includes(serialNo)) {
      devicesDispatch({ type: "set-device-online", serialNo });
      // Update Map Zoom when new AIR is online
      mapDispatch({ type: "set-map-center-and-zoom", zoom: 18, center: { lat: event.lat, lng: event.lng } });
    }
    // send all events to update FlightDataContext
    flightDispatch({ type: "new-flight-datapoint", serialNo: serialNo, flightDatapoint: event });

  //  OFFLINE WATCH
    if (offlineTimers[serialNo]) {
      offlineTimers[serialNo].reset(); // reset the timer
    }
    // Update Map Zoom if this serialNo is selected right now
    if (isDemoRunning || demoState.simulationData) return; // do not update map location when demo is running
    if (selectedDevice === serialNo) {
      mapDispatch({ type: "set-map-center", zoom: 18, center: { lat: event.lat, lng: event.lng } });
    }
  });

  const stationaryChannel = useChannel(STATIONARY_PUSHER_CHANNEL_NAME);
  useEvent(stationaryChannel, PUSHER_MESSAGE_TAG, (event) => {
    const { serialNo } = event;
    if (!serialNo) return; // serialNo is mandatory
    // Announce device as ONLINE if not present
    if (!onlineDevices.includes(serialNo)) {
      devicesDispatch({ type: "set-device-online", serialNo });
      mapDispatch({ type: "set-map-zoom", zoom: 18 });
    }
    stationaryDispatch({ type: "new-stationary-datapoint", serialNo: serialNo, stationaryDatapoint: event });
    //  OFFLINE WATCH
    if (offlineTimers[serialNo]) {
      offlineTimers[serialNo].reset(); // reset the timer
    }
    // Update Map center and zoom only if GPNT_TARAS is the only online device
    if (isDemoRunning || demoState.simulationData) return; // do not update map location when demo is running
    if (selectedDevice === serialNo || onlineDevices.length === 1) {
      mapDispatch({ type: "set-map-center", center: { lat: event.lat, lng: event.lng } });
    }
  });

  const mobileChannel = useChannel(MOBILE_PUSHER_CHANNEL_NAME);
  useEvent(mobileChannel, PUSHER_MESSAGE_TAG, (event) => {
    const { serialNo } = event;
    if (!serialNo) return; // serialNo is mandatory
    // Announce device as ONLINE if not present
    if (!devicesState.onlineDevices.includes(serialNo)) {
      devicesDispatch({ type: "set-device-online", serialNo });
    }
    mobileDispatch({ type: "new-mobile-datapoint", serialNo: serialNo, mobileDatapoint: event });
    //  OFFLINE WATCH
    if (offlineTimers[serialNo]) {
      offlineTimers[serialNo].reset(); // reset the timer
    }
    // Update Map center and zoom only if its first online device or only GPNT_TARAS is present
    if (isDemoRunning || demoState.simulationData) return; // do not update map location when demo is running
    if (selectedDevice === serialNo || onlineDevices.length === 0 || (onlineDevices.length === 1 && onlineDevices.includes("GPNT_TARAS"))) {
      mapDispatch({ type: "set-map-center-and-zoom", zoom: 16, center: { lat: event.lat, lng: event.lng } });
    }
  });

  return null;
}