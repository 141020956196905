import React, { useState } from "react";
import { Marker } from "react-google-maps";
import { MeasurementUnit } from "./Measurement";
import SignalTowerIcon from "assets/svg/signal-tower.svg";
import { pmColor, tempColor } from "./color_utils";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Card from "components/Card/Card";
import {
  useDevicesContext,
  useMapContext,
  useDemoReplaysContext,
  useStationaryDataContext
} from "../UAVMapContainer";
import _ from "lodash";
import { lightBlue } from "@material-ui/core/colors";
import { scaleIconSize } from "./utils";

const InfoBoxOptions = {
  pixelOffset: { width: -60, height: -210 },
  boxStyle: {
    padding: "0px 0px 0px 0px"
  },
  closeBoxURL: "",
  // infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const CustomCardStyle = {
  background: "rgba(0, 27, 45, 0.7)",
  marginTop: 0,
  padding: 5,
  width: 160,
  color: "white",
  textAlign: "center"
};

export default function StationaryBoxMarker () {
  const { state } = useStationaryDataContext();
  const datapointsPerDevice = state.stationaryData;

  const { state: devicesState } = useDevicesContext();
  const { onlineDevices } = devicesState;

  const { state: mapState } = useMapContext();
  const { zoom } = mapState;
  const { height, width } = scaleIconSize(zoom);

  const [showStationInfobox, setShowStationInfobox] = useState({ "GPNT_TARAS": true });

  const { state: demoState } = useDemoReplaysContext();
  const isDemoRunning = demoState?.activeMission;

  return (_.map(datapointsPerDevice, (datapoints, serialNo) => {
      //  if demo is running do not show actual devices
      if ((isDemoRunning && !serialNo.includes("DEMO")) ||
        !isDemoRunning && serialNo.includes("DEMO")
      ) {
        return null;
      }

      const data = datapoints[datapoints.length - 1];

      return onlineDevices.includes(serialNo) ? <Marker
        key={serialNo}
        position={{ lat: data.lat, lng: data.lng }}
        icon={{ url: SignalTowerIcon, scaledSize: { height, width } }}
        onClick={() => setShowStationInfobox({ ...showStationInfobox, [serialNo]: !showStationInfobox[serialNo] })}
      >
        {showStationInfobox[serialNo] &&
        <InfoBox options={InfoBoxOptions}>
          <Card style={CustomCardStyle}>
            {serialNo === "GPNT_TARAS" && <b>GPNT Taras</b>}
            {serialNo === "DEMO_STATIONARY_1" && <b>DEMO #1</b>}
            {serialNo === "DEMO_STATIONARY_2" && <b>DEMO #2</b>}
            {/*<div>{moment(data.t).format("hh:mm:ss")}</div>*/}
            <br/>

            <div style={{margin: 5}}>
              PM10: <span style={{ color: pmColor(data.PM10 || 10) }}>
                  {Math.round(data.PM10) || 10} [{MeasurementUnit.PM10}]
                </span>
            </div>
            <div style={{margin: 5}}>
              PM2.5: <span style={{ color: pmColor(data.PM10 || 10) }}>
                  {Math.round(data.PM25) || 8} [{MeasurementUnit.PM25}]
                </span>
            </div>
            <div style={{margin: 5}}>
              Ciśnienie: <span style={{color: "white"}}>
                  {Math.round(data.pressure) || 1023} [{MeasurementUnit.Pressure}]
                </span>
            </div>

            {/*<div>*/}
            {/*  Temp: <span style={{ color: tempColor(data.temp || 20) }}>*/}
            {/*      {Math.round(data.temp) || 20.0} [{MeasurementUnit.temp}]*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div style={{margin: 5}}>
              Wilgotność: <span style={{ color: lightBlue[300] }}>
                  {Math.round(data.RH) || 68.1} [{MeasurementUnit.RH}]
                </span>
            </div>
          </Card>
        </InfoBox>}
      </Marker> : null;
    })
  );
}
