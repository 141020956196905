/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Refresh from "@material-ui/icons/Refresh";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import cardsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import dronePort from "assets/img/uav/drone-port.jpg";
import dronePortWebP from "assets/img/uav/drone-port.webp";

import fieldPhoto from "assets/img/uav/pole-small.png";
import fieldPhotoWebP from "assets/img/uav/pole-small.webp";

import LocationOn from "@material-ui/core/SvgIcon/SvgIcon";
import Carousel from "react-slick/lib";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import SO2Przelot from "assets/img/uav/SO2.jpg";
import SO2PrzelotWebP from "assets/img/uav/SO2.webp";
//Images for Products
import droneAtThePort from "assets/img/uav/products/uav/1.jpg";
import uavImage2 from "assets/img/uav/products/uav/2.jpg";
import uavImage3 from "assets/img/uav/products/uav/3.jpg";
import uavImage4 from "assets/img/uav/products/uav/4.png";
import uavImage5 from "assets/img/uav/products/uav/5.jpg";
import uavImage6 from "assets/img/uav/products/uav/6.jpg";
import uavImage7 from "assets/img/uav/products/uav/7.png";
import uavImage8 from "assets/img/uav/products/uav/8.jpg";

import droneAtThePortWebP from "assets/img/uav/products/uav/1.webp";
import uavImage2WebP from "assets/img/uav/products/uav/2.webp";
import uavImage3WebP from "assets/img/uav/products/uav/3.webp";
import uavImage4WebP from "assets/img/uav/products/uav/4.webp";
import uavImage5WebP from "assets/img/uav/products/uav/5.webp";
import uavImage6WebP from "assets/img/uav/products/uav/6.webp";
import uavImage7WebP from "assets/img/uav/products/uav/7.webp";
import uavImage8WebP from "assets/img/uav/products/uav/8.webp";
//Images for BigData
import bigDataImage1 from "assets/img/uav/products/bigData/1.jpeg";
import bigDataImage2 from "assets/img/uav/products/bigData/2.jpg";
import bigDataImage3 from "assets/img/uav/products/bigData/3.png";
import bigDataImage4 from "assets/img/uav/products/bigData/4.jpg";
import bigDataVideo5 from "assets/img/uav/products/bigData/SO2.mp4";
import bigDataVideo6 from "assets/img/uav/products/bigData/PM10.mp4";

import bigDataImage1WebP from "assets/img/uav/products/bigData/1.webp";
import bigDataImage2WebP from "assets/img/uav/products/bigData/2.webp";
import bigDataImage3WebP from "assets/img/uav/products/bigData/3.webp";
import bigDataImage4WebP from "assets/img/uav/products/bigData/4.webp";
// Images for GIS
import gisImage1 from "assets/img/uav/products/gis/1.jpg";
import gisImage2 from "assets/img/uav/products/gis/2.jpg";
import gisImage3 from "assets/img/uav/products/gis/3.jpg";
import gisImage4 from "assets/img/uav/products/gis/4.jpg";
import gisImage5 from "assets/img/uav/products/gis/5.jpg";
import gisImage6 from "assets/img/uav/products/gis/6.jpeg";
import gisImage7 from "assets/img/uav/products/gis/7.png";
import gisImage8 from "assets/img/uav/products/gis/8.jpg";
import gisImage9 from "assets/img/uav/products/gis/9.jpg";

import gisImage1WebP from "assets/img/uav/products/gis/1.webp";
import gisImage2WebP from "assets/img/uav/products/gis/2.webp";
import gisImage3WebP from "assets/img/uav/products/gis/3.webp";
import gisImage4WebP from "assets/img/uav/products/gis/4.webp";
import gisImage5WebP from "assets/img/uav/products/gis/5.webp";
import gisImage6WebP from "assets/img/uav/products/gis/6.webp";
import gisImage7WebP from "assets/img/uav/products/gis/7.webp";
import gisImage8WebP from "assets/img/uav/products/gis/8.webp";
import gisImage9WebP from "assets/img/uav/products/gis/9.webp";

import { useWebPSupportCheck } from "react-use-webp-support-check";

const useStyles = makeStyles(projectsStyle);
const useCardsStyles = makeStyles(cardsStyles);
const useCarouselStyles = makeStyles(carouselStyle);

export default function SectionProducts({ ...rest }) {
  const [activeRotate1, setActiveRotate1] = React.useState("");
  const [activeRotate2, setActiveRotate2] = React.useState("");
  const [activeRotate3, setActiveRotate3] = React.useState("");
  // const classes = { ...useStyles(), ...useCardsStyles() };
  const classes = useStyles();
  const cardClasses = useCardsStyles();
  const carouselClasses = useCarouselStyles();
  const supportsWebP = useWebPSupportCheck();

  const settings = {
    dots: true,
    // dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    pauseOnHover: true
  };

  const gisSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    pauseOnHover: true
  };

  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(cardClasses.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(cardClasses.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(cardClasses.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  };
  
  return (
    <div className="cd-section" {...rest}>
      {/* Project 1 START */}
      <div className={classes.projects}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Nasze usługi
              </h2>
              {/*<div className={classes.tabSpace} />*/}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div
                className={
                  cardClasses.rotatingCardContainer +
                  " " +
                  cardClasses.manualRotate +
                  " " +
                  activeRotate1
                }
              >
                <Card raised className={cardClasses.cardRotate}>
                  <div className={cardClasses.front + " " + cardClasses.wrapperBackground}
                       style={{ backgroundImage: `url(${supportsWebP ? dronePortWebP : dronePort})` }}
                  >
                    <CardBody background className={cardClasses.cardBodyRotate}>
                      <h6 className={classes.cardCategory}>UAV</h6>
                      <a href="#" onClick={e => e.preventDefault()}>
                        <h3 className={classes.cardTitleWhite}>
                          Inspekcje  z dronów
                        </h3>
                      </a>
                      <p className={classes.cardDescription}>
                        Wykorzystujemy drony w roznego rodzaju inspekcjach i przelotach kontrolnych, monitoringu jakości i składu powietrza.
                      </p>
                      <div className={classes.textCenter}>
                        <Button
                          round
                          color="danger"
                          onClick={() =>
                            setActiveRotate1(cardClasses.activateRotate)
                          }
                        >
                          <Refresh /> Więcej
                        </Button>
                      </div>
                    </CardBody>
                  </div>
                  <div className={cardClasses.back }
                       // style={{ backgroundImage: `url(${dronePort})` }}
                  >
                    <CardBody background className={cardClasses.cardBodyRotate} style={{padding: 0}}>
                      <Carousel {...settings} >
                        <div>
                          <img src={droneAtThePort} alt="Dron w porcie" className="slick-image" />
                          {/*<div className="slick-caption">*/}
                            {/*<h4>*/}
                              {/*<LocationOn className="slick-icons" />*/}
                              {/*Yellowstone National Park, United States*/}
                            {/*</h4>*/}
                          {/*</div>*/}
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage2WebP : uavImage2} alt="Inspekcje gazów" className="slick-image" />
                          <div className="slick-caption">
                            <h4>
                              <LocationOn className="slick-icons" />
                              Inspekcja gazów
                            </h4>
                          </div>
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage3WebP : uavImage3} alt="Dron do badania smogu" className="slick-image" />
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage4WebP : uavImage4} alt="Wizualizacja przelotu i pomiarów PM10 w Porcie Gdańsk" className="slick-image" />
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage5WebP : uavImage5} alt="Dron Przemysłowy" className="slick-image" />
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage6WebP : uavImage6} alt="Mapa smogu" className="slick-image" />
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage7WebP : uavImage7} alt="DJI Matrice 600 Pro" className="slick-image" />
                        </div>
                        <div>
                          <img src={supportsWebP ? uavImage8WebP : uavImage8} alt="DJI Matrice 600" className="slick-image" />
                        </div>
                      </Carousel>
                      <div className={classes.textCenter}>
                        <Button
                          style={{top: 10}}
                          round
                          color="danger"
                          onClick={() => setActiveRotate1("")}
                        >
                          <Refresh/> Wróć
                        </Button>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div
                className={
                  cardClasses.rotatingCardContainer +
                  " " +
                  cardClasses.manualRotate +
                  " " +
                  activeRotate2
                }
              >
                <Card raised className={cardClasses.cardRotate}>
                  <div className={cardClasses.front + " " + cardClasses.wrapperBackground}
                       style={{ backgroundImage: `url(${supportsWebP ? SO2PrzelotWebP : SO2Przelot})` }}
                  >
                    <CardBody background className={cardClasses.cardBodyRotate}>
                      <h6 className={classes.cardCategory}>MAPY, RAPORTY, ANIMACJE 3D </h6>
                      <a href="#pablito" onClick={e => e.preventDefault()}>
                        <h3 className={classes.cardTitleWhite}>
                          Analizy, Raporty i wizualizacje
                        </h3>
                      </a>

                      <p className={classes.cardDescription}>
                        Tworzymy animacje 3D przelotów, mapy zanieczyszczeń i jakości powietrza oraz wnikliwe analizy zebranych danych.
                        <br/>
                        Przeloty dronem są wizualizowane w czasie rzeczywistym.
                      </p>
                      <div className={classes.textCenter}>
                      <Button
                        style={{top: 10}}
                        round
                        color="info"
                        onClick={() =>
                          setActiveRotate2(cardClasses.activateRotate)
                        }
                      >
                        <Refresh />
                        Więcej
                      </Button>
                      </div>
                    </CardBody>
                  </div>
                  <div className={cardClasses.back }>
                    <CardBody background className={cardClasses.cardBodyRotate} style={{padding: 5}}>
                        <Carousel {...settings} >
                          <video autoPlay loop className="slick-image"  >
                            <source src={bigDataVideo6} type="video/mp4" />
                          </video>
                          <div><video  autoPlay loop className="slick-image"  >
                            <source src={bigDataVideo5} type="video/mp4" />
                          </video></div>
                          <div><img src={supportsWebP ? bigDataImage1WebP : bigDataImage1} alt="Model 3D Hangaru" className="slick-image" /></div>
                          <div><img src={supportsWebP ? bigDataImage2WebP : bigDataImage2} alt="Termowizja" className="slick-image" /></div>
                          <div><img src={supportsWebP ? bigDataImage3WebP : bigDataImage3} alt="Model 3D hałdy piachu" className="slick-image" /></div>
                          <div><img src={supportsWebP ? bigDataImage4WebP : bigDataImage4} alt="Zdjęcie termowizyjne tankowca" className="slick-image" /></div>
                        </Carousel>
                      <div className={classes.textCenter}>
                      <Button
                        round
                        style={{top: 15}}
                        color="info"
                        onClick={() => setActiveRotate2("")}
                      >
                        <Refresh/> Wróć
                      </Button>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div
                className={
                  cardClasses.rotatingCardContainer +
                  " " +
                  cardClasses.manualRotate +
                  " " +
                  activeRotate3
                }
              >
                <Card raised className={cardClasses.cardRotate}>
                  <div className={cardClasses.front + " " + cardClasses.wrapperBackground}
                       style={{ backgroundImage: `url(${supportsWebP ? fieldPhotoWebP : fieldPhoto})` }}
                  >
                    <CardBody background className={cardClasses.cardBodyRotate}>
                      <h6 className={classes.cardCategory}>GEODEZJA, DANE SATELITARNE, ROLNICTOW, GIS</h6>
                      <a href="#pablito" onClick={e => e.preventDefault()}>
                        <h3 className={classes.cardTitleWhite}>
                          Dane satelitarne w nowoczesnym rolnictwie
                        </h3>
                      </a>
                      <p className={classes.cardDescription}>
                        Uzupełnieniamy zebrane przez drony informacje o dane satelitarne i GIS.
                        Wspomagamy zlecenia geodezyjne.
                        Wykonujemy analizy dla rolnictwa: detekcja zagrożenia suszą, kondycja upraw, wilgotność gleby.
                      </p>
                      <div className={classes.textCenter}>
                      <Button
                        round
                        color="warning"
                        onClick={() =>
                          setActiveRotate3(cardClasses.activateRotate)
                        }>
                        <Refresh /> Więcej
                      </Button>
                      </div>
                    </CardBody>
                  </div>
                  <div className={cardClasses.back }
                       // style={{ backgroundImage: `url(${fieldPhoto})` }}
                  >
                    {/*<CardBody background className={cardClasses.cardBodyRotate } style={{paddingLeft: 10, margin: 0, top:0, bottom: 0}}>*/}
                      <Carousel {...gisSettings} >
                        <div><img src={supportsWebP ? gisImage6WebP : gisImage6} alt="HD video obwodnica" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage1WebP : gisImage1} alt="Odwzorowanie GIS" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage8WebP : gisImage8} alt="Inspekcja budowy drogi" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage3WebP : gisImage3} alt="Third slide" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage7WebP : gisImage7} alt="Start i lądowisko drona" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage2WebP : gisImage2} alt="Second slide" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage4WebP : gisImage4} alt="Foruth slide" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage5WebP : gisImage5} alt="Inspekcja instalacji elektrycznych i wież" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                        <div><img src={supportsWebP ? gisImage9WebP : gisImage9} alt="Drony w rolnictwie" className={"slick-image" + " " + carouselClasses.roboImageStyle}/></div>
                      </Carousel>
                      <div className={classes.textCenter}>
                      <Button
                        style={{top: 15}}
                        round
                        color="warning"
                        onClick={() =>
                          setActiveRotate3("")
                        }>
                        <Refresh/> Wróć
                      </Button>
                      </div>
                    {/*</CardBody>*/}
                  </div>
                </Card>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 1 END */}
    </div>
  );
}
