import { green, lightBlue, red, orange } from "@material-ui/core/colors";

export const pmColor = (pmVal) => {
  if (pmVal < 30) {
    return green[300];
  } else if (pmVal < 60) {
    return orange[300];
  } else {
    return red[300];
  }
};

export const tempColor = (tempVal) => {
  if (tempVal < 18) {
    return lightBlue[300];
  } else {
    return red[300];
  }
};