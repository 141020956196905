export const Measurement = {
  PM10: "PM10",
  PM25: "PM25",
  Temp: "temp",
  RH: "RH",
  Pressure: "Pressure",
};

export const MeasurementLabel = {
  PM10: "PM10",
  PM25: "PM2.5",
  temp: "Temperatura",
  RH: "RH",
  Pressure: "Pressure",
};

export const MeasurementUnit = {
  PM10: "μg/m³",
  PM25: "μg/m³",
  temp: "°C",
  RH: "%",
  Pressure: "hPa",
};