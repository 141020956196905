import React, {useEffect, useMemo, useState} from "react";
import {
  useDemoReplaysContext,
  useDevicesContext,
  useFlightDataContext, useMapContext, useMobileDataContext,
  useStationaryDataContext
} from "./UAVMapContainer";
import _ from "lodash";
import {mobile_box_path, new_mobile_path_dawid, test_flight_data} from "./test_flight_data";

const SIMULATION_DISABLE = false;
const SIMULATION_TIMEOUT = 1000;
const TWO_MINUTES = 1000 * 60 * 1;

export default function DemoReplaysProvider(props) {
  const {state: demoState, dispatch: demoDispatch} = useDemoReplaysContext();
  const {activeMission, demoData, simulationData, simulationIdx} = demoState;
  const {state: devicesState, dispatch: devicesDispatch} = useDevicesContext();
  const {dispatch: flightDispatch} = useFlightDataContext();
  const {state: stationaryState, dispatch: stationaryDispatch} = useStationaryDataContext();
  const {stationaryData} = stationaryState;
  const {dispatch: mobileDispatch} = useMobileDataContext();
  const {dispatch: mapDispatch} = useMapContext();
  const {onlineDevices, selectedDevice} = devicesState;

  const startTimestamp = useMemo(() => new Date().getTime(), [activeMission]);

  const [idxs, setIdxs] = useState({
    "DEMO_STATIONARY_1": 0,
    "DEMO_STATIONARY_2": 0,
    "DEMO_AIR_1": 0,
    "DEMO_AIR_2": 0,
    "DEMO_MOBILE_1": 0
  });

  // DATA SIMULATION in LOOP
  useEffect(
    () => {
      if (SIMULATION_DISABLE) {
        return ;
      }
      if (!activeMission) {
        return;
      }

      let timer1 = setTimeout(() => {
        let tmpIdxs = idxs;
        // iterate all missionsData and publish them
        _.forOwn(demoData, function (deviceData, serialNo) {
          const demoSerialNo = "DEMO_" + serialNo;
          const idx = tmpIdxs[demoSerialNo];
          const timestamp = startTimestamp + idx * SIMULATION_TIMEOUT;

          if (idx >= deviceData.data.length) {
            return;
          }
          const data = deviceData.data;

          const demoDatapoint = {...data[idx], t: timestamp};
          // Announce device as ONLINE if not present
          if (!onlineDevices.includes(demoSerialNo)) {
            devicesDispatch({type: "set-device-online", serialNo: demoSerialNo});
            // Update Map Zoom when new AIR is online
            mapDispatch({
              type: "set-map-center-and-zoom",
              zoom: 18,
              center: {lat: demoDatapoint.lat, lng: demoDatapoint.lng}
            });
          }
          // // set DEFAULT selectedDevice
          if (deviceData.type === "yeti-air" && selectedDevice && !selectedDevice.includes("DEMO")) {
            devicesDispatch({type: "select-device", serialNo: demoSerialNo});
          }

          if (deviceData.type === "yeti-stationary") {
            stationaryDispatch({
              type: "new-stationary-datapoint",
              serialNo: demoSerialNo,
              stationaryDatapoint: demoDatapoint
            });
            if (selectedDevice === "DEMO_STATIONARY_1") {
              mapDispatch({
                type: "set-map-center-and-zoom",
                zoom: 18,
                center: {lat: demoDatapoint.lat, lng: demoDatapoint.lng}
              });
            }
          } else if (deviceData.type === "yeti-air") {
            flightDispatch({
              type: "new-flight-datapoint",
              serialNo: demoSerialNo,
              flightDatapoint: demoDatapoint
            });
          } else if (deviceData.type === "yeti-mobile") {
            mobileDispatch({
              type: "new-mobile-datapoint",
              serialNo: demoSerialNo,
              mobileDatapoint: demoDatapoint
            });
            if (selectedDevice === "DEMO_MOBILE_1") {
              mapDispatch({
                type: "set-map-center-and-zoom",
                zoom: 17,
                center: {lat: demoDatapoint.lat, lng: demoDatapoint.lng}
              });
            }
          }

          tmpIdxs = {...tmpIdxs, [demoSerialNo]: idx + 1};
        });
        // update Indexes after all demo data published
        setIdxs(tmpIdxs);
      }, SIMULATION_TIMEOUT);
      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(timer1);
      };
    },
    [demoData, idxs]
  );

  // SYMULACJA POJAZDU co 15 minut
  const simulationStartTimestamp = useMemo(() => new Date().getTime(), [activeMission]);
  // Odpalamy symulacje z opóźnieniem 2 minut
  useEffect(() => {
    if (SIMULATION_DISABLE) {
      return ;
    }
    let timer = setTimeout(() => {
      demoDispatch({type: "set-simulationData", simulationData: test_flight_data});
    }, TWO_MINUTES);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // PETLA SYMULACJI
  useEffect(() => {
    if (SIMULATION_DISABLE) {
      return ;
    }
    if (simulationData === null) {
      return;
    }

    // REST if KONIEC
    if (simulationIdx > new_mobile_path_dawid.length - 1) {
      demoDispatch({type: "set-simulationData", simulationData: null});
      demoDispatch({type: "set-simulationIdx", simulationIdx: 0});

      mapDispatch({
        type: "set-map-center-and-zoom",
        zoom: 18,
        center: {lat: 54.355270, lng: 18.594419}
      });
      // Zmień select
      // devicesDispatch({type: "set-device-offline", serialNo: "MOBILE_1", isSimulation: true});
      let timer = setTimeout(() => {
        demoDispatch({type: "set-simulationData", simulationData: test_flight_data});
      }, TWO_MINUTES);
      return () => {
        clearTimeout(timer);
      };
    }

    let timer = setTimeout(() => {
      // console.log("SIMULATION STEP " + simulationIdx);

      devicesDispatch({type: "set-device-online", serialNo: "MOBILE_1", isSimulation: true});
      const refData = stationaryData["GPNT_TARAS"];
      const simulationDatapoint = refData ? {
        ...simulationData[simulationIdx],
        lat: new_mobile_path_dawid[simulationIdx][1],
        lng: new_mobile_path_dawid[simulationIdx][0],
        PM10: refData[Math.abs(refData.length - simulationIdx - 1) % refData.length].PM10 || test_flight_data[100 + simulationIdx].PM10,
        PM25: refData[Math.abs(refData.length - simulationIdx - 1) % refData.length].PM25 || test_flight_data[100 + simulationIdx].PM25
      } : {
        ...simulationData[simulationIdx],
        lat: new_mobile_path_dawid[simulationIdx][1],
        lng: new_mobile_path_dawid[simulationIdx][0]
      };

      console.log({simulationDatapoint});

      mobileDispatch({
        type: "new-mobile-datapoint",
        serialNo: "MOBILE_1",
        mobileDatapoint: simulationDatapoint,
        isSimulation: true
      });
      mapDispatch({
        type: "set-map-center-and-zoom",
        zoom: 18,
        center: {lat: simulationDatapoint.lat, lng: simulationDatapoint.lng}
      });
      // update Indexes after all demo data published
      demoDispatch({type: "set-simulationIdx", simulationIdx: simulationIdx + 1});

    }, SIMULATION_TIMEOUT * 5);
    return () => {
      clearTimeout(timer);
    };

  }, [simulationData, simulationIdx, mapDispatch, demoDispatch]);

  return null;
}