/*global google*/
import React, {useEffect, useMemo, useState} from "react";
import Header from "../../components/Header/Header";
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps";
import {compose, withProps} from "recompose";
import FlightInfoCard from "./MapControls/FlightInfoCard";
import MapControl from "../MapControl";
import FlightChart from "./MapControls/FlightChart";
import MobileBoxMarker from "./MapControls/MobileBoxMarker";
import StationaryBoxMarker from "./MapControls/StationaryBoxMarker";
import {
    useDemoReplaysContext,
    useDevicesContext,
    useFlightDataContext,
    useMapContext, useMobileDataContext,
    useStationaryDataContext
} from "./UAVMapContainer";
import YetiAirMarker from "./MapControls/YetiAirMarker";
import {makeStyles} from "@material-ui/core/styles";
import headersStyle from "../../assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle";
// import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/youtube'
import WaterDroneMarker from './MapControls/WaterDroneMarker';
import WaterBuoyMarker from './MapControls/WaterBuoyMarker';
import SimulatedAirDroneMarker from './MapControls/SimulatedAirDroneMarker';

const NEW_GOOGLE_MAPS_KEY = "AIzaSyCa7R26S9KdcNvgP4JPzoOIOwKHuYdjt7U";
const OLD_GOOGLE_MAPS_KEY = "AIzaSyD8PvAbxJDVYFWWxJUlg3e_83fLXtlQJMI";
const useStyles = makeStyles(headersStyle);

export default function UAVMap() {
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);
    const {state: flightState} = useFlightDataContext();
    const {state: stationaryState} = useStationaryDataContext();
    const {state: mobileState} = useMobileDataContext();
    const {state: mapState, dispatch: mapDispatch} = useMapContext();
    // To make MAP NOT STATIC uncoment this and delete the next line
    // const {zoom, center} = mapState;
    const zoom = 15;
    //54.357578, 18.593912
    const center = {lat: 54.357578, lng: 18.593912};

  console.log({zoom, center})

    const {state: devicesState} = useDevicesContext();
    const {onlineDevices, selectedDevice} = devicesState;

    const {state: demoState} = useDemoReplaysContext();
    const {activeDemo} = demoState;

    // if selectedDevice changed set new map center
    useEffect(() => {
        const datapoints =
            stationaryState.stationaryData[selectedDevice] ||
            flightState.flightsData[selectedDevice] ||
            mobileState.mobileData[selectedDevice];
        if (datapoints) {
            const datapoint = datapoints[datapoints.length - 1];
            const newMapCenter = {lat: datapoint.lat, lng: datapoint.lng};
            mapDispatch({type: "set-map-center-and-zoom", zoom: 18, center: newMapCenter});
        }
    }, [selectedDevice]);
    // Set timer to 1h to refresh the page
    useEffect(
        () => {
            const timeout = 60 * 60 * 1000;
            let timer1 = setTimeout(() => {
                // refresh the current page
                window.location.reload(false);
            }, timeout);
            // this will clear Timeout when component unmount like in willComponentUnmount
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const showFlightChart = useMemo(() => measurements => {
        if (selectedMeasurement === measurements) {
            setSelectedMeasurement(null);
        } else {
            setSelectedMeasurement(measurements);
        }
    }, [selectedMeasurement]);

    const handleZoomChanged = useMemo(() => (zoomLevel) => {
        console.log("Zoom level changed", {zoomLevel});
        mapDispatch({type: "set-map-zoom", zoom: zoomLevel});
    }, []);
    return (
        <div>
            <Header fixed
                    backgroundColor={"rgba(0,0,0,0.7)"}
                    changeColorOnScroll={false}
                    flightsControl
            />
            <GoogleMapWrapper
                // missionsControlDispatch={missionsControlDispatch}
                // activeMissionLocation={activeMissionLocation}
                // selectedMission={selectedMission}
                onlineDevices={onlineDevices}
                activeDemo={activeDemo}
                showFlightChart={showFlightChart}
                selectedMeasurement={selectedMeasurement}
                mapZoom={zoom}
                center={center}
                zoomChanged={handleZoomChanged}
            >
              <WaterDroneMarker/>
              <WaterBuoyMarker/>
              <SimulatedAirDroneMarker/>
                {/*<MobileBoxMarker/>*/}
                <StationaryBoxMarker/>
                <YetiAirMarker serialNo={"AIR_1"}/>
                <YetiAirMarker serialNo={"AIR_2"}/>
            </GoogleMapWrapper>
        </div>
    );
}

const GoogleMapWrapper = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${OLD_GOOGLE_MAPS_KEY}`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div id='map-container-id'
                               style={{height: "100vh", borderRadius: "6px", overflow: "hidden"}}/>,
        mapElement: <div style={{height: "100%"}}/>
    }),
    withScriptjs,
    withGoogleMap
)(props => {
        const {
            center, zoomChanged, mapZoom, showFlightChart, selectedMeasurement,
            onlineDevices, activeDemo
            // selectedMission,
            // activeMissionLocation,
            // missionsControlDispatch
        } = props;

        function handleZoomChanged() {
            zoomChanged(this.getZoom());
        }

        const classes = useStyles();

        return <GoogleMap
            zoom={mapZoom}
            center={center}
            onZoomChanged={handleZoomChanged}
            options={{
                streetViewControl: false,
                clickableIcons: false,
                fullscreenControl: false,
                // fullscreenControlOptions: {
                //   position: google.maps.ControlPosition.LEFT_CENTER,
                // },
                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: google.maps.ControlPosition.LEFT_CENTER,
                },
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.SATELLITE,
                rotateControl: false,
                rotateControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                }
            }}
        >
            {props.children}

            {(false && onlineDevices.length === 0 && !activeDemo) && <MapControl position={google.maps.ControlPosition.TOP_CENTER}>
                <div
                    style={{
                        marginTop: "40vh",
                        width: "50vw",
                        height: "200px",
                        background: "rgba(0, 27, 45, 0.7)",
                        padding: 5,
                        borderRadius: "5px",
                        marginBottom: 10
                    }}
                >
                    <h2 style={{position: "relative", textAlign: "center", color: "white"}}>
                        Brak aktywnych lotów.
                    </h2>
                    <h3 style={{position: "relative", textAlign: "center", color: "white"}}>
                        Poczekaj na rozpoczęcie misji lub wybierz jedną z misji DEMO...
                    </h3>
                </div>
            </MapControl>}

            <MapControl position={google.maps.ControlPosition.RIGHT_CENTER}>
                <FlightInfoCard showFlightChart={showFlightChart}/>
            </MapControl>

            {window.screen.width >= 1000 && <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>
                <div style={{marginLeft: -70, marginBottom: 10}}>
                    <ReactPlayer
                        width={640 - 128}
                        height={360 - 72}
                        playing={true}
                        loop={true}
                        light={false}
                        playIcon={true}
                        muted={true}
                        youtube={{
                          autoplay:1,
                          controls:0
                        }}
                        // url={filmWWW}
                        url={'https://www.youtube.com/watch?v=DgH7lZooP-s'}
                    />
                </div>
            </MapControl>}

            {selectedMeasurement && <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
                <FlightChart showFlightChart={showFlightChart}
                             selectedMeasurement={selectedMeasurement}
                />
            </MapControl>}
        </GoogleMap>;
    }
);
