import React, {useEffect, useState} from 'react';
import {Marker} from 'react-google-maps';
// import MobileBoxIcon from 'assets/svg/water-drone.svg';
import DroneIcon from '../../../assets/svg/drone.svg';
import Card from '../../../components/Card/Card';
import {Box} from '@material-ui/core';
import moment from 'moment';
import {green, orange, pink, red} from '@material-ui/core/colors';
import {MeasurementUnit} from './Measurement';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';


const InfoBoxOptions = {
  pixelOffset: { width: -10, height: -80 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
  },
  closeBoxURL: "",
  // infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const CustomCardStyle = {
  background: "rgba(0, 27, 45, 0.7)",
  marginTop: 0,
  padding: 5,
  // width: 100,
  color: "white",
  // paddingLeft: 10,
  // textAlign: ""
};


const ZIMNOL_POSITIONS = [
  // Z
  [
    18.562817573547363,
    54.36418313399235
  ],
  [
    18.567452430725098,
    54.36418313399235
  ],
  [
    18.572087287902832,
    54.36415813016863
  ],
  [
    18.568310737609863,
    54.36040738423076
  ],
  [
    18.56187343597412,
    54.35550589339602
  ],
  [
    18.570842742919922,
    54.35540585688189
  ],
//  I
    [
    18.576035499572754,
      54.35540585688189
    ],
  [
    18.576250076293945,
    54.35965719394485
  ],
  [
    18.576507568359375,
    54.36418313399235
  ],
//  M
    [
    18.579726219177246,
      54.355355838533455
    ],
  [
    18.58264446258545,
    54.36018232858345
  ],
  [
    18.58534812927246,
    54.36430815288256
  ],
  [
    18.58788013458252,
    54.360232341056076
  ],
  [
    18.59139919281006,
    54.364108122475564
  ],
  [
    18.59560489654541,
    54.3550807365288
  ],
//  N
  [
    18.59813690185547,
    54.35525580165397
  ],
  [
    18.598480224609375,
    54.360457396429375
  ],
  [
    18.598737716674805,
    54.36430815288256
  ],
  [
    18.602943420410153,
    54.36005729713551
  ],
  [
    18.607192039489746,
    54.35533082933644
  ],
  [
    18.607277870178223,
    54.360032290800255
  ],
  [
    18.607406616210938,
    54.364333156614954
  ],
  // O
  [
    18.615474700927734,
    54.36430815288256
  ],
  [
    18.610153198242188,
    54.36080748011494
  ],
  [
    18.61358642578125,
    54.35538084771528
  ],
  [
    18.619894981384277,
    54.360032290800255
  ],
  [
    18.616247177124023,
    54.36400810690674
  ],
//  L
  [
    18.621182441711426,
    54.364108122475564
  ],
  [
    18.621182441711426,
    54.36018232858345
  ],
  [
    18.62105369567871,
    54.355555911561765
  ],
  [
    18.626890182495117,
    54.355355838533455
  ],
  [
    18.63302707672119,
    54.355355838533455
  ]
];

const TRIANGLE_POSITIONs = [  [
  18.59706401824951,
  54.35334254946068
],
  [
    18.60549688339233,
    54.35328002337593
  ],
  [
    18.6088228225708,
    54.35545587516941
  ],
  [
    18.606913089752197,
    54.35764411564263
  ],
  [
    18.600497245788574,
    54.35766912343157
  ],
  [
    18.597257137298584,
    54.355068231848435
  ]];

export default function SimulatedAirDroneMarker() {
  const height = 50;
  const width = 50;

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 2000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const [lng, lat] = TRIANGLE_POSITIONs[seconds % TRIANGLE_POSITIONs.length];


  return <Marker key={'fake-drone'}
                 position={{lat, lng}}
                 icon={{url: DroneIcon, scaledSize: {height, width}}}
  >
    {!(seconds % 2) && <InfoBox options={InfoBoxOptions}>
      <Card style={CustomCardStyle}>
        <Box style={{textAlign: 'center'}}>
         👀
        </Box>
      </Card>
    </InfoBox>}

    {!(seconds % 11) && <InfoBox options={InfoBoxOptions}>
      <Card style={CustomCardStyle}>
        <Box style={{textAlign: 'center'}}>
          💥
        </Box>
      </Card>
    </InfoBox>}
  </Marker>;
}
