// import droneImg from "assets/img/examples/card-blog4.jpg";
import uavMonitoringPowietrza from "./images/1/UAV_monitoring_powietrza.png"
import droneLarge from "./images/1/drone-674235_1920.jpg"
import droneSmall from "./images/1/drone-674235_640.jpg"

import wroclawLarge from "./images/2/wrocaw-4767317_1920.jpg"
import wroclawSmall from "./images/2/wrocaw-4767317_640.jpg"

import pollutionLarge from "./images/3/pollution-4846741_1920.jpg"
import pollutionSmall from "./images/3/pollution-4846741_640.jpg"

export const BlogPosts = [
  {
    id: 1,
    title: "Drony w monitoringu atmosfery.",
    frontImg: droneSmall,
    backgroundImg: droneLarge,
    tags: ["UAV", "Pomiary", "Środowisko"],
    subtitle: "Jak wykorzystanie dronów zmienia spojrzenie na monitoring środowiska?",
    author: "Marta Wenta",
    category: "UAV",
    // publishDate: "2020-08-30",
    publishDate: "Tydzień temu",
    abstract: "Otaczające nas powietrze i zawarty w nim tlen jest podstawą życia. Powietrze nie jest jednak wszędzie takie samo. Rosnąca liczba samochodów, działające 24 godziny na dobę przedsiębiorstwa, elektrociepłownie i inne czynniki powodują, że tam gdzie ludzie żyją w największym zagęszczeniu - najwyższe jest również zanieczyszczenie powietrza. Rośnie liczba badań, które wskazują, że niskiej jakości powietrza przypisuje się odpowiedzialność za wzrost zachorowań raka, śmiertelność noworodków, czy ostatnio - podatność na COVID-19…",
    firstLine: "Otaczające nas powietrze i zawarty w nim tlen jest podstawą życia...",
    text: [
      "Powietrze nie jest jednak wszędzie takie samo. Rosnąca liczba samochodów, działające 24 godziny na dobę przedsiębiorstwa, elektrociepłownie i inne czynniki powodują, że tam gdzie ludzie żyją w największym zagęszczeniu -  najwyższe jest również zanieczyszczenie powietrza. Rośnie liczba badań, które wskazują, że niskiej jakości powietrza przypisuje się odpowiedzialność za wzrost zachorowań raka, śmiertelność noworodków, czy ostatnio - podatność na COVID-19.",
      "Przeciwdziałanie tym efektom nie jest łatwe. Nie możemy całkowicie ograniczyć transportu, zamknąć firm generujących zanieczyszczenia, czy wyłączyć ogrzewanie w mieście. Dlatego zwracamy się ku monitoringowi, gdyż tylko dzięki stałej kontroli możemy zidentyfikować największych trucicieli, czy wprowadzić zakaz wjazdu samochodem do centrum miasta. Stąd rosnąca w Polsce liczba czujników jakości powietrza w aglomeracjach miejskich oraz przedsiębiorstwach, pozwalająca np. ostrzegać mieszkańców o słabej jakości powietrza.",
      "Punktowy pomiar, z urządzenia zawieszonego z regułu na około 2 metrach, nie pozwala jednak stwierdzić jak zanieczyszczenie rozprzestrzenia się na danym obszarze? Na jakiej wysokości stężenia szkodliwych substancji są największe? Jeszcze niedawno odpowiedź na te pytania byłaby całkiem trudna, z pomocą przychodzą jednak drony. Miniaturyzacja czujników i rozwój technologii UAV (Unmanned Aerial Vehicle) pozwala w krótkim czasie uzyskać informacje o jakości powietrza z rozległego obszaru. Efektem przelotu jest mapa 2D lub 3D prezentująca kompleksowy obraz stężenia poszczególnych substancji niebezpiecznych w danym rejonie. "
    ],
    finishingLine: "Stały monitoring w połączeniu z cyklicznymi przelotami dronów to przyszłość monitoringu jakości powietrza. Lepsza identyfikacja źródeł oraz obszarów narażonych na wysokie stężenia szkodliwych substancji jest pierwszym krokiem do polepszenia jakości powietrza, którym na co dzień oddychamy.",
    images: [
      uavMonitoringPowietrza
    ]
  },
  {
    id: 2,
    title: "Jakość powietrza w Trójmieście.",
    frontImg: wroclawSmall,
    backgroundImg: wroclawLarge,
    tags: ["Powietrze", "Atmosfera", "3City", "Inwersja Temperatury"],
    subtitle: "Aglomeracja trójmiejska odnotowuje znacznie mniej przekroczeń wartości dopuszczalnych w ciągu roku aniżeli inne miasta Polski. Dlaczego tak się dzieje?",
    author: "Marta Wenta",
    category: "Atmosfera",
    publishDate: "2 dni temu",
    abstract: "Wielu mieszkańcom Trójmiasta zanieczyszczenie powietrza oraz smog kojarzą się ze Śląskiem oraz pod górskimi miejscowościami, a Pomorze cenione jest za “czyste powietrze”. Rzeczywiście, aglomeracja trójmiejska odnotowuje znacznie mniej przekroczeń wartości dopuszczalnych w ciągu roku aniżeli inne miasta Polski. Dlaczego tak się dzieje?…",
    firstLine: "Dlaczego smog kojarzy się ze Śląskiem?",
    text: [
      "Wielu mieszkańcom Trójmiasta zanieczyszczenie powietrza oraz smog kojarzą się ze Śląskiem oraz pod górskimi miejscowościami, a Pomorze cenione jest za “czyste powietrze”. Rzeczywiście, aglomeracja trójmiejska odnotowuje znacznie mniej przekroczeń wartości dopuszczalnych w ciągu roku aniżeli inne miasta Polski. Dlaczego tak się dzieje?",
      "Transport samochodowy jest tutaj równie intensywny jak w innych miejscach, a wiele domów jednorodzinnych wciąż ogrzewa domy węglem. Sprzymierzeńcem czystszego powietrza jest wiatr, wystarczy by prędkość wiatru wynosiła kilka m/s by wytworzyły się wiry (turbulencja) powodujące mieszanie powietrza. Im silniejszy wiatr i intensywniejsze mieszanie, tym na większym  obszarze w pionie i poziomie rozprowadzane są pyły, dzięki czemu ich koncentracja spada.  Obecność morza i tworzenie się bryzy dziennej i nocnej jest zatem naszym sprzymierzeńcem w walce z zanieczyszczeniem powietrza.",
      "Mimo to, zimą występują dni gdy obecne w Trójmieście czujniki jakości powietrza przybierają alarmujący czerwony kolor na różnego rodzaju mapach. Skąd ta zmiana? Zimą dochodzi niekiedy do wystąpienia zjawiska inwersji temperatury, kiedy to szkodliwe zanieczyszczenia zatrzymane są w warstwie atmosfery, w której żyjemy. Na czym polega inwersja? Utrzymywanie się przed dłuższy czas układu wysokiego ciśnienia,któremu towarzyszy słoneczna pogoda i słaby wiatr, często jest odpowiedzialne za to zjawisko. Promienie słoneczne w ciągu dnia nagrzewają grunt i inne powierzchnie,które w nocy zaczynają gwałtownie tracić ciepło. Powietrze tuż nad powierzchnią ochładza się, a cieplejsze masy powietrza unoszą się w górę. W efekcie temperatura powietrza wzrasta wraz z wysokością, a nagrzane powietrze w górze tworzy swego rodzaju barierę ograniczającą mieszanie się powietrza. Bariera ta może czasami znajdować się zaledwie kilkanaście-kilkadziesiąt metrów nad ziemią, skutecznie akumulując wszelkie generowane przez nas pyły w powietrzu, którym na co dzień oddychamy.",
      "Inwersję można łatwo zaobserwować spoglądając na unoszenie się dymu z kominów. Wysokość, na której dym zaczyna unosić się poziomo - jest wyznacznikiem występowania bariery cieplejszego powietrza. Jest to jeden z powodów, dla których buduje się bardzo wysokie kominy, w celu przebicia się poza barierę inwersji.",
    ],
    finishingLine: "Pogoda zatem ma kluczowy wpływ na jakość powietrza w naszej okolicy. Może nie brzmi to zbyt atrakcyjnie ale im silniej wieje i częściej występują opady - tym zdrowsze powietrze wokół nas. Czasami warto pomyśleć o tego typu zaletach niezbyt przyjemnej dla wypoczynku pogody.",
    images: [
      wroclawSmall
    ]
  },
  {
    id: 3,
    title: "Co oznacza skrót PM?",
    frontImg: pollutionSmall,
    backgroundImg: pollutionLarge,
    tags: ["PM10", "Atmosfera", "3City", "Inwersja Temperatury"],
    subtitle: "W tym poście wyjaśnimy sobie co to pyły zawieszone, PM10, PM2.5. Zapraszam do lektury",
    author: "Marta Wenta",
    category: "Ochrona środowiska",
    publishDate: "Wczoraj",
    abstract: "Określenia typy: pyły zawieszone, PM10, PM2.5, PMy weszły już niemal do naszego języka i często powtarzane są w przekazach medialnych? Co jednak oznaczają te skróty?…",
    firstLine: "PM10, PM2.5 - Co oznaczają te skróty?",
    text: [
      "Określenia typy: pyły zawieszone, PM10, PM2.5, PMy weszły już niemal do naszego języka i często powtarzane są w przekazach medialnych? Co jednak oznaczają te skróty?",
      "PM to w języku angielskim skrót od particulate matter, czyli pyły zawieszone. Liczby 10 i 2.5 oznaczają wymiary cząsteczek pyłów, odpowiednio: mniejsze od 10 mikrometrów i od 2.5 mikrometra. Wskazuje się, że i mniejsze cząsteczki tym bardziej groźne dla zdrowia, gdyż łatwiej im dostać się do krwioobiegu i płuc. Wielkości rzędu kilku mikrometrów są niedostrzegalne dla ludzkiego oka, dlatego pyły dostają się w głąb naszego organizmu niemal niezauważenie.",
      "W skład pyłów zawieszone, które tworzą zanieczyszczenia powietrza wchodzą m. in. siarczany, amoniak, tlenki glinu i żelaza, węgiel oraz sole, a także substancje toksyczne jak benzopiren, dioksyny, furany.  Narażenie na wysokie stężenia pyłu zawieszonego objawia się dolegliwościami układu oddechowego jak np. kaszel, katar, a także zapalenia spojówek. Długotrwały kontakt może skutkować m.in zwiększoną zachorowalnością na nowotwory, choroby serca,  nadciśnienia.",
      "W Polsce głównym źródłem pyłów (ok. 46%)  jest tzw. niska emisja, czyli spalanie paliw kopalnych w gospodarstwach domowych. Do słabej jakości powietrza w dużych częściach kraju przyczynia się także przemysł (20%), rolnictwo (12%) , transport (8%), energetyka (7%) i inne.",
    ],
    finishingLine: "Monitoring stężeń pyłów jest kluczowy w eliminacji źródeł zanieczyszczeń oraz w celu informowania społeczeństwa o niebezpieczeństwie. Pomiary stałe jak i okresowe pozwalają ocenić aktualną sytuację oraz efekty podjętych działań. Warto, dla naszego zdrowia, sprawdzać ostrzeżenia o złej jakości powietrza, a także podejmować działania, które mogą sprzyjać polepszeniu sytuacji jak np. używanie komunikacji miejskiej lub stosowanie ekologicznych systemów grzewczych. W końcu każda większa zmiana opiera się o zbiorowe działanie jednostek, czyli nas.",
    images: [
      pollutionSmall
    ]
  },

];

